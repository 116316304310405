import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './RoomToolbar.css';

// Images
import PencilIcon from '../../../svg/PencilIcon';
import RoomsFilledIcon from '../../../svg/RoomsFilledIcon';
import RoomDesignIcon from '../../../svg/RoomDesignIcon';

// Components
import RoomDesigner from '../../../../desktop/roomdesigner/RoomDesigner';
import { Toolbar, Left, Right } from '../../../components/toolbar/Toolbar';
import ToolbarButton from '../../../components/toolbar/button/ToolbarButton';
import ToolbarIcon from '../../../components/toolbar/icon/ToolbarIcon';
import ToolbarTitle from '../../../components/toolbar/title/ToolbarTitle';
import ParticipantsBar from '../../../components/participants/bar/ParticipantsBar';

const RoomToolbar = () => {

    // Global
    const {
        selectedFolderItems,
        selectedRoom
    } = useContext(Global);

    // Local State
    const [designerVisible, setDesignerVisible] = useState(false);

    const handleEditClick = () => {
        console.log('Edit clicked', selectedFolderItems);
    };

    const handleRoomDesignClick = () => {
        setDesignerVisible(true);
    };

    // Verify the selected room
    if (!selectedRoom) return null;

    return (
        <>
            <Toolbar>

                <Left>

                    {/* ICON */}
                    <ToolbarIcon
                        icon={RoomsFilledIcon}
                    />

                    {/* TITLE */}
                    <ToolbarTitle
                        title={selectedRoom.title}
                    />

                </Left>
                <Right>

                    {/* PARTICIPANTS */} 
                    <ParticipantsBar
                        item={selectedRoom}
                        displayCount={20}
                    />

                    {/* EDIT 
                    {selectedFolderItems.length === 1 && (
                        <ToolbarButton
                            icon={PencilIcon}
                            title="Edit"
                            onClick={handleEditClick}
                        />
                    )}
                        */}

                    {/* ROOM DESIGN 
                    <ToolbarButton
                        icon={RoomDesignIcon}
                        title="Room Design"
                        onClick={handleRoomDesignClick}
                    />
                    */}

                </Right>

            </Toolbar>

            {/* WEBSITE */}
            <RoomDesigner
                open={designerVisible}
                setOpen={setDesignerVisible}
            />
        </>
    );
};

export default RoomToolbar;
