import React from 'react';

// Styles
import './RoomListElement.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const RoomListElement = ({ element, icon: IconComponent, title, useDrag }) => {

  // Theme
  const { theme } = useTheme();

  const [, drag] = useDrag({
    type: 'ELEMENT',
    item: { element },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <>
      {/* CONTAINER */}
      <div ref={drag}
        className="room-list-element-container"
        title={title}>

        {/* ICON */}
        <div className="room-list-element-icon">
          <IconComponent
            color={theme.foregroundColorFaded}
            width="10"
            height="10"
          />
        </div>

        {/* TITLE */}
        <div className="room-list-element-title"
          style={{
            color: theme.foregroundColor,
          }}>
          {title}
        </div>

      </div>
    </>
  );

};

export default RoomListElement;
