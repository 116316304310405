import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './Profile.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import BookmarkIcon from '../../../common/svg/BookmarkIcon';
import Grid from '../../../common/components/grid/Grid';
import Item from '../../../common/sections/folders/item/Item';
import LikeIcon from '../../../common/svg/LikeIcon';
import PublicIcon from '../../../common/svg/PublicIcon';

// Components
import ProfileButtons from './buttons/ProfileButtons';
import ProfilePhoto from './photo/ProfilePhoto';
import ProfileStats from './stats/ProfileStats';
import ProfileTabs from './tabs/ProfileTabs';

// Preload Hooks
import usePreloadPublicItems from './hooks/PreloadPublicItems.js';

const Profile = ({ profile }) => {

    // Themes
    const { theme } = useTheme();

    // Global
    const {
    } = useContext(Global);

    // Local State
    const [tabsData, setTabsData] = useState([]);
    const [publicItems, setPublicItems] = useState([]);

    // Hooks
    usePreloadPublicItems(profile, setPublicItems);

    useEffect(() => {
        if (!publicItems || publicItems.length === 0) return;

        setTabsData([
            {
                title: 'Public',
                icon: PublicIcon,
                content: <Grid
                    items={publicItems}
                    renderItem={(item) => (

                        <Item
                            key={item.key}
                            item={item}
                        />

                    )}
                    loading={false}
                    minCols={1}
                    colWidth={170}
                />,
            },
            {
                title: 'Favorites',
                icon: BookmarkIcon,
                content: <div>favorites goes here.</div>,
            },
            {
                title: 'Likes',
                icon: LikeIcon,
                content: <div>likes goes here.</div>,
            },
        ]);

    }, [publicItems]);

    /**
     * Handles click on the edit button.
     */
    const handleEditClick = () => {
    };

    /**
     * Handles click on the share button.
     */
    const handleShareClick = () => {
    };

    /**
     * Handles click on the add friend button.
     */
    const handleAddFriendClick = () => {

    };

    // Validate profile
    if (!profile) return null;

    return (

        <div className="profile-container">

            {/* PHOTO */}
            <div className="profile-photo-wrapper">
                <ProfilePhoto
                    item={profile}
                    size={150}
                />
            </div>

            {/* NAME */}
            <div className="profile-name-wrapper"
                style={{
                    color: theme.foregroundColor,
                }}>
                {profile.firstName} {profile.lastName}
            </div>

            {/* STATS */}
            <div className="profile-stats-wrapper"
                style={{
                    color: theme.foregroundColor,
                }}>
                <ProfileStats
                    profile={profile}
                />
            </div>

            {/* BUTTONS */}
            <div className="profile-buttons-wrapper"
                style={{
                    color: theme.foregroundColor,
                }}>
                <ProfileButtons
                    item={profile}
                    onEditClick={handleEditClick}
                    onShareClick={handleShareClick}
                    onAddFriendClick={handleAddFriendClick}
                />
            </div>

            {/* TABS */}
            <div className="profile-tabs-wrapper"
                style={{
                    color: theme.foregroundColor,
                }}>

                <ProfileTabs 
                    tabsData={tabsData}
                />

            </div>

        </div>

    );
};

export default Profile;
