import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Firebase
import { collections } from '../../../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../../../utilities/Keys';
import { hexToRgba } from '../../../../../utilities/Colors';
import { ItemSize, ThemeMode } from '../../../../../utilities/Enums';
import { formatEventDates } from '../../../../../utilities/Formatters';

// Styles
import './EventGridItem.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import CalendarIcon from '../../../../../svg/CalendarIcon';

// Components
import GridItem from '../../../../../components/grid/griditem/GridItem';

// Managers
import DataManager from '../../../../../managers/DataManager';
const dataManager = new DataManager();

/**
 * EventGridItem
 * 
 * A grid item for displaying events.
 * 
 * @param {Object} item The event item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} EventGridItem component.
 */
const EventGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart, size = ItemSize.SMALL }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Global
    const {
        profile,
        profileEvents,
    } = useContext(Global);

    // Local State
    const [backgroundUrl, setBackgroundUrl] = useState(null);

    useEffect(() => {
        const items = item.items;
        if (items) {
            const photos = items.filter(item => item.type === "PHOTO");
            if (photos.length > 0) {
                setBackgroundUrl(photos[0].url);
            }
        }

    }, [item]);

    const handleAddClick = async () => {

        // Create a copy of the event, but reference the original event
        // so that we can update this event whenever the original event is updated
        const displayProfile = {
            key: profile.key,
            firstName: profile.firstName,
            lastName: profile.lastName,
            photo: profile.photo,
        };

        const key = generateKey();

        const copy = {
            ...item,
            key: key,
            referenceEventKey: item.key,
            profileKey: profile.key,
            profile: displayProfile,
        };

        await dataManager.add(collections.items, profile.key, key, copy);
    };

    // Format the dates and times
    const { formattedDates, formattedTimes } = formatEventDates(item.startDate, item.endDate);

    return (

        <GridItem
            selected={selected}
            shared={item.shared}
            trashed={item.trashed}
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(event) => {
                if (onDragStart) onDragStart(event, item);
            }}
            size={size}>

            <div className="event-grid-item-container"
                title={item.title}>

                
                {/* IMAGE/ICON */}
                {backgroundUrl ? (
                    <div className="event-grid-item-image-container">
                        <img className="event-grid-item-image"
                            src={backgroundUrl} />
                    </div>
                ) : (
                    <div className="event-grid-item-icon-container"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}>
                        <CalendarIcon
                            color={theme.highlightBackgroundColor}
                            width="220"
                            height="220"
                        />
                    </div>
                )}

                <div className="event-grid-item-footer"
                    style={{
                        backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.5)
                    }}>

                    <div className="event-grid-item-title"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {item.title}
                    </div>

                    <div className="event-grid-item-dates"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {formattedDates}
                    </div>

                    <div className="event-grid-item-dates"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {formattedTimes}
                    </div>

                    {item.location && item.location.length > 0 && (
                        <div className="event-grid-item-location"
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {item.location}
                        </div>
                    )}

                </div>

            </div>

        </GridItem>
    );
};

export default EventGridItem;
