import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../../../Global';


// Firebase
import { collections } from '../../../../../../firebaseConfig';

// Utilities
import { hexToRgba } from '../../../../../utilities/Colors';
import { getRelativeTime } from '../../../../../utilities/DateTime';
import { ItemSize, ThemeMode } from '../../../../../utilities/Enums';
import { formatEventDates } from '../../../../../utilities/Formatters';
import { generateKey } from '../../../../../utilities/Keys';

// Styles
import './EventFeedItem.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import CalendarIcon from '../../../../../svg/CalendarIcon';

// Components
import GridItem from '../../../../../components/grid/griditem/GridItem';

// Managers
import DataManager from '../../../../../managers/DataManager';
const dataManager = new DataManager();

/**
 * EventFeedItem
 * 
 * A grid item for displaying events.
 * 
 * @param {Object} item The event item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} EventFeedItem component.
 */
const EventFeedItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart, size = ItemSize.SMALL }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Global
    const {
        profile,
        profileEvents,
    } = useContext(Global);

    // Local State
    const [alreadyCopied, setAlreadyCopied] = useState(false);
    const [backgroundUrl, setBackgroundUrl] = useState(null);
    const [songUrl, setSongUrl] = useState(null);

    // References
    const containerRef = useRef(null);
    const audioRef = useRef(null);

    useEffect(() => {
        const items = item.items;

        // Look for a background image
        if (items) {
            const photos = items.filter(item => item.type === "PHOTO");
            if (photos.length > 0) {
                setBackgroundUrl(photos[0].url);
            }
        }

        // Look for a song
        if (items) {
            const songs = items.filter(item => item.type === "MUSIC");
            if (songs.length > 0) {
                setSongUrl(songs[0].url);
            }
        }

        // Detect whether this event has already been copied
        const copied = profileEvents.find(event => event.referenceEventKey === item.key);
        if (copied) {
            setAlreadyCopied(true);
        }

    }, [item]);

    // Intersection Observer to detect when the item is in the viewport
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Item is in the viewport, play the song
                        if (audioRef.current && songUrl) {
                            audioRef.current.play().catch((error) => {
                                console.error("Auto-play failed:", error);
                            });
                        }
                    } else {
                        // Item is out of the viewport, pause the song
                        if (audioRef.current) {
                            audioRef.current.pause();
                        }
                    }
                });
            },
            {
                threshold: 0.5, // Trigger when 50% of the item is visible
            }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current); // Observe the feed item container
        }

        // Cleanup the observer when the component unmounts
        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [songUrl]);

    useEffect(() => {
        // Detect whether this event has already been copied
        const copied = profileEvents.find(event => event.referenceEventKey === item.key);
        if (copied) {
            setAlreadyCopied(true);
        }
    }, [item, profileEvents]);

    const handleAddClick = async () => {

        // Create a copy of the event, but reference the original event
        // so that we can update this event whenever the original event is updated
        const displayProfile = {
            key: profile.key,
            firstName: profile.firstName,
            lastName: profile.lastName,
            photo: profile.photo,
        };

        const key = generateKey();

        const copy = {
            ...item,
            key: key,
            referenceEventKey: item.key,
            profileKey: profile.key,
            profile: displayProfile,
            shared: false,
        };

        await dataManager.add(collections.items, profile.key, key, copy);
    };

    const handleRemoveClick = async () => {
        const copied = profileEvents.find(event => event.referenceEventKey === item.key);
        if (copied) {
            await dataManager.delete(collections.items, profile.key, copied.key);
            setAlreadyCopied(false);
        }
    };

    // Format the dates and times
    const { formattedDates, formattedTimes } = formatEventDates(item.startDate, item.endDate, true);

    return (

        <GridItem
            selected={selected}
            shared={item.shared}
            trashed={item.trashed}
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(event) => {
                if (onDragStart) onDragStart(event, item);
            }}
            size={size}>

            <div className="event-feed-item-container"
                ref={containerRef}
                title={item.title}>

                {/* HEADER */}
                <div className="event-feed-item-header"
                    style={{
                        backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.5)
                    }}>

                    <div className="event-feed-item-header-name"
                        style={{
                            color: theme.foregroundColor,
                        }}>
                        {item.profile.firstName} {item.profile.lastName}
                    </div>

                    <div className="event-feed-item-header-type"
                        style={{
                            color: theme.foregroundColor,
                        }}>
                        <CalendarIcon
                            color={theme.foregroundColor}
                            width="13"
                            height="13"
                        />
                        Event {getRelativeTime(item.startDate)}
                    </div>

                </div>

                {/* IMAGE/ICON */}
                {backgroundUrl ? (
                    <div className="event-feed-item-image-container">
                        <img className="event-feed-item-image"
                            src={backgroundUrl} />
                    </div>
                ) : (
                    <div className="event-feed-item-icon-container"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}>
                        <CalendarIcon
                            color={theme.highlightBackgroundColor}
                            width="220"
                            height="220"
                        />
                    </div>
                )}

                {/* FOOTER */}
                <div className="event-feed-item-footer"
                    style={{
                        backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.5)
                    }}>

                    <div className="event-feed-item-title"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {item.title}
                    </div>

                    <div className="event-feed-item-dates"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {formattedDates}
                    </div>

                    <div className="event-feed-item-dates"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {formattedTimes}
                    </div>

                    {item.location && item.location.length > 0 && (
                        <div className="event-feed-item-location"
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {item.location}
                        </div>
                    )}

                    {/* BUTTONS */}
                    {alreadyCopied ? (
                        <div className="event-feed-item-button"
                            onClick={handleRemoveClick}
                            style={{
                                backgroundColor: theme.backgroundColorFaded,
                                color: theme.foregroundColor
                            }}>
                            Remove From My Calendar
                        </div>
                    ) : (
                        <div className="event-feed-item-button"
                            onClick={handleAddClick}
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                color: theme.highlightForegroundColor
                            }}>
                            Add To My Calendar
                        </div>
                    )}

                </div>

            </div>

            {/* Music Player */}
            {songUrl && (
                <audio
                    ref={audioRef}
                    controls // Optional: Add controls if you want the user to control playback
                    autoPlay // Auto-play the song when the component renders
                    loop // Optional: Loop the song
                    style={{ display: 'none' }} // Hide the audio element if you don't want it visible
                >
                    <source src={songUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            )}

        </GridItem>
    );
};

export default EventFeedItem;
