import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Utilities
import { ItemType, SectionType } from '../../../common/utilities/Enums';

// Styles
import './Rooms.css';

// Components
import RoomGridItem from '../../../common/sections/folders/item/room/RoomGridItem';
import Grid from '../../../common/components/grid/Grid';
import RoomsToolbar from './toolbar/RoomsToolbar';
import Section from '../../components/section/Section';

// Managers
import { activity } from '../../../common/managers/ActivityManager';
import DataManager from '../../../common/managers/DataManager';
const dataManager = new DataManager();

const Rooms = () => {

  // Global
  const {
    profile,
    setSelectedRoom,
    setCurrentSection,
  } = useContext(Global);

  const [rooms, setRooms] = useState([]);

  useEffect(() => {

    const handleUpdate = (items) => {
      console.log(items);
      setRooms(items);
      activity.log(profile.key, 'reads', items.length);
    };

    const params = [
      { field: "type", operator: "==", value: ItemType.ROOM },
      { field: "profileKeys", operator: "array-contains", value: profile.key }
    ];

    const unsubscribe = dataManager.listAndSubscribe(
      collections.items,
      profile.key,
      handleUpdate,
      params,
      []
    );

    return unsubscribe;

  }, [profile, setRooms]);

  // Room
  const handleRoomDoubleClick = (room) => {
    setSelectedRoom(room);
    setCurrentSection(SectionType.ROOM);
  };

  return (

    <Section>

      {/* TOOLBAR */}
      <RoomsToolbar />

      {/* CONTENT */}
      <div className="rooms-grid-container">
        <Grid
          items={rooms}
          renderItem={(item) => (

            <RoomGridItem
              key={item.key}
              item={item}
              onDoubleClick={() => handleRoomDoubleClick(item)}
              />

          )}
          loading={false}
          minCols={2}
          colWidth={200}
        />
      </div>

    </Section>

  );
};

export default Rooms;
