import React from 'react';

// Styles
import './EventAddItemButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const EventAddItemButton = ({ icon: IconComponent, label, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div
            className="event-add-item-button-container"
            onClick={onClick}>

            {/* ICON */}
            <div className="event-add-item-button-button">
                <IconComponent 
                    color={theme.foregroundColorFaded} 
                    width="14" 
                    height="14" 
                />
            </div>

            {/* LABEL */}
            <div className="event-add-item-button-label"
                style={{
                    color: theme.foregroundColorFaded
                }}>
                {label}
            </div>

        </div>
    );
};

export default EventAddItemButton;
