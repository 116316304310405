import React from 'react';

// Styles
import './ParticipantsGrid.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ParticipantsGrid = ({ item, displayCount = 6 }) => {
    const { theme } = useTheme();

    // Extract room participants (max 6)
    const participants = item.profiles.slice(0, displayCount);
    const extraCount = item.profiles.length > displayCount ? item.profiles.length - displayCount : 0;

    // Determine grid structure based on participant count
    const getGridStyle = () => {
        const count = participants.length;
        if (count <= 3) return { gridTemplateColumns: `repeat(${count}, 1fr)` }; // 1 row
        return { gridTemplateColumns: `repeat(3, 1fr)` }; // Max 3 per row
    };

    return (
        <div
            className="participants-grid-photos-container"
            style={getGridStyle()}>
            {participants.map((participant, index) => (
                <img
                    key={index}
                    src={participant.photo}
                    alt={`${participant.firstName} ${participant.lastName}`}
                    className="participants-grid-photo"
                />
            ))}
            {extraCount > 0 && (
                <div className="participants-grid-extra"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    +{extraCount}
                </div>
            )}
        </div>
    );
};

export default ParticipantsGrid;
