import React, { useState } from 'react';

// Styles
import './ConversationMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import RemoveIcon from '../../../svg/RemoveIcon';

// Components
import ContextMenuButton from '../../../components/contextmenu/ContextMenuButton';
import ContextMenuOption from '../../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../components/contextmenu/ContextMenuPopup';

const ConversationMenu = ({ onDelete }) => {

    // Theme
    const { theme } = useTheme();

    // Local State
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });

    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ left: event.clientX, top: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    const handleDelete = (event) => {
        event.stopPropagation();
        onDelete();
        setMenuVisible(false);
    };

    return (
        <div className="conversation-menu-outer-container">

            {/* MENU BUTTON */}
            <div className="conversation-menu-container">
                <ContextMenuButton
                    title="Conversation Options"
                    onToggle={toggleMenu}
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={190}
                position={menuPosition}>

                {/* REMOVE */}
                <ContextMenuOption
                    icon={RemoveIcon}
                    text="Delete Conversation"
                    onClick={handleDelete}
                />

            </ContextMenuPopup>

        </div>
    );
};

export default ConversationMenu;
