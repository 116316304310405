import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../Global';

// Components
import Modal from '../components/modal/Modal';

// Utilities
import { Permission, SectionType } from '../../common/utilities/Enums';
import { hasCollectionPermission } from '../../common/utilities/Permissions';

// Styles
import './Search.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Managers
import IndexManager from '../managers/IndexManager';
import { isMobile } from 'react-device-detect';

const indexManager = new IndexManager();

// Styled Components
const InlineStyles = useStyle`
  *::placeholder {
    color: ${(props) => props.placeholderColor};
    opacity: 0.5; /* Adjusted opacity for better visibility */
  }
`;

const Search = ({ onSearch, isVisible, setVisible, modelKey = "" }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profileModels,
    profileEvents,
    profile,
    currentUser,
    setCurrentSection,
    setEventVisible,
    setSelectedEvent,
  } = useContext(Global);

  // Local State
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedResultIndex, setSelectedResultIndex] = useState(-1);

  // Input reference
  const inputRef = useRef(null);
  const resultsRef = useRef([]);

  // Load all objects when the modal is displayed.
  useEffect(() => {
    // Do nothing if the profile is null.
    if (!profile) return;

    if (!isVisible) {
      setSearchTerm('');
      setSelectedResultIndex(-1);
      return;
    }

    // Focus the input
    if (inputRef.current) {
      inputRef.current.focus();
    }

    async function load() {
      const allEntries = await indexManager.listByApp(profile.key);
      setEntries(allEntries);
    }

    load();
  }, [isVisible, profile]);

  // Search/filter the entries array whenever the search term changes.
  useEffect(() => {

    // Verify profile
    if (!profile) return null;

    async function search() {
      let r = [];

      if (searchTerm.length === 0 && modelKey) {
        // When searchTerm is empty and modelKey is present, display all items for the modelKey.
        r = entries.filter(entry => entry.modelKey === modelKey).map(entry => ({
          ...entry,
          type: 'OBJECT', // Tag as OBJECT
        }));
      } else if (searchTerm.length > 0) {
        // Filter based on search term and modelKey.
        r = indexManager.searchByApp(profile.key, entries, searchTerm, modelKey).map(entry => ({
          ...entry,
          type: 'OBJECT', // Tag as OBJECT
        }));

        // Add filtered events
        const eventResults = profileEvents.filter(event =>
          event.title.toLowerCase().includes(searchTerm.toLowerCase())
        ).map(event => ({
          ...event,
          type: 'EVENT', // Tag as EVENT
          modelKey: 'events', // Ensure grouping works
        }));

        r = [...r, ...eventResults];
      }

      setResults(r);
      setSelectedResultIndex(-1);
    }

    search();
  }, [searchTerm, profile, entries, modelKey, profileEvents]);


  // Group results by modelKey and flatten them for navigation
  const groupedResults = results.reduce((acc, entry) => {
    if (!acc[entry.modelKey]) {
      acc[entry.modelKey] = [];
    }
    acc[entry.modelKey].push(entry);
    return acc;
  }, {});

  const flattenedResults = Object.values(groupedResults).flat();

  const handleResultClick = (entry) => {

    if (entry.type === 'OBJECT') {
      onSearch(entry);
    } else if (entry.type === 'EVENT') {
      setCurrentSection(SectionType.CALENDAR);
      setSelectedEvent(entry);
      setEventVisible(true);
    }

    setVisible(false);
    setSearchTerm('');
  };

  useEffect(() => {

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown' || e.key === 'Tab') {
        e.preventDefault(); // Prevent the default tab behavior
        setSelectedResultIndex((prevIndex) =>
          Math.min(prevIndex + 1, flattenedResults.length - 1)
        );
      } else if (e.key === 'ArrowUp') {
        setSelectedResultIndex((prevIndex) =>
          Math.max(prevIndex - 1, 0)
        );
      } else if (e.key === 'Enter' && selectedResultIndex >= 0) {
        const entry = flattenedResults[selectedResultIndex];
        onSearch(entry);
        setVisible(false);
        setSearchTerm('');
      }
    };

    if (isVisible) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };

  }, [isVisible, selectedResultIndex, flattenedResults, onSearch, setVisible, setSearchTerm]);

  useEffect(() => {
    if (selectedResultIndex >= 0 && resultsRef.current[selectedResultIndex]) {
      resultsRef.current[selectedResultIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [selectedResultIndex]);

  // Verify profile
  if (!profile) return null;

  return (
    <>
      <InlineStyles placeholderColor={theme.foregroundColorFaded} />

      <Modal
        title={`Search ${profile && profile.title}`}
        isOpen={isVisible}
        onClose={() => setVisible(false)}
        width={isMobile ? '100%' : '90%'}
        height={isMobile ? '100%' : '90%'} >

        <div className="search-container">

          {/* SEARCH INPUT */}
          <input
            type="text"
            ref={inputRef}
            className="search-input"
            placeholder="Search term..."
            style={{
              backgroundColor: theme.backgroundColorFaded,
              color: theme.foregroundColor,
              fontFamily: profile.fontFamily,
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* DIVIDER */}
        <div className="search-divider"
          style={{
            backgroundColor: theme.backgroundColorFaded,
          }}></div>

        {/* SEARCH RESULTS */}
        <div className="search-results-container">
          {Object.keys(groupedResults).map((modelKey) => {
            const groupModel = profileModels.find((model) => model.key === modelKey);

            // Skip permission check for "events"
            if (modelKey === 'events' || hasCollectionPermission(profile, currentUser, groupModel, Permission.READ)) {
              return (
                <div key={modelKey}>
                  <div
                    className="search-group"
                    style={{
                      color: theme.foregroundColorFaded,
                    }}
                  >
                    {modelKey === 'events' ? 'Events' : groupModel ? groupModel.title : 'Unknown Model'}
                  </div>
                  {groupedResults[modelKey].map((result) => {
                    const flattenedIndex = flattenedResults.indexOf(result);
                    return (
                      <div
                        key={result.id}
                        ref={(el) => (resultsRef.current[flattenedIndex] = el)}
                        className="search-result"
                        style={{
                          color:
                            flattenedIndex === selectedResultIndex
                              ? theme.highlightForegroundColor
                              : theme.foregroundColor,
                          backgroundColor:
                            flattenedIndex === selectedResultIndex
                              ? theme.highlightBackgroundColor
                              : 'transparent',
                        }}
                        onClick={() => handleResultClick(result)}
                      >
                        {result.objectTitle || result.title}
                      </div>
                    );
                  })}
                </div>
              );
            }
            return null; // Return null if no permission
          })}

        </div>

      </Modal>
    </>
  );
};

export default Search;
