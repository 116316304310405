import React from 'react';

// Styles
import './ConversationsToolbar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import MessagesFilledIcon from '../../../common/svg/MessagesFilledIcon';

// Components
import { Toolbar, Left, Right } from '../../../common/components/toolbar/Toolbar';
import ToolbarIcon from '../../../common/components/toolbar/icon/ToolbarIcon';
import ToolbarTitle from '../../../common/components/toolbar/title/ToolbarTitle';

const ConversationsToolbar = () => {
    const { theme } = useTheme();

    return (

        <Toolbar>

            <Left>

                {/* ICON */}
                <ToolbarIcon 
                    icon={MessagesFilledIcon} 
                />

                {/* TITLE */}
                <ToolbarTitle
                    title="Rooms"
                />

            </Left>
            <Right>

            </Right>

        </Toolbar>

    );
};

export default ConversationsToolbar;
