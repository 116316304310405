import React, { useEffect } from 'react';

// Utilities
import { ItemSize } from '../../../../../common/utilities/Enums';

// Styles
import './NoteGridItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import GridItem from '../../../../components/grid/griditem/GridItem';
import GridItemTitle from '../../../../components/grid/griditem/title/GridItemTitle';

/**
 * NoteGridItem
 * 
 * A grid item for displaying notes.
 * 
 * @param {Object} item The note item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} NoteGridItem component.
 */
const NoteGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart, size = ItemSize.SMALL }) => {
    
    // Theme
    const { theme } = useTheme();

    // Local State
    const [firstBlockText, setFirstBlockText] = React.useState(null);
    const [blockCount, setBlockCount] = React.useState(0);

    // Find the first image URL from the blocks
    const firstImageUrl = item.blocks
        ?.find(block => block.type === "PHOTOS" && block.content?.[0]?.url)
        ?.content[0]?.url;

    // Check for blocks with type "TEXT" and print them to the console
    useEffect(() => {
        if (item.blocks) {
            const textBlocks = item.blocks.filter(block => block.type === "TEXT");
            if (textBlocks.length > 0) {
                setFirstBlockText(textBlocks[0].content);
            }
        }

        setBlockCount(item.blocks?.length || 0);
    }, [item]);

    return (
        <GridItem
            selected={selected}
            shared={item.shared}
            trashed={item.trashed}
            onClick={(event) => {
                if (onClick) onClick(event, item); 
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(event) => {
                if (onDragStart)  onDragStart(event, item);
            }}
            size={size}>

            <div
                className="note-grid-item-container"
                title={item.title}>

                {/* ICON OR IMAGE */}
                {firstImageUrl && (
                    <div
                        className="note-grid-item-image-wrapper">
                        <img
                            src={firstImageUrl}
                            alt={item.title}
                            className="note-grid-item-image"
                        />
                    </div>
                )}

                {/* TITLE */}
                <GridItemTitle
                    title={item.title}
                    selected={selected}
                />

                {/* FIRST BLOCK */}
                {firstBlockText && (
                    <div className="note-grid-item-first-block-text"
                        style={{
                            color: selected ? theme.highlightForegroundColor : theme.foregroundColorFaded,
                        }}>
                        {firstBlockText}
                    </div>
                )}

            </div>

        </GridItem>
    );
};

export default NoteGridItem;