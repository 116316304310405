import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../Global';

// Firebase
import { collections } from '../../../../../firebaseConfig';

// Styles
import './ModelEdit.css';

// Components
import Modal from '../../../../components/modal/Modal';
import ModalButton from '../../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../../components/modal/buttons/ModalButtons';
import ModalInput from '../../../../components/modal/input/ModalInput';
import ModalLabel from '../../../../components/modal/label/ModalLabel';
import ModalSection from '../../../../components/modal/section/ModalSection';
import ModalTextarea from '../../../../components/modal/textarea/ModalTextarea';

// Managers
import DataManager from '../../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * ModelEdit Component
 * 
 * This component allows the user to edit a model.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModelEdit = ({ isOpen, setOpen }) => {

    // Global
    const {
        profile,
        selectedModel,
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    // Load the selectedModel attributes
    useEffect(() => {
        if (!selectedModel) return;
        setTitle(selectedModel.title);
        setDescription(selectedModel.description || '');
    }, [selectedModel]);

    const handleSave = async () => {

        const data = {
            title: title,
            description: description,
        };

        await dataManager.update(collections.models, profile.key, selectedModel.key, data);

        setOpen(false);
    };

    if (!selectedModel) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title="Model Settings"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Model Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* SAVE */}
                    <ModalButton
                        onClick={handleSave}
                        label="Save"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default ModelEdit;
