import React from 'react';

// Styles
import './SiteCanvasColumn.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import SwapIcon from '../../../../common/svg/SwapIcon';

// Components
import SiteCanvasBlock from '../canvasblock/SiteCanvasBlock';

// Column container for managing drag-and-drop reordering and blocks within a column
const SiteCanvasColumn = ({ column, index, selectedColumn, selectedBlock, selectedRoomElement, onMoveColumn, onColumnSelect, onBlockSelect, onDrop, onElementSelect, onElementDoubleClick, useDrag, useDrop }) => {

    // Theme
    const { theme } = useTheme();

    // References
    const ref = React.useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: 'COLUMN',
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover(item, monitor) {
            if (!ref.current) return;
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            onMoveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={`site-canvas-column-container ${isDragging ? 'dragging' : ''}`}
            data-handler-id={handlerId}
            style={{
                opacity: isDragging ? 0.5 : 1,
                borderColor: selectedColumn === column.id ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                borderStyle: selectedColumn === column.id ? "solid" : "dotted",
            }}
            onClick={() => onColumnSelect(column.id)}>
            {column.blocks.map((block) => (
                <SiteCanvasBlock
                    key={block.id} // Unique key is necessary here
                    block={block}
                    columnId={column.id}
                    selected={selectedBlock && selectedBlock.blockId === block.id && selectedBlock.columnId === column.id}
                    onSelect={() => onBlockSelect(column.id, block.id)}
                    onDrop={onDrop}
                    onElementSelect={onElementSelect}
                    onElementDoubleClick={onElementDoubleClick}
                    selectedRoomElement={selectedRoomElement}
                    useDrop={useDrop}
                />
            ))}
            <SwapIcon
                color={selectedColumn === column.id ? theme.highlightBackgroundColor : theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
    
};

export default SiteCanvasColumn;

