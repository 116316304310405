import React from 'react';

const RoomsIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M424-242q13 11 27.5 15.5T480-222q29 0 52.5-18.5T560-294q5-47-29-69.5T480-422q-5 14-5 26t3 26q3 17 7 32t1 32q-5 18-22 37t-40 27ZM120-120v-720h720v720H120Zm40-40h107.69v-40h119.23Q357-222.85 338.5-256.77T320-322q0-41.38 10.77-76.5t31.5-65.81Q383-495 413.85-521.73q30.84-26.73 70.77-50.58 6.69 42.46 31.03 79.66 24.35 37.19 71.89 72.03 25.31 19.39 38.88 44.58Q640-350.85 640-320q0 32.69-13.69 64.12-13.69 31.42-43.23 55.88h109.23v40H800v-640H160v640Z" />
  </svg>

);

export default RoomsIcon;
