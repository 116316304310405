import { useEffect, useContext } from 'react';

// Enums
import { SectionType } from '../../../../common/utilities/Enums';

// Global
import { Global } from '../../../../Global';

/**
 * Determines the contents of the folder when it is selected.
 */
const useSetFolderItems = ({ setFolderItems }) => {

    // Global
    const {
        privateItems,
        selectedFolder
    } = useContext(Global);

    useEffect(() => {

        if (!privateItems) return;

        // Filter logic
        const filterItems = () => {
            let filteredItems = privateItems;

            filteredItems = filteredItems.filter((item) => item.folderKey === (selectedFolder && selectedFolder.key || null));
           
            return filteredItems;
        };

        // Apply the filter and set the folder items
        const combinedItems = filterItems();
        setFolderItems(combinedItems);

    }, [privateItems, selectedFolder]);

};

export default useSetFolderItems;