import React, { useContext } from 'react';

// Styles
import './ObjectsToolbar.css';

// Global
import { Global } from '../../../Global';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import CollectionMenu from '../menu/CollectionMenu';

const ObjectsToolbar = () => {
    const { theme } = useTheme();
    const {
        selectedModel,
    } = useContext(Global);

    return (
        <>
            <div className="objects-toolbar-container">
                <div className="objects-toolbar-buttons">
                    <div className="objects-toolbar-left"
                        style={{ color: theme.foregroundColorFaded }}>
                        {selectedModel && selectedModel.title}
                    </div>
                    <div className="objects-toolbar-right" >
                        {selectedModel &&
                            <>
                                {/* BUTTONS */}

                                {/* VIEW MENU */}
                                <CollectionMenu />
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ObjectsToolbar;
