import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Translation
import { useTranslation } from 'react-i18next';

// Styles
import './FolderToolbar.css';

// Theme
import { useStyle, useTheme } from '../../../../ThemeContext';

// Images
import CopyIcon from '../../../../common/svg/CopyIcon';
import CutIcon from '../../../../common/svg/CutIcon';
import FolderFilledIcon from '../../../../common/svg/FolderFilledIcon';
import PasteIcon from '../../../../common/svg/PasteIcon';
import SeparatorIcon from '../../../../common/svg/SeparatorIcon';
import TrashIcon from '../../../../common/svg/TrashIcon';
import ShareIcon from '../../../../common/svg/ShareIcon';
import SettingsIcon from '../../../../common/svg/SettingsIcon';
import UnshareIcon from '../../../../common/svg/UnshareIcon';

// Components
import Divider from '../../../components/divider/Divider';
import { Toolbar, Left, Right } from '../../../components/toolbar/Toolbar';
import ToolbarButton from '../../../components/toolbar/button/ToolbarButton';
import ToolbarIcon from '../../../components/toolbar/icon/ToolbarIcon';
import ToolbarTitle from '../../../components/toolbar/title/ToolbarTitle';

// Styled Components
const InlineStyles = useStyle`
    .folder-toolbar-link {
        background-color: ${(props) => props.folderBackgroundColor} !important;
        color: ${(props) => props.folderColor} !important;
    }
    .folder-toolbar-link:hover {
        background-color: ${(props) => props.folderBackgroundColorHovered} !important;
        color: ${(props) => props.folderColorHovered} !important;
    }
`;

/**
 * FolderToolbar Component
 * 
 * This component renders a folder toolbar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const FolderToolbar = ({ folderItems, pathKeys, getShareCount, onDropItem, onCopyClicked, onCutClicked, onPasteClicked, onDeleteClicked, onShareClicked, onUnshareClicked, onSettingsClicked }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
        hideProgress,
        selectedFolder,
        selectedFolderItems,
        setSelectedFolder,
        showProgress,
        profileFolders,
        profileItems,
    } = useContext(Global);

    // Local State
    const [shareCount, setShareCount] = useState(0);

    // Translation
    const { t } = useTranslation('Folder');

    useEffect(() => {
        if (!selectedFolderItems) return;
        const sharedItems = folderItems.filter(item => item.shared === true);
        setShareCount(sharedItems.length);
    }, [selectedFolderItems]);

    // Helper function to get a folder by key
    const getFolderByKey = (key) => profileFolders.find(folder => folder.key === key);

    /**
     * Handles dropping of files into the component.
     * 
     * @param {event} event - Event object.
     */
    const handleDrop = async (event, folder) => {
        event.preventDefault();
        event.stopPropagation();

        const draggedItemKey = event.dataTransfer.getData("key");
        const draggedItemType = event.dataTransfer.getData("type");
        if (draggedItemKey) {
            showProgress("Moving item...");
            await onDropItem(draggedItemKey, draggedItemType, (folder && folder.key) || null); // Call handler with file, type and target folder keys
            hideProgress();
        } else {
            console.error("No key found in dataTransfer");
        }
    };

    /**
     * Checks whether any selected items are shared.
     */
    const anyShared = () => {
        const sharedItems = selectedFolderItems.filter(item => item.shared === true);
        return sharedItems.length > 0;
    };

    /**
     * Checks whether any selected items are shared.
     */
    const anyUnshared = () => {
        const unsharedItems = selectedFolderItems.filter(item => item.shared !== true);
        return unsharedItems.length > 0;
    };

    // Verify profile
    if (!profile) return null;

    return (
        <>
            <InlineStyles
                folderColor={theme.foregroundColor}
                folderColorHovered={theme.foregroundColor}
                folderBackgroundColor={theme.backgroundColor}
                folderBackgroundColorHovered={theme.highlightBackgroundColor}
            />

            {/* CONTAINER */}
            <div className="folder-toolbar-container">

                {/* TOP */}
                <div className="folder-toolbar-top">

                    <Toolbar>

                        <Left>

                            {/* ICON */}
                            <ToolbarIcon
                                icon={FolderFilledIcon}
                            />

                            {/* TITLE */}
                            <ToolbarTitle
                                title={selectedFolder && selectedFolder.title || t('Folder:toolbar_title')}
                            />

                        </Left>
                        
                        <Right>

                            {selectedFolderItems && selectedFolderItems.length > 0 &&
                                <>
                                    {/* COPY */}
                                    <ToolbarButton
                                        icon={CopyIcon}
                                        title="Copy"
                                        onClick={onCopyClicked}
                                    />

                                    {/* CUT */}
                                    <ToolbarButton
                                        icon={CutIcon}
                                        title="Cut"
                                        onClick={onCutClicked}
                                    />

                                    {/* PASTE */}
                                    <ToolbarButton
                                        icon={PasteIcon}
                                        title="Paste"
                                        onClick={onPasteClicked}
                                    />

                                    {/* UNSHARE */}
                                    {anyShared() &&
                                        <ToolbarButton
                                            icon={UnshareIcon}
                                            title="Unshare"
                                            onClick={onUnshareClicked}
                                        />
                                    }

                                    {/* SHARE */}
                                    {anyUnshared() &&
                                        <ToolbarButton
                                            icon={ShareIcon}
                                            title="Share"
                                            onClick={onShareClicked}
                                        />
                                    }

                                    {/* DELETE */}
                                    <ToolbarButton
                                        icon={TrashIcon}
                                        title="Trash"
                                        onClick={onDeleteClicked}
                                    />

                                    {/* DIVIDER */}
                                    <Divider
                                        direction="vertical"
                                        color={theme.backgroundColorFaded}
                                        height="20px"
                                        margin="3px"
                                    />
                                </>
                            }

                            {/* SETTINGS */}
                            {selectedFolder &&
                                <ToolbarButton
                                    icon={SettingsIcon}
                                    title="Settings"
                                    onClick={onSettingsClicked}
                                />
                            }

                        </Right>

                    </Toolbar>


                </div>

                {/* BOTTOM */}
                <div className="folder-toolbar-bottom">

                    {/* PATH */}
                    <div className="folder-toolbar-path"
                        style={{
                            color: theme.foregroundColorFaded,
                            fontFamily: profile.fontFamily,
                        }}>

                        {/* ROOT */}
                        <span
                            onClick={() => setSelectedFolder(null)}
                            style={{ cursor: 'pointer' }}
                            className="folder-toolbar-link"
                            onDrop={(event) => handleDrop(event, null)}>
                            {t('Folder:breadcrumb_root')}
                        </span>

                        {/* SEPARATOR */}
                        {pathKeys.length > 0 && (
                            <span className="folder-toolbar-separator">
                                <SeparatorIcon
                                    color={theme.foregroundColorFaded}
                                    width="14"
                                    height="14"
                                />
                            </span>
                        )}

                        {pathKeys.map((key, index) => {
                            const folder = getFolderByKey(key); // Get folder by key
                            if (!folder) return null;

                            return (
                                <span key={key} className="folder-toolbar-item">

                                    {/* FOLDER */}
                                    <span
                                        onClick={() => setSelectedFolder(folder)}
                                        style={{ cursor: 'pointer' }}
                                        className="folder-toolbar-link"
                                        onDrop={(event) => handleDrop(event, folder)}>
                                        {folder && folder.title || "Home"}
                                    </span>

                                    {/* SEPARATOR */}
                                    {index < pathKeys.length - 1 && (
                                        <span className="folder-toolbar-separator">
                                            <SeparatorIcon
                                                color={theme.foregroundColorFaded}
                                                width="14"
                                                height="14"
                                            />
                                        </span>
                                    )}

                                </span>
                            );
                        })}

                    </div>
                </div>
            </div>
        </>
    );
};

export default FolderToolbar;
