import React from 'react';

// Styles
import './ParticipantsBar.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ParticipantsBar = ({ item, displayCount }) => {
    const { theme } = useTheme();

    // Extract room participants (max 6)
    const participants = item.profiles.slice(0, displayCount);
    const extraCount = item.profiles.length > displayCount ? item.profiles.length - displayCount : 0;

    return (
        <div
            className="participants-bar-photos-container">
            {participants.map((participant, index) => (
                <img
                    key={index}
                    src={participant.photo}
                    alt={`${participant.firstName} ${participant.lastName}`}
                    className="participants-bar-photo"
                />
            ))}
            {extraCount > 0 && (
                <div className="participants-bar-extra"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    +{extraCount}
                </div>
            )}
        </div>
    );
};

export default ParticipantsBar;
