import React, { useEffect } from 'react';

// Styles
import './ModalSwitch.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Switch from '../../switch/Switch';

/**
 * ModalSwitch Component
 * 
 * This component renders a switch input field for a modal dialog.
 */
const ModalSwitch = ({ label, checked, setChecked }) => {
    const { theme } = useTheme();

    return (
        <>
            {/* TOP SECTION */}
            <div className="modal-switch-container">

                {/* LABEL */}
                <div className="modal-switch-label"
                    style={{ color: theme.foregroundColorFaded }}>
                    {label}
                </div>

                {/* SWITCH */}
                <Switch
                    checked={checked}
                    setChecked={setChecked}
                />

            </div>
        </>
    );
};

export default ModalSwitch;
