import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { ItemType } from '../../../utilities/Enums';
import { getFolderPath } from '../../../utilities/Folders';

/**
 * Prepares the folder path keys when a folder is selected, for
 * use in the breadcrumb navigation.
 */
const useSetFolderPath = ({ setPathKeys }) => {

    // Global
    const {
        privateItems,
        selectedFolder
    } = useContext(Global);

    useEffect(() => {
        if (selectedFolder) {
            // Get all folders in privateItems
            const folders = privateItems.filter(item => item.type === ItemType.FOLDER);
            const keys = getFolderPath(folders);
            setPathKeys(keys);
        } else {
            setPathKeys([]);
        }
    }, [privateItems, selectedFolder]);

};

export default useSetFolderPath;
