import React from 'react';

// Styles
import './SiteCanvasBlock.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Droppable Block
const SiteCanvasBlock = ({ block, columnId, selected, onSelect, onDrop, onElementSelect, onElementDoubleClick, selectedRoomElement, useDrop }) => {

    // Theme
    const { theme } = useTheme();

    // References
    const ref = React.useRef(null); // Create a ref for the block

    const [{ isOver }, drop] = useDrop({
        accept: 'ELEMENT',
        drop: (item) => onDrop(columnId, block.id, item.element),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    drop(ref); // Ensure the ref is passed to the drop function

    return (
        <div
            ref={ref} // Attach the ref here to the block div
            className="site-canvas-block-container"
            onClick={(e) => {
                e.stopPropagation();  // Prevent column selection when block is clicked
                onSelect();           // Select the block
            }}
            style={{
                justifyContent: block.align, // Use justify-content for alignment since flex is used
                borderColor: isOver ? theme.foregroundColorFaded : selected ? theme.foregroundColor : theme.foregroundColorFaded,
                borderStyle: selected ? "solid" : "dotted",
            }}>
            {block.content.length > 0
                ? block.content.map((element) => (
                    <div key={element.key}
                        className="site-canvas-block-element"
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent block selection
                            onElementSelect(element);
                        }}
                        onDoubleClick={(e) => {
                            e.stopPropagation();  // Prevent block selection
                            onElementDoubleClick(element);
                        }}
                        style={{
                            color: theme.foregroundColor,
                            justifyContent: 'center',
                            borderColor: selectedRoomElement?.key === element.key ? theme.foregroundColor : theme.foregroundColorFaded,
                            borderStyle: selectedRoomElement?.key === element.key ? "solid" : "dotted",
                        }}>

                        {/* TITLE */}
                        {element.title}

                    </div>
                )
                ) : (
                    <div className="site-canvas-block-empty"
                        style={{
                            color: theme.backgroundColorFaded,
                        }}>
                    </div>
                )
            }
        </div>
    );
};

export default SiteCanvasBlock;

