import React, { useContext, useEffect, useState, useRef } from 'react';

// Global
import { Global } from '../../../Global.js';

// Firebase
import { collections } from '../../../firebaseConfig.js';

// Drag/Drop
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Utilities
import { generateKey } from '../../utilities/Keys.js';

// Styles
import './Studio.css';

// Theme
import { useTheme } from '../../../ThemeContext.js';

// Images
import DeleteIcon from '../../svg/DeleteIcon.jsx';
import DragIcon from '../../svg/DragIcon.jsx';
import PlusIcon from '../../svg/PlusIcon.jsx';
import SaveIcon from '../../svg/SaveIcon.jsx';

// Components
import StudioHeader from './header/StudioHeader.js';
import FieldSelector from '../../form/fieldselector/FieldSelector.js';
import ModelAdd from './models/add/ModelAdd.js';
import ModelDelete from './models/delete/ModelDelete';
import ModelEdit from './models/edit/ModelEdit';
import FieldDelete from './models/fields/delete/FieldDelete';
import FieldEdit from './models/fields/edit/FieldEdit';
import Models from './models/Models.js';
import Section from '../../components/section/Section';
import StudioToolbar from './toolbar/StudioToolbar';


// Managers
import DataManager from '../../managers/DataManager.js';

// Import the item types we need
import {
  DRAGGABLE_NEW_FIELD_TYPE
} from '../../form/fieldselector/FieldSelector.js';

const dataManager = new DataManager();

// Existing fields reordering
const DRAGGABLE_FIELD_TYPE = 'FIELD';

const DraggableField = ({
  field,
  index,
  moveField,
  theme,
  addNewFieldAtIndex, // function to add a new field if we detect a new field was dropped
  onFieldClick,
  selectedFormField
}) => {
  const ref = useRef(null);

  // Drop hook
  const [{ handlerId }, drop] = useDrop({
    accept: [DRAGGABLE_FIELD_TYPE, DRAGGABLE_NEW_FIELD_TYPE],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index; // might be undefined for new fields
      const hoverIndex = index;

      // If it's a *new* field (coming from FieldSelector), it won't have `item.index`
      if (item.isNew) {
        // We can insert the new field at hoverIndex, but let's do it on "drop" to avoid repeated inserts.
        return;
      }

      // ==========================
      // REORDERING EXISTING FIELDS
      // ==========================
      // Avoid reordering with self
      if (dragIndex === hoverIndex) {
        return;
      }

      // This logic ensures we only move after half of the hovered item
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Perform the reordering of existing fields
      moveField(dragIndex, hoverIndex);

      // Update the drag index so it doesn't cause issues from multiple hovers
      item.index = hoverIndex;
    },
    drop(item, monitor) {
      // We handle creation of a new field on drop instead of hover.
      if (item.isNew) {
        // Insert the new field at the current index
        addNewFieldAtIndex(item.fieldType, item.fieldName, item.modelKey, item.checklistModelKey, index);
      }
    },
  });

  // Drag hook
  const [{ isDragging }, drag] = useDrag({
    type: DRAGGABLE_FIELD_TYPE,
    item: () => ({
      id: field.key,
      index,
      isNew: false, // existing field
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      title="Drag to reorder"
      className="designer-field"
      onClick={() => onFieldClick(field)}
      style={{
        opacity,
      }}>
      <div className="designer-field-wrapper"
        style={{
          borderColor: (selectedFormField && selectedFormField.key === field.key) ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
          borderStyle: (selectedFormField && selectedFormField.key === field.key) ? "solid" : "dotted",
          color: theme.foregroundColor,
          opacity,
        }}>
        <div className="designer-field-title"
          style={{
            color: theme.foregroundColor,
          }}>
          {field.title}
        </div>
        <div className="designer-field-type"
          style={{
            color: theme.foregroundColorFaded,
          }}>
          {field.type}
        </div>
      </div>

      <DragIcon
        color={theme.foregroundColorFaded}
        width="16"
        height="16"
      />
    </div>
  );
};

const Studio = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
    profileFields,
    profileModels,
    selectedModel,
    selectedFormField,
    setModelAddOpen,
    setSelectedFormField
  } = useContext(Global);


  // Local State
  const [fields, setFields] = useState([]);
  const [addedFields, setAddedFields] = useState([]);
  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [deleteFieldOpen, setDeleteFieldOpen] = useState(false);
  const [editFieldOpen, setEditFieldOpen] = useState(false);
  const [editModelOpen, setEditModelOpen] = useState(false);
  const [fieldTitle, setFieldTitle] = useState("");
  const [fieldDescription, setFieldDescription] = useState("");
  const [needsSave, setNeedsSave] = useState(false);

  /**
   * Saves the form when the needsSave flag is set.
   */
  useEffect(() => {
    if (needsSave && selectedModel) {
      save();
      setNeedsSave(false);
    }
  }, [needsSave, selectedModel]);

  /**
   * Detect changes in the fields array and set the needsSave flag.
   */
  useEffect(() => {
    setNeedsSave(true);
  }, [addedFields]);

  /**
   * Update the form title and form description when model is selected.
   */
  useEffect(() => {
    if (!selectedModel) return;
    setFormTitle(selectedModel.title || "");
    setFormDescription(selectedModel.description || "");
  }, [selectedModel]);

  /**
   * Update the field title and field description when a new field is selected.
   */
  useEffect(() => {
    if (!selectedFormField) return;
    setFieldTitle(selectedFormField.title || "");
    setFieldDescription(selectedFormField.description || "");
  }, [selectedFormField]);

  // Sorts fields by the order in selectedModel.fieldSort.
  const sortFieldsByCollectionOrder = (unsortedFields, fieldSortArray) => {
    if (!fieldSortArray || !Array.isArray(fieldSortArray)) {
      return unsortedFields;
    }
    const sortIndexMap = fieldSortArray.reduce((acc, key, idx) => {
      acc[key] = idx;
      return acc;
    }, {});
    return unsortedFields.sort((a, b) => {
      const aIndex = sortIndexMap[a.key] ?? Infinity;
      const bIndex = sortIndexMap[b.key] ?? Infinity;
      return aIndex - bIndex;
    });
  };

  useEffect(() => {
    if (!selectedModel) return;
    const relevantFields = profileFields.filter(
      (f) => f.modelKey === selectedModel.key
    );
    const sorted = sortFieldsByCollectionOrder(
      relevantFields,
      selectedModel.fieldSort
    );
    setFields(sorted);
  }, [selectedModel, profileFields]);

  /**
   * Reorders the existing fields
   */
  const moveField = (dragIndex, hoverIndex) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      const [removed] = updatedFields.splice(dragIndex, 1);
      updatedFields.splice(hoverIndex, 0, removed);
      return updatedFields;
    });
  };

  /**
   * Inserts a newly created field into the array at a specific index.
   */
  const addNewFieldAtIndex = (fieldType, fieldName, modelKey, checklistModelKey, index) => {
    if (!selectedModel) return;

    // Build the new field object
    const newField = {
      key: generateKey(),
      title: fieldName,    // for now, just use the fieldName as the title
      type: fieldType,
    };

    // Add related 'modelKey' only if it is not null or undefined
    if (modelKey != null) {
      newField.modelKey = modelKey;
    }

    // Add 'checklistModelKey' only if it is not null or undefined
    if (checklistModelKey != null) {
      newField.checklistModelKey = checklistModelKey;
    }

    // Insert into local state
    setFields((prevFields) => {
      const updated = [...prevFields];
      updated.splice(index, 0, newField);
      return updated;
    });

    // Insert into addedFields array
    setAddedFields((prevFields) => {
      const updated = [...prevFields];
      updated.push(newField);
      return updated;
    });

    // Select the new field
    setSelectedFormField(newField);
  };

  /**
   * Saves the updated fields, extracting the new fieldSort array.
   */
  const save = async () => {
    // Iterate through the addedFields and add every field
    for (let i = 0; i < addedFields.length; i++) {
      const field = addedFields[i];
      field.profileKey = profile.key;
      field.modelKey = selectedModel.key;

      await dataManager.add(collections.fields, profile.key, field.key, field);
    }

    // Need to iterate all the fields and update the fieldSort array
    const fieldSort = fields.map(f => f.key);

    // Update the model
    const updatedModel = {
      ...selectedModel,
      fieldSort: fieldSort,
    };
    await dataManager.update(collections.models, profile.key, selectedModel.key, updatedModel);

    // Update the remaining fields
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      field.profileKey = profile.key;
      field.modelKey = selectedModel.key;

      await dataManager.update(collections.fields, profile.key, field.key, field);
    }
  };

  /**
   * Handles field selection.
   */
  const handleFieldClick = (field) => {
    setSelectedFormField(field);
  };

  /**
   * Updates a field in the fields array.
   * 
   * @param {string} name - The field name to update.
   * @param {string} value - The new value.
   * 
   * @returns {void}
   **/
  const updateField = (name, value) => {
    // Update the field in the formFields state
    const updatedFormFields = fields.map(item =>
      item.key === selectedFormField.key ? { ...item, [name]: value } : item
    );

    setFields(updatedFormFields);
  };

  /**
   * Handles an add model button click event.
   */
  const handleAddModelClick = () => {
    setModelAddOpen(true);
  };

  /**
   * Handles an edit model button click event.
   */
  const handleEditModelClick = () => {
    setEditModelOpen(true);
  };

  /**
   * Handles a delete model button click event.
   */
  const handleDeleteModelClick = () => {
    setDeleteModelOpen(true);
  };

  /**
   * Handles an edit field button click event.
   */
  const handleEditFieldClick = () => {
    setEditFieldOpen(true);
  };

  const handleDeleteFieldClick = () => {
    setDeleteFieldOpen(true);
  };

  // Verify profile
  if (!profile) return null;

  return (

    <Section>

      {/* TOOLBAR */}
      <StudioToolbar
        onAddModelClick={handleAddModelClick}
        onEditModelClick={handleEditModelClick}
        onDeleteModelClick={handleDeleteModelClick}
        onDeleteFieldClick={handleDeleteFieldClick}
        onEditFieldClick={handleEditFieldClick}
      />

      <div className="studio-container">

        {/* DESIGNER */}
        <DndProvider backend={HTML5Backend}>

          <div className="designer-container">

            {/* MODELS PANEL */}
            <div className="designer-models-wrapper"
              style={{
                borderRightColor: theme.backgroundColorFaded,
              }}>

              {/* HEADER */}
              <StudioHeader title="Models" />

              {/* MODELS */}
              <Models />

            </div>

            {/* CANVAS PANEL */}
            {selectedModel &&
              <>
                <div className="designer-canvas-wrapper">

                  {/* HEADER */}
                  <StudioHeader title={`${selectedModel.title} Fields`} />

                  {/* MAIN CANVAS WITH DRAGGABLE FIELDS */}
                  <div className="designer-canvas">
                    {fields.map((field, index) => (
                      <DraggableField
                        key={field.key}
                        field={field}
                        index={index}
                        moveField={moveField}
                        theme={theme}
                        addNewFieldAtIndex={addNewFieldAtIndex}
                        onFieldClick={handleFieldClick}
                        selectedFormField={selectedFormField}
                      />
                    ))}
                  </div>

                </div>

                {/* FIELDS PANEL */}
                <div
                  className="designer-selector-wrapper"
                  style={{
                    borderLeftColor: theme.backgroundColorFaded,
                  }}>

                  {/* HEADER */}
                  <StudioHeader title="Add Fields" />

                  <FieldSelector size="LARGE" />

                </div>
              </>
            }

            {/* FORM */}
            <ModelAdd />

          </div>

        </DndProvider>

      </div>

      {/* MODEL EDIT */}
      <ModelEdit
        isOpen={editModelOpen}
        setOpen={setEditModelOpen}
      />

      {/* MODEL DELETE */}
      <ModelDelete
        isOpen={deleteModelOpen}
        setOpen={setDeleteModelOpen}
      />

      {/* FIELD DELETE */}
      <FieldDelete
        isOpen={deleteFieldOpen}
        setOpen={setDeleteFieldOpen}
      />

      {/* FIELD EDIT */}
      <FieldEdit
        isOpen={editFieldOpen}
        setOpen={setEditFieldOpen}
      />

    </Section>

  );
};

export default Studio;
