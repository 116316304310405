import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './Models.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DataIcon from '../../../svg/DataIcon';

// Managers
import ProfileManager from '../../../managers/ProfileManager';

const profileManager = new ProfileManager();

/**
 * Models Component
 * 
 * This component renders a model-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Models = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profileModels,
        selectedModel,
        setSelectedModel,
    } = useContext(Global);

    /**
     * Handles a model click event.
     * 
     * @param {object} model - Model that was clicked on.
     */
    const handleModelClick = async (model) => {
        setSelectedModel(model);
    };

    return (
        <div className="models-container">

            {/* MODELS */}
            <div className="models-scroller">

                {profileModels.map(model => (

                    <React.Fragment key={model.key}>

                        <div className="models-item-container"
                            onClick={() => handleModelClick(model)}
                            style={{
                                backgroundColor: selectedModel && selectedModel.key === model.key ? theme.highlightBackgroundColor : 'transparent',
                            }}>

                            {/* TOP */}
                            <div className="models-item-top">

                                <div className="models-item-icon">
                                    <DataIcon
                                        color={selectedModel && selectedModel.key === model.key ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                                        width="14"
                                        height="14"
                                    />
                                </div>
                                <div className="models-item-text"
                                    style={{
                                        color: selectedModel && selectedModel.key === model.key ? theme.highlightForegroundColor : theme.foregroundColor,
                                    }}>
                                    {model.title}
                                </div>

                            </div>

                            {/* BOTTOM */}
                            <div className="models-item-bottom"
                                style={{
                                    color: selectedModel && selectedModel.key === model.key ? theme.highlightForegroundColor : theme.foregroundColorFaded,
                                }}>
                                {model.description}
                            </div>

                        </div>
                    </React.Fragment>
                ))}

            </div>

        </div>
    );
};

export default Models;
