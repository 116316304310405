import React from 'react';

const TrashIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M304.62-160q-26.85 0-45.74-18.88Q240-197.77 240-224.62V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.38q0 27.62-18.5 46.12Q683-160 655.38-160H304.62ZM680-720H280v495.38q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h350.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93V-720ZM392.31-280h40v-360h-40v360Zm135.38 0h40v-360h-40v360ZM280-720v520-520Z" />
  </svg>

);

export default TrashIcon;
