import React from 'react';

// Styles
import './ProfileItem.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Components
import ProfileDot from '../profiledot/ProfileDot';

/**
 * ProfileItem Component
 * 
 * This component renders an a summary of a profile.
 * 
 * @param {object} profile - The profile to represent.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ProfileItem = ({ item, onClick }) => {

    // Theme
    const {
        theme
    } = useTheme();

    return (

        <div key={item.key}
            className="profile-search-item-container"
            style={{
                backgroundColor: theme.backgroundColorFaded,
                color: theme.foregroundColor,
            }}
            onClick={onClick && onClick}>
            <div className="profile-search-item-image-wrapper">
                <ProfileDot
                    item={item}
                    size={30}
                />
            </div>
            <div className="profile-search-item-text-wrapper">
                {item.firstName} {item.lastName}
            </div>
        </div>

    );
};

export default ProfileItem;
