import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

// Styles
import './CalendarMonthDay.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const CalendarMonthDay = ({ day, dayWidth, dayHeight, dayHeaderHeight }) => {
    // Theme
    const { theme } = useTheme();

    // Global
    const {
        activeDate,
        setSelectedTime,
        setEventVisible,
        setSelectedEvent,
    } = useContext(Global);

    // Handle day click
    const handleDayClick = (day, month) => {
        const monthOffset = month === 'prev' ? -1 : month === 'next' ? 1 : 0;
        const date = new Date(activeDate.getFullYear(), activeDate.getMonth() + monthOffset, day);

        setSelectedEvent(null); // Clear selected event

        // Set selected time and show event form
        const selected = new Date(date.getTime());
        selected.setMinutes(0);
        selected.setSeconds(0);
        setSelectedTime(selected);
        setEventVisible(true);
    };

    return (
        <div className={`calendar-month-day-container ${day.month}`}
            style={{
                borderBottomColor: theme.backgroundColorFaded,
                borderRightColor: theme.backgroundColorFaded,
                maxHeight: `${dayHeight}px`,
                width: `${dayWidth}px`,
            }}
            onClick={() => handleDayClick(day.day, day.month)}>
            <div className="calendar-month-day-header"
                style={{
                    color: day.month === 'current' ? theme.foregroundColor : theme.foregroundColorFaded,
                    height: `${dayHeaderHeight}px`,
                }}>
                {day.day}
            </div>
        </div>
    );
};

export default CalendarMonthDay;
