import React from 'react';

// Styles
import './ProfileStats.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Divider from '../../../components/divider/Divider';
import ProfileStat from '../stat/ProfileStat';

const ProfileStats = ({ profile }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <>
            {/* FOLLOWING */}
            <ProfileStat
                count={4}
                label="Following"
            />

            <Divider
                color={theme.backgroundColorFaded}
                direction="vertical"
            />

            {/* FOLLOWERS */}
            <ProfileStat
                count={1900}
                label="Followers"
            />

            <Divider
                color={theme.backgroundColorFaded}
                direction="vertical"
            />

            {/* LIKES */}
            <ProfileStat
                count={143200}
                label="Likes"
            />
        </>
    );
};

export default ProfileStats;

