import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { SectionType } from '../../../common/utilities/Enums';

// Styles
import './Settings.css';

// Components
import AppDelete from '../../../common/appsettings/appdelete/AppDelete';
import MenuGroup from '../menugroup/MenuGroup';
import MenuItem from '../menuitem/MenuItem';
import RoomDesigner from '../../../desktop/roomdesigner/RoomDesigner';

/**
 * Settings Component
 * 
 * This component renders a channel-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Settings = () => {
    const {
        resetSelections,
        selectedSetting,
        setCurrentSection,
        setSelectedSetting,
        settings,
    } = useContext(Global);

    // State Variables
    const [deleteVisible, setDeleteVisible] = useState('');
    const [siteDesignerVisible, setRoomDesignerVisible] = useState(false);

    const handleOptionClick = (key) => {
        if (key === "DELETE") {
            handleDeleteClick();
            return;
        }
        if (key === "WEBSITE") {
            handleWebsiteClick();
            return;
        }

        resetSelections();
        setSelectedSetting(settings.find(option => option.key === key));
        setCurrentSection(SectionType.SETTINGS);
    }

    /**
     * Method to handle deleting an app.
     */
    const handleDeleteClick = async () => {
        setDeleteVisible(true);
    };

    /**
     * Method to handle website click.
     */
    const handleWebsiteClick = async () => {
        setRoomDesignerVisible(true);
    };

    return (
        <>
            <MenuGroup
                title="Settings"
                showAdd={false}>

                {/* SETTINGS */}
                {settings.map((option, index) => (
                    <MenuItem
                        key={index}
                        title={option.title}
                        icon={option.icon}
                        isActive={selectedSetting && selectedSetting.key === option.key}
                        onClick={() => handleOptionClick(option.key)}
                    />
                ))}

            </MenuGroup>

            {/* DELETE */}
            <AppDelete
                isOpen={deleteVisible}
                setOpen={setDeleteVisible}
            />

            {/* WEBSITE */}
            <RoomDesigner
                open={siteDesignerVisible}
                setOpen={setRoomDesignerVisible}
            />

        </>
    );
};

export default Settings;
