import React from 'react';

const DataFilledIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M480.77-519.23q133.85 0 226.92-40.85 93.08-40.84 93.08-99.15t-93.08-99.15q-93.07-40.85-226.92-40.85t-227.31 40.85Q160-717.54 160-659.23t93.46 99.15q93.46 40.85 227.31 40.85Zm-.77 61.54q48.69 0 99.04-7.73 50.34-7.73 94.65-22.89 44.31-15.15 78-37.57 33.69-22.43 49.08-51.81v118.46q-15.39 29.38-49.08 51.81-33.69 22.42-78 37.57-44.31 15.16-94.65 22.89-50.35 7.73-99.04 7.73-48.69 0-99.04-7.73-50.34-7.73-94.27-22.89-43.92-15.15-77.61-37.57-33.7-22.43-49.08-51.81v-118.46q15.38 29.38 49.08 51.81 33.69 22.42 77.61 37.57 43.93 15.16 94.27 22.89 50.35 7.73 99.04 7.73Zm0 180q48.69 0 99.04-7.73 50.34-7.73 94.65-22.89 44.31-15.15 78-37.57 33.69-22.43 49.08-51.81V-280q-15.39 29.38-49.08 51.81-33.69 22.42-78 37.57-44.31 15.16-94.65 22.89Q528.69-160 480-160q-48.69 0-99.04-7.73-50.34-7.73-94.27-22.89-43.92-15.15-77.61-37.57Q175.38-250.62 160-280v-117.69q15.38 29.38 49.08 51.81 33.69 22.42 77.61 37.57 43.93 15.16 94.27 22.89 50.35 7.73 99.04 7.73Z" />
    </svg>
);

export default DataFilledIcon;
