import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Managers
import DataManager from '../../../managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load events for a specific app.
 */
const usePreloadRoomProfiles = () => {

  // Global
  const { 
    selectedRoom, 
    setRoomProfiles 
  } = useContext(Global);

  useEffect(() => {
    if (!selectedRoom || !selectedRoom.key) {
      setRoomProfiles([]);
      return;
    }
    setRoomProfiles(selectedRoom.profiles || []);
  }, [selectedRoom, setRoomProfiles]);

};

export default usePreloadRoomProfiles;