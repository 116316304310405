// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import DataManager from '../../../managers/DataManager';

/**
 * Handles the drag start event for a folder item.
 */
export const handleDragStart = (event, item, selectedItems, setSelectedItems) => {
    // If the item isn't in the selected items, clear the selection
    if (!selectedItems.some((selected) => selected.key === item.key)) {
        setSelectedItems([item]);
    }

    // Drag the item whether multiple items are selected or not
    if (event && event.dataTransfer) {
        // Set data transfer context
        event.dataTransfer.setData("key", item.key);
        event.dataTransfer.setData("type", item.type);
    } else {
        console.error("DragStart event or dataTransfer is undefined");
    }
};

/**
 * Cancels events when dragging files into the component.
 */
export const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
};

/**
 * Handles drag enter of the gallery while dragging files.
 * 
 * @param {event} event - Event object.
 * @param {Object} dragCounter - Counter for drag events.
 * @param {Function} setIsDragging - Function to set the dragging state.
 * 
 * @returns {void}
 */
export const handleDragEnter = (event, dragCounter, setIsDragging) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current += 1;
    setIsDragging(true);
};

/**
 * Handles drag leave of the gallery while dragging files.
 * 
 * @param {event} event - Event object.
 * @param {Object} dragCounter - Counter for drag events.
 * @param {Function} setIsDragging - Function to set the dragging state.
 * 
 * @returns {void}
 */
export const handleDragLeave = (event, dragCounter, setIsDragging) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
        setIsDragging(false);
    }
};

/**
 * Handles dropping of an item into the folder.
 * 
 * @param {String} key - The key of the item being dropped.
 * @param {String} type - The type of the item being dropped.
 * @param {String} targetFolderKey - The key of the folder being dropped into.
 * @param {Object} profile - The current space.
 * @param {Array} selectedItems - The selected items.
 * 
 * @returns {Promise} The promise for the update operation.
 */
export const handleDropItem = async (key, type, targetFolderKey, profile, selectedItems) => {
    const dataManager = new DataManager();

    // Determine the items to be processed based on selection
    const itemsToDrop = selectedItems.length > 1
        ? selectedItems
        : [{ type, key }];

    // Perform the update for each item
    await Promise.all(
        itemsToDrop.map(async (item) => {
            await dataManager.update(
                collections.items,
                profile.key,
                item.key,
                { folderKey: targetFolderKey }
            );
        })
    );
};