import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './StudioToolbar.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DataFilledIcon from '../../../../common/svg/DataFilledIcon';
import DeleteIcon from '../../../../common/svg/DeleteIcon';
import PencilIcon from '../../../../common/svg/PencilIcon';
import PlusIcon from '../../../../common/svg/PlusIcon';
import SaveIcon from '../../../../common/svg/SaveIcon';

// Components
import Divider from '../../../components/divider/Divider';
import ToolbarButton from '../../../components/toolbar/button/ToolbarButton';
import { Toolbar, Left, Right } from '../../../components/toolbar/Toolbar';
import ToolbarIcon from '../../../components/toolbar/icon/ToolbarIcon';
import ToolbarTitle from '../../../components/toolbar/title/ToolbarTitle';

/**
 * Studio Toolbar
 * 
 * Renders a toolbar for the Studio section.
 * 
 * @param {function} onAddModelClick Fired when the add model button is clicked.
 * @param {function} onEditModelClick Fired when the edit model button is clicked.
 * @param {function} onDeleteModelClick Fired when the delete model button is clicked.
 * @param {function} onEditFieldClick Fired when the edit field button is clicked.
 * @param {function} onDeleteFieldClick Fired when the delete field button is clicked.
 * 
 * @returns {JSX.Element} StudioToolbar component.
 */
const StudioToolbar = ({ onAddModelClick, onEditModelClick, onDeleteModelClick, onEditFieldClick, onDeleteFieldClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedFormField,
        selectedModel,
    } = useContext(Global);

    return (
        <Toolbar>

            <Left>

                {/* ICON */}
                <ToolbarIcon
                    icon={DataFilledIcon}
                />

                {/* TITLE */}
                <ToolbarTitle
                    title="Database"
                />

            </Left>
            <Right>

                {/* ADD MODEL */}
                <ToolbarButton
                    title="Add Model"
                    icon={PlusIcon}
                    onClick={onAddModelClick}
                />

                {selectedModel &&
                    <>

                        {/* EDIT MODEL */}
                        {selectedModel &&
                            <ToolbarButton
                                title="Edit Model"
                                icon={PencilIcon}
                                onClick={onEditModelClick}
                            />
                        }

                        {/* DELETE MODEL */}
                        {selectedModel &&
                            <ToolbarButton
                                title="Delete Model"
                                icon={DeleteIcon}
                                onClick={onDeleteModelClick}
                            />
                        }

                        {selectedFormField &&
                            <>
                                <Divider
                                    direction="vertical"
                                    color={theme.backgroundColorFaded}
                                    height="20px"
                                    margin="3px"
                                />

                                {/* EDIT FIELD */}
                                <ToolbarButton
                                    title="Edit Field"
                                    icon={PencilIcon}
                                    onClick={onEditFieldClick}
                                />

                                {/* DELETE FIELD */}
                                {selectedModel.titleFieldKey !== selectedFormField.key &&
                                    <ToolbarButton
                                        title="Delete Field"
                                        icon={DeleteIcon}
                                        onClick={onDeleteFieldClick}
                                    />
                                }
                            </>
                        }

                        <Divider
                            direction="vertical"
                            color={theme.backgroundColorFaded}
                            height="20px"
                            margin="3px"
                        />
                    </>
                }

            </Right>

        </Toolbar>
    );
};

export default StudioToolbar;
