import React from 'react';

const PasteIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M224.62-160q-26.85 0-45.74-18.88Q160-197.77 160-224.62v-510.76q0-26.85 18.88-45.74Q197.77-800 224.62-800h185.46q5.61-23.46 24.92-39.42 19.31-15.97 45-15.97 26.15 0 45.35 15.97 19.19 15.96 24.8 39.42h185.23q26.85 0 45.74 18.88Q800-762.23 800-735.38v510.76q0 26.85-18.88 45.74Q762.23-160 735.38-160H224.62Zm0-40h510.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-510.76q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H640v89.23H320V-760h-95.38q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v510.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69ZM480-750.77q13.92 0 23.12-9.19 9.19-9.19 9.19-23.12 0-13.92-9.19-23.11-9.2-9.19-23.12-9.19-13.92 0-23.12 9.19-9.19 9.19-9.19 23.11 0 13.93 9.19 23.12 9.2 9.19 23.12 9.19Z" />
  </svg>

);

export default PasteIcon;
