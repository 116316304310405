import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './EventTitle.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const EventTitle = ({
  title,
  setTitle
}) => {
  const { theme } = useTheme();
  const { 
    profile,
    selectedEvent 
  } = useContext(Global);

  // References
  const titleRef = useRef(null);

  useEffect(() => {
    // Focus the input on load if adding an event
    if (titleRef.current && !selectedEvent) {
      titleRef.current.focus();
    }
  }, [title, selectedEvent]);

  return (
    <>
      <input
        type="text"
        ref={titleRef}
        className="event-title-container"
        placeholder="Add Title"
        style={{
          backgroundColor: theme.backgroundColorFaded,
          color: theme.foregroundColor,
          fontFamily: profile.fontFamily
        }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
    </>
  );
};

export default EventTitle;
