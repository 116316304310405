import React from 'react';

// Styles
import './ProfileButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ProfileButton = ({ title, width = "auto", icon: IconComponent, highlighted = false, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="profile-button-container"
            onClick={onClick}
            title={title}
            style={{
                backgroundColor: highlighted ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                color: highlighted ? theme.highlightForegroundColor : theme.foregroundColor,
                width: width
            }}>
            {IconComponent &&
                <IconComponent
                    color={theme.foregroundColor}
                    width="20"
                    height="20"
                />
            }
            {title &&
                <div className="profile-button-title">
                    {title}
                </div>
            }
        </div>
    );
};

export default ProfileButton;

