import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../../utilities/Keys';
import { defaultSummaryTitleStyles } from '../../../../utilities/Defaults';

// Styles
import './ModelAdd.css';

// Components
import Modal from '../../../../components/modal/Modal';
import ModalButton from '../../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../../components/modal/buttons/ModalButtons';
import ModalInput from '../../../../components/modal/input/ModalInput';
import ModalLabel from '../../../../components/modal/label/ModalLabel';
import ModalSection from '../../../../components/modal/section/ModalSection';
import ModalTextarea from '../../../../components/modal/textarea/ModalTextarea';

// Managers
import DataManager from '../../../../managers/DataManager';
import PromptManager from '../../../../managers/PromptManager';

const dataManager = new DataManager();
const promptManager = new PromptManager();

/**
 * ModelAdd Component
 * 
 * This component allows the user to add a model.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModelAdd = () => {

    // Global
    const {
        modelAddOpen,
        currentUser,
        hideProgress,
        profileModels,
        resetSelections,
        profile,
        setModelAddOpen,
        setSelectedModel,
        showProgress
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [autogen, setAutogen] = useState(false);
    const [autopop, setAutopop] = useState(false);

    /**
     * Adds a new model to the database.
     */
    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a model title.');
            return;
        }

        showProgress("Adding model...");

        setModelAddOpen(false);

        const profileKey = profile.key;

        const newModelKey = generateKey();  // Generate key for the new model

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each model to find the highest sort number
        profileModels.forEach(model => {
            const sortNumber = model.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        const titleFieldKey = generateKey();

        // We need to create a default title field if autogen is disabled
        const titleFieldData = {
            profileKey: profileKey,
            key: titleFieldKey,
            title: "Title",
            description: "Title of the item.",
            type: "text",
            modelKey: newModelKey,
            modelTitle: title,
            modelDescription: "",
            options: [],
        };

        const titleFieldContentKey = generateKey();

        const titleFieldBlock = {
            id: generateKey(),
            content: [{
                id: titleFieldContentKey,
                key: titleFieldContentKey,
                title: "Title",
                field: titleFieldData,
                type: "field",
            }],
            align: 'left',
        }

        // Create the model data
        const modelData = {
            key: newModelKey,
            profileKey: profileKey,
            title: title,
            description: description,
            sort: highestSortNumber + 1,
            fieldSort: [titleFieldKey], // Include the title field key in the field sort order
            titleFieldKey: titleFieldKey,
            rows: [{ id: generateKey(), blocks: [titleFieldBlock] }],
            styles: defaultSummaryTitleStyles,
            dateCreated: now,
            dateModified: now,
        };

        // Add the model to the database first
        await dataManager.add(
            collections.models,
            profileKey,
            newModelKey,
            modelData
        );

        // Generate default fields if autogen is enabled
        if (autogen) {
            await submitModelPrompt(profile, modelData, []);
        } else {
            // Add the title field to the database
            await dataManager.add(
                collections.fields,
                profileKey,
                titleFieldKey,
                titleFieldData
            );

            // Set the selected model
            setSelectedModel(modelData);
        }

        // Reset the title field
        setTitle('');

        // Reset the description field
        setDescription('');

        // Reset the autogen field if necessary
        //setAutogen(true);

        // Hide the progress panel
        hideProgress();
    };

    const submitModelPrompt = async (app, model, profileModels) => {

        try {

            const prompt = await promptManager.prepareModelPrompt(app.title, app.description, model.title, model.description, profileModels, autopop);

            const response = await promptManager.send(prompt);

            await promptManager.processModelResponse(
                response,
                app,
                model,
                showProgress,
                setSelectedModel,
                resetSelections,
                currentUser
            );

        } catch (error) {
            console.error('Failed to fetch API response:', error);
        }
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Model"
                isOpen={modelAddOpen}
                onClose={() => setModelAddOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Model Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* AI FIELD GENERATION 
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Generate Fields"
                        value={autogen}
                        onChange={() => setAutogen(!autogen)}
                    />

                </ModalSection>
                */}

                {/* AI DATA POPULATION 
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Populate Data"
                        value={autopop}
                        onChange={() => setAutopop(!autopop)}
                    />

                </ModalSection>
                */}

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label="Continue..."
                    />

                </ModalButtons>

            </Modal >
        </>
    );
};

export default ModelAdd;
