import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CalendarHeader.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CalendarFilledIcon from '../../../svg/CalendarFilledIcon';

// Components
import CalendarMenu from '../menu/CalendarMenu';
import CalendarToolbar from '../toolbar/CalendarToolbar';
import { Toolbar, Left, Right } from '../../../components/toolbar/Toolbar';
import ToolbarIcon from '../../../components/toolbar/icon/ToolbarIcon';
import ToolbarTitle from '../../../components/toolbar/title/ToolbarTitle';

const CalendarHeader = () => {
  const { theme } = useTheme();
  const {
    profile
  } = useContext(Global);

  return (

    <Toolbar>

      <Left>

        {/* ICON */}
        <ToolbarIcon
          icon={CalendarFilledIcon}
        />

        {/* TITLE */}
        <ToolbarTitle
          title="Calendar"
        />

        {/* CALENDAR TOOLBAR */}
        <CalendarToolbar />

      </Left>
      <Right>

        {/* MENU */}
        <CalendarMenu />

      </Right>

    </Toolbar>

  );
};

export default CalendarHeader;
