import React from 'react';

const MenuIcon = ({ color = "white", width = "24", height = "24" }) => (

    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M140-254.62v-59.99h680v59.99H140ZM140-450v-60h680v60H140Zm0-195.39v-59.99h680v59.99H140Z" />
    </svg>

);

export default MenuIcon;