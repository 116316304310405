import React, { useContext, useState, useRef, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Transition Group
import { SwitchTransition, CSSTransition } from 'react-transition-group';

// Styles
import './ProfileTabs.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * ProfileTabs Component
 */
const ProfileTabs = ({ tabsData }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
    } = useContext(Global);

    // Local State
    const [activeTab, setActiveTab] = useState(0);

    // References
    const prevTabRef = useRef(activeTab);

    /**
     * Whenever the activeTab changes, update the ref.
     */
    useEffect(() => {
        prevTabRef.current = activeTab;
    }, [activeTab]);

    /**
     * Determine the direction of the slide based on whether
     * the new tab index is higher or lower than the old tab index
     */
    const direction = activeTab > prevTabRef.current
        ? 'slide-left'
        : 'slide-right';

    /**
     * Event handler for when a user clicks on a tab.
     * 
     * @param {number} index - The index of the tab that was clicked
     */
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <div className="profile-tabs-container">

            {/* HEADER */}
            <div className="profile-tabs-header"
                style={{
                    width: "460px"
                }}>

                {tabsData.map((tab, index) => {

                    const IconComponent = tab.icon;

                    return (

                        <div
                            key={index}
                            title={tab.title}
                            className="profile-tab-header"
                            style={{
                                borderBottomColor: activeTab === index ? theme.foregroundColor : theme.backgroundColorFaded,
                            }}
                            onClick={() => handleTabClick(index)}>
                            {IconComponent && (
                                <IconComponent
                                    color={activeTab === index ? theme.foregroundColor : theme.foregroundColorFaded}
                                    width="20"
                                    height="20"
                                />
                            )}
                        </div>

                    );
                })}

            </div>

            {/* CONTENT */}
            <div className="profile-tabs-content">

                <SwitchTransition>
                    <CSSTransition
                        key={activeTab}
                        classNames={direction}
                        timeout={300}
                        unmountOnExit>
                        <div className="profile-tab-content">
                            {tabsData[activeTab]?.content || null}
                        </div>
                    </CSSTransition>
                </SwitchTransition>

            </div>
        </div>
    );
};

export default ProfileTabs;
