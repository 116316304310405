import React from 'react';

// Utilities
import { formatCount } from '../../../../common/utilities/Formatters';

// Styles
import './ProfileStat.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * ProfileStat Component
 * 
 * This component renders a profile stat.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ProfileStat = ({ count = 0, label }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <div className="profile-stat-container">
      <div className="profile-stat-value"
        style={{
          color: theme.foregroundColor,
        }}>
        {formatCount(count)}
      </div>
      <div className="profile-stat-label"
        style={{
          color: theme.foregroundColorFaded,
        }}>
        {label}
      </div>
    </div>
  );
};

export default ProfileStat;

