import React from 'react';

// Styles
import './CalendarStorylineYears.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const CalendarStorylineYears = ({ years, yearWidth }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="calendar-storyline-years-container"
            style={{
                borderTopColor: theme.backgroundColorFaded,
            }}>
            {years.map(year => (
                <div className="calendar-storyline-year" key={year}
                    style={{
                        borderBottomColor: theme.backgroundColorFaded,
                        borderRightColor: theme.backgroundColorFaded,
                        color: theme.foregroundColorFaded,
                        minWidth: `${yearWidth}px`,
                    }}>
                    {year}
                </div>
            ))}
        </div>
    );
};

export default CalendarStorylineYears;
