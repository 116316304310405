import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './FolderEdit.css';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../components/modal/buttons/ModalButtons';
import ModalInput from '../../../components/modal/input/ModalInput';
import ModalLabel from '../../../components/modal/label/ModalLabel';
import ModalSection from '../../../components/modal/section/ModalSection';
import ModalTextarea from '../../../components/modal/textarea/ModalTextarea';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * FolderEdit Component
 * 
 * This component allows the user to edit a folder.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const FolderEdit = ({ folder, isOpen, setOpen }) => {

    // Global
    const {
        profile,
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    // Load the folder attributes
    useEffect(() => {
        if (!folder) return;
        setTitle(folder.title);
        setDescription(folder.description || '');
    }, [folder]);

    const handleSave = async () => {
        const data = {
            title: title,
            description: description,
        };

        await dataManager.update(
            collections.items,
            profile.key,
            folder.key,
            data
        );

        setOpen(false);
    };

    if (!folder) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title="Collection Settings"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={isMobile ? "100%" : "330px"}
                height={isMobile ? "100%" : "auto"}>

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Title" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* SAVE */}
                    <ModalButton
                        onClick={handleSave}
                        label="Save"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default FolderEdit;
