import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ToolbarTitle.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ToolbarTitle = ({ title }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    return (

        <div className="toolbar-title-container"
            style={{
                color: theme.foregroundColorFaded,
                fontFamily: profile.fontFamily,
            }}>
            {title}
        </div>

    );
};

export default ToolbarTitle;
