import React from 'react';

// Styles
import './ButtonSummaryPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import TextIcon from '../../../../common/svg/TextIcon';
import CartIcon from '../../../../common/svg/CartIcon';
import FollowIcon from '../../../../common/svg/FollowIcon';
import CommentIcon from '../../../../common/svg/CommentIcon';
import DislikeIcon from '../../../../common/svg/DislikeIcon';
import DotIcon from '../../../../common/svg/DotIcon';
import DownloadIcon from '../../../../common/svg/DownloadIcon';
import DownvoteIcon from '../../../../common/svg/DownvoteIcon';
import EmojiIcon from '../../../../common/svg/EmojiIcon';
import LikeIcon from '../../../../common/svg/LikeIcon';
import PrintIcon from '../../../../common/svg/PrintIcon';
import PriorityIcon from '../../../../common/svg/PriorityIcon';
import RateOffIcon from '../../../../common/svg/RateOffIcon';
import ShareIcon from '../../../../common/svg/ShareIcon';
import UpvoteIcon from '../../../../common/svg/UpvoteIcon';

const ButtonSummaryPreview = ({ element }) => {
    const { theme } = useTheme();

    const iconMap = {
      cart: CartIcon,
      comment: CommentIcon,
      dislike: DislikeIcon,
      dot: DotIcon,
      download: DownloadIcon,
      downvote: DownvoteIcon,
      emoji: EmojiIcon,
      follow: FollowIcon,
      like: LikeIcon,
      print: PrintIcon,
      priority: PriorityIcon,
      rate: RateOffIcon,
      share: ShareIcon,
      text: TextIcon,
      upvote: UpvoteIcon,
    };

    // Dynamically choose the correct icon based on element.buttonType
    const IconComponent = iconMap[element.buttonType];

    return (
        <div className="button-summary-preview-container">
          <IconComponent
            color={theme.foregroundColor}
            width="14"
            height="14"
          />
        </div>
    );
};

export default ButtonSummaryPreview;
