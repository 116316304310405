import React from 'react';

// Styles
import './CalendarStorylineTags.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const CalendarStorylineTags = ({ tags, tagHeight, shades }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="calendar-storyline-tags-container">
            {tags.map((tag, index) => (
                <div className="calendar-storyline-tag" key={tag}
                    style={{
                        color: theme.foregroundColor,
                        borderBottomColor: theme.backgroundColorFaded,
                        borderRightColor: shades[index],
                        height: `${tagHeight}px`,
                    }}>
                    {tag}
                </div>
            ))}
        </div>
    );
};

export default CalendarStorylineTags;
