import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { SettingType } from '../../../common/utilities/Enums';

// Styles
import './Settings.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import SettingsToolbar from './toolbar/SettingsToolbar';
import Section from '../../components/section/Section';
import SettingsMenu from './menu/SettingsMenu';
import SettingsPhoto from '../profile/photo/ProfilePhoto';
import SettingTitle from './title/SettingTitle';

const Settings = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    selectedSetting,
    setProfile,
    setSelectedSetting,
  } = useContext(Global);

  const handleItemClick = async (option) => {
    setSelectedSetting(option);
  };

  return (
    <Section>

      {/* TOOLBAR */}
      <SettingsToolbar />

      <div className="settings-container">

        {/* MENU */}
        <div className="settings-menu-wrapper"
          style={{
            borderRightColor: theme.backgroundColorFaded,
          }}>
          <SettingsMenu
            onItemClick={handleItemClick}
          />
        </div>

        <div className="settings-content">

          {/* SETTING TITLE */}
          <SettingTitle
            title={selectedSetting && selectedSetting.title}
          />

          {/* PROFILE PHOTO */}
          {selectedSetting && selectedSetting.key === SettingType.PHOTO && (
            <SettingsPhoto />
          )}

          {/*
          <AppSettings />
*/}
        </div>

      </div>

    </Section>
  );
};

export default Settings;
