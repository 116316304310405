import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Translation
import { useTranslation } from 'react-i18next';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../../firebaseConfig';

// Utilities
import { ItemType } from '../../../utilities/Enums';
import { generateKey } from '../../../utilities/Keys';

// Styles
import './FolderAdd.css';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../components/modal/buttons/ModalButtons';
import ModalInput from '../../../components/modal/input/ModalInput';
import ModalLabel from '../../../components/modal/label/ModalLabel';
import ModalSection from '../../../components/modal/section/ModalSection';
import ModalTextarea from '../../../components/modal/textarea/ModalTextarea';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * FolderAdd Component
 * 
 * This component allows the user to add a folder.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const FolderAdd = ({ isOpen, setOpen, folderKey }) => {

    // Global
    const {
        hideProgress,
        profile,
        selectedRoom,
        showProgress
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    // Translation
    const { t } = useTranslation('Folder');

    /**
     * Handles adding a folder.
     */
    const handleAdd = async () => {

        if (!title) {
            alert(t('Folder:new_folder_title_required'));
            return;
        }

        showProgress(t('Folder:new_folder_progress_creating'));

        setOpen(false);

        const profileKey = profile.key;

        const newFolderKey = generateKey();  // Generate key for the new folder

        // Current timestamp
        const now = Timestamp.now();

        // Build the folder object
        const folderData = {
            key: newFolderKey,
            profileKey: profileKey,
            description: description,
            type: ItemType.FOLDER,
            title: title,
            roomKey: selectedRoom ? selectedRoom.key : null,
            folderKey: folderKey || null,
            trashed: false,
            shared: false,
            dateCreated: now,
            dateModified: now,
        };

        // Add the folder to the database first
        await dataManager.add(
            collections.items,
            profileKey,
            newFolderKey,
            folderData
        );

        // Reset the title field
        setTitle('');

        // Reset the description field
        setDescription('');

        // Hide the progress panel
        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title={t('Folder:new_folder_title')}
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={isMobile ? "100%" : "300px"}
                height="320px">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel
                        text={t('Folder:new_folder_label_title')}
                    />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel
                        text={t('Folder:new_folder_label_description')}
                    />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label={t('Folder:new_folder_button_continue')}
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default FolderAdd;
