import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ProfileSearch.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Components
import Modal from '../modal/Modal';
import ProfileItem from '../profileitem/ProfileItem';

// Managers
import ProfileManager from '../../managers/ProfileManager';

const profileManager = new ProfileManager();

// Styled Components
const InlineStyles = useStyle`
  *::placeholder {
    color: ${(props) => props.placeholderColor};
    opacity: 0.5; /* Adjusted opacity for better visibility */
  }
`;

const ProfileSearch = ({ isOpen, setOpen, onSelect }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
  } = useContext(Global);

  // Local State
  const [searchTerm, setSearchTerm] = useState('');
  const [profiles, setProfiles] = useState([]);

  // References
  const inputRef = useRef(null);

  /**
   * Initializes the component when it opens.
   */
  useEffect(() => {

    if (!isOpen) {
      setSearchTerm('');
      return;
    }

    // Focus the input
    if (inputRef.current) {
      inputRef.current.focus();
    }

  }, [isOpen]);

  /**
   * Searches for profiles based on the search term.
   */
  useEffect(() => {
    async function search() {
      // Don't search if the term is too short
      if (searchTerm.length < 3) return;

      try {
        const results = await profileManager.search(searchTerm.toLowerCase());
        setProfiles(results); 
      } catch (error) {
        console.error('Error during search:', error);
        setProfiles([]);
      }
    }

    search(); 
  }, [searchTerm]);

  /**
   * Handles clicking on a profile in the search result.
   */
  const handleProfileClick = (selectedProfile) => {
    onSelect(selectedProfile);
    setOpen(false);
    setSearchTerm('');
  };

  return (
    <>
      <InlineStyles placeholderColor={theme.foregroundColorFaded} />

      <Modal
        title={`Search People`}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        width={isMobile ? "100%" : "400px"}
        height="500px">

        <div className="profile-search-container">

          {/* SEARCH INPUT */}
          <input
            type="text"
            ref={inputRef}
            className="profile-search-input"
            placeholder="Search term..."
            style={{
              backgroundColor: theme.backgroundColorFaded,
              color: theme.foregroundColor,
              fontFamily: profile.fontFamily,
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* DIVIDER */}
        <div className="profile-search-divider"
          style={{
            backgroundColor: theme.backgroundColorFaded,
          }}></div>

        {/* SEARCH RESULTS */}
        <div className="profile-search-results-container">

          {profiles.map((profile) => (

            <ProfileItem
              key={profile.key}
              item={profile}
              onClick={() => handleProfileClick(profile)}
            />

          ))}

        </div>

      </Modal>
    </>
  );
};

export default ProfileSearch;
