import React from 'react';

// Utilities
import { hexToRgba } from '../../../../utilities/Colors';
import { ThemeMode } from '../../../../utilities/Enums';
import { truncateText } from '../../../../utilities/Strings';

// Styles
import './PhotoFeedItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

/**
 * PhotoFeedItem
 * 
 * A grid item for displaying files in a feed.
 * 
 * @param {Object} item The file item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} PhotoFeedItem component.
 */
const PhotoFeedItem = ({ item }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    return (

        <div className="photo-feed-item-container"
            title={item.description}>

            <div className="photo-feed-item-image-wrapper">
                <img
                    src={item.url}
                    alt={item.title}
                    className="photo-feed-item-image"
                />
            </div>

            <div className="photo-feed-item-footer"
                style={{
                    backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.5)
                }}>

                <div className="photo-feed-item-title"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {item.profile.firstName} {item.profile.lastName}
                </div>

                {item.description &&
                    <div className="photo-feed-item-caption"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {truncateText(item.description, 165)}
                    </div>
                }

            </div>
            
        </div>

    );
};

export default PhotoFeedItem;
