import React, { useState, useEffect } from 'react';

// Styles
import './EventDates.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import EventDate from './EventDate';
import EventTime from './EventTime';

const EventDates = ({
  defaultStart,
  defaultEnd,
  onStartDateChange,
  onStartTimeChange,
  onEndDateChange,
  onEndTimeChange,
}) => {
  const { theme } = useTheme();

  // State variables for start and end dates
  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultEnd);

  // Use loading state to ensure both defaultStart and defaultEnd are available
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (defaultStart && defaultEnd) {
      const initialDuration = defaultEnd - defaultStart;
      setStartDate(defaultStart);
      setEndDate(new Date(defaultStart.getTime() + initialDuration));
      setIsLoaded(true);
    }
  }, [defaultStart, defaultEnd]);

  // Handle start date change
  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);
    onStartDateChange(newStartDate);

    // Calculate the new end date based on the duration
    const newEndDate = new Date(newStartDate.getTime() + (endDate - startDate));
    setEndDate(newEndDate);
    onEndDateChange(newEndDate);
  };

  // Handle start time change
  const handleStartTimeChange = (newStartTime) => {
    const newStartDateTime = new Date(startDate);
    newStartDateTime.setHours(newStartTime.getHours());
    newStartDateTime.setMinutes(newStartTime.getMinutes());

    setStartDate(newStartDateTime);
    onStartTimeChange(newStartDateTime);

    // Calculate the new end time based on the duration
    const newEndDateTime = new Date(newStartDateTime.getTime() + (endDate - startDate));
    setEndDate(newEndDateTime);
    onEndTimeChange(newEndDateTime);
  };

  if (!isLoaded) {
    // You can display a loading indicator or return null
    return <div>Loading...</div>;
  }

  return (
    <div className="event-dates-container">
      <div className="event-dates-row">
        {/* START DATE */}
        <EventDate
          placeholder="Date"
          defaultValue={startDate}
          onUpdate={handleStartDateChange}
        />
        {/* START TIME */}
        <EventTime
          placeholder="Time"
          defaultValue={startDate}
          onUpdate={handleStartTimeChange}
        />
        <div className="event-dates-to" style={{ color: theme.foregroundColor }}>
          to
        </div>
      </div>
      <div className="event-dates-row">
        {/* END DATE */}
        <EventDate
          placeholder="Date"
          defaultValue={endDate}
          onUpdate={onEndDateChange} // This will reflect the new end date in the UI
        />
        {/* END TIME */}
        <EventTime
          placeholder="Time"
          defaultValue={endDate}
          onUpdate={onEndTimeChange} // This will reflect the new end time in the UI
        />
      </div>
    </div>
  );
};

export default EventDates;
