import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../../../Global';

// Styles
import './EventButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import CalendarIcon from '../../../../svg/CalendarIcon';

// Components
import EventForm from '../../../../sections/calendar/event/EventForm';

const EventButton = ({ onAddEvent }) => {
  
  // Theme
  const { theme } = useTheme();

  // Global
  const {
    selectedTime,
    setEventVisible,
    setSelectedTime,
  } = useContext(Global);

  useEffect(() => {
    setSelectedTime(new Date());
  }, [setSelectedTime]);

  /**
   * Method to handle an event save.
   * 
   * @param {object}  - Change event
   */
  const handleEventSave = async (newEvent) => {
    onAddEvent(newEvent);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="event-button-container"
        style={{
          backgroundColor: theme.backgroundColorFaded,
        }}
        onClick={() => setEventVisible(true)}>

        {/* ICON */}
        <CalendarIcon
          color={theme.foregroundColorFaded}
          width="15"
          height="15"
        />

        {/* EVENT FORM */}
        <EventForm
          date={selectedTime}
          onSave={handleEventSave}
        />

      </div>
    </>
  );
};

export default EventButton;
