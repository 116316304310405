import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CalendarToolbar.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CalendarIcon from '../../../svg/CalendarIcon';

// Components
import CalendarNav from '../nav/CalendarNav';
import CalendarTitle from '../title/CalendarTitle';
import DatePicker from '../../../components/datepicker/DatePicker';
import MonthPicker from '../../../components/monthpicker/MonthPicker';
import WeekPicker from '../../../components/weekpicker/WeekPicker';

const CalendarToolbar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        activeDate,
        selectedCalendarType,
        setActiveDate,
    } = useContext(Global);

    // Local State Variables
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [monthPickerOpen, setMonthPickerOpen] = useState(false);
    const [weekPickerOpen, setWeekPickerOpen] = useState(false);

    const handleSelect = (date) => {
        if (!date) return;

        setActiveDate(date);
        setDatePickerOpen(false);
        setWeekPickerOpen(false);
    };

    const handleClick = () => {
        switch (selectedCalendarType) {
            case 'DAY':
            case 'COLLECTION':
                setDatePickerOpen(true);
                break;
            case 'WEEK':
                setWeekPickerOpen(true);
                break;
            case 'MONTH':
                setMonthPickerOpen(true);
                break;
            default:
                break;
        }
    };

    return (
        <div className="calendar-toolbar-container">

            {/* TOP */}
            <div className="calendar-toolbar-top">

                {selectedCalendarType === 'SCHEDULE' ? (
                    <>
                        {/* LEFT */}
                        <div className="calendar-toolbar-left">
                            <div className="calendar-toolbar-schedule"
                                style={{ color: theme.foregroundColor }}>
                                All Events
                            </div>
                        </div>
                    </>
                ) : selectedCalendarType === 'NOTELINE' ? (
                    <>
                        {/* LEFT */}
                        <div className="calendar-toolbar-left">
                            <div className="calendar-toolbar-storyline"
                                style={{ color: theme.foregroundColor }}>
                                Storyline
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {/* WRAPPER */}
                        <div className="calendar-toolbar-wrapper">

                            {/* NAV */}
                            <div className="calendar-toolbar-nav">
                                <CalendarNav />
                            </div>

                            {/* ICON */}
                            <div
                                className="calendar-toolbar-icon"
                                onClick={handleClick}>
                                <CalendarIcon
                                    color={theme.foregroundColorFaded}
                                    width="20"
                                    height="20"
                                />
                            </div>

                            {/* TITLE */}
                            <div className="calendar-toolbar-title"
                                onClick={handleClick}
                                style={{ color: theme.foregroundColor }}>
                                <CalendarTitle />
                            </div>

                            {/* PICKERS */}
                            <div className="calendar-toolbar-pickers">

                                {/* DATE PICKER POPUP */}
                                <DatePicker
                                    isOpen={datePickerOpen}
                                    onClose={() => setDatePickerOpen(false)}
                                    selectedDate={activeDate}
                                    onDateSelect={handleSelect}
                                />

                                {/* WEEK PICKER POPUP */}
                                {selectedCalendarType === 'WEEK' &&
                                    <WeekPicker
                                        isOpen={weekPickerOpen}
                                        onClose={() => setWeekPickerOpen(false)}
                                        selectedDate={activeDate}
                                        onDateSelect={handleSelect}
                                    />
                                }

                                {/* MONTH PICKER POPUP */}
                                {selectedCalendarType === 'MONTH' &&
                                    <MonthPicker
                                        isOpen={monthPickerOpen}
                                        onClose={() => setMonthPickerOpen(false)}
                                        onMonthSelect={handleSelect}
                                        initialYear={activeDate.getFullYear()}
                                    />
                                }

                            </div>

                        </div>
                    </>
                )}

            </div>
        </div >
    );
};

export default CalendarToolbar;
