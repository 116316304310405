import React from 'react';

// Utilities
import { hexToRgba } from '../../../../../common/utilities/Colors';
import { formatCount } from '../../../../../common/utilities/Formatters';

// Styles
import './ItemButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

/**
 * ItemButton
 * 
 * @returns {JSX.Element} ItemButtons component.
 */
const ItemButton = ({ icon: IconComponent, size = 36, count = 0, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="item-button-container">

            <div className="item-button-circle"
                style={{
                    backgroundColor: hexToRgba(theme.backgroundColorFaded, 0.5)
                }}>

                <IconComponent
                    color={theme.foregroundColor}
                    width={size}
                    height={size}
                />

            </div>

            <div className="item-button-count"
                style={{
                    color: theme.foregroundColor
                }}>
                {formatCount(count)}
            </div>

        </div>

    );
};

export default ItemButton;
