import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { SectionType, ThemeMode } from '../common/utilities/Enums';
import { loadFont } from '../common/utilities/Fonts';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Mobile.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import AppSettings from '../common/appsettings/AppSettings';
//import Profile from '../common/members/profile/Profile';
import Members from '../common/members/Members';
import Bookmarks from '../common/bookmarks/Bookmarks';
import Calendar from '../common/sections/calendar/Calendar';
import Chat from '../common/channel/chat/Chat';
import Folder from '../common/sections/folders/Folder';
import Form from '../common/form/Form';
import Menu from '../common/menu/Menu';
import Messages from '../common/sections/messages/Messages';
import Notifications from '../common/sections/notifications/Notifications';
import Objects from '../common/collection/Objects';
import MobileHeader from './MobileHeader';
import VideoChat from '../common/channel/videochat/VideoChat';

// Dynamic Styles
import { MobileStyles } from './MobileStyles';

// Images
const gradient = 'https://appdeck.ai/wallpapers/gradient.jpg';
const gradientlight = 'https://appdeck.ai/wallpapers/gradientlight.png';

const Mobile = () => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    currentSection,
    currentUser,
    menuVisible,
    profile,
    selectedMember,
    selectedObject,
    setMenuVisible,
  } = useContext(Global);

  const closeMenu = (e) => {
    e.stopPropagation();
    setMenuVisible(false);
  };

  const renderContent = () => {
    if (selectedObject) return <Form />;
    if (currentSection === SectionType.CALENDAR) return <Calendar />;
    if (currentSection === SectionType.CHAT) return <Chat />;
    if (currentSection === SectionType.FOLDERS) return <Folder />;
    if (currentSection === SectionType.ROOM) return <Messages />;
    if (currentSection === SectionType.INBOX) return <Notifications />;
    if (currentSection === SectionType.SETTINGS) return <AppSettings />;
    if (currentSection === SectionType.VIDEOCHAT) return <VideoChat />;

    {/**
    if (currentSection === SectionType.MEMBERS) {
      if (selectedMember) {
        return <Profile />
      } else {
        return <Members />;
      }
    }
       */}

    return <Objects />;
  };

  useEffect(() => {
    if (!profile) return;
    loadFont(profile.fontFamily || defaultFont);
  }, [profile]);

  return (
    <>
      <MobileStyles
        fontFamily={profile.fontFamily || defaultFont}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="mobile-container"
        style={{
          backgroundColor: theme.backgroundColor,
          backgroundImage: currentThemeMode === ThemeMode.DARK ? `url(${profile.wallpaperDark || gradient})` : `url(${profile.wallpaperLight || gradientlight})`,
          backgroundSize: 'cover', // Ensures the image covers the entire screen
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
          height: '100vh', // Ensures it takes up the full viewport height
          width: '100vw', // Ensures it takes up the full viewport width
        }}>
        {menuVisible && (
          <div
            className="mobile-menu-mask"
            onClick={closeMenu}
            style={{
              backgroundImage: profile
                ? currentThemeMode === ThemeMode.DARK
                  ? `url(${profile.wallpaperDark || 'none'})`
                  : `url(${profile.wallpaperLight || 'none'})`
                : 'none', // Fallback if profile is null
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed',
              opacity: 0.8,
            }}
          ></div>
        )}
        <div className={isMobile ? "mobile-center" : "mobile-center-mobile"}
        >
          <div className={`mobile-menu ${menuVisible ? 'visible' : ''}`}>
            <Menu />
          </div>
          <div className="mobile-content">
            <MobileHeader />
            <div className="mobile-summaries">
              {renderContent()}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Mobile;
