import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../../Global';

// Utilities
import { getStringPathFromKeys } from '../../../../utilities/Folders';

// Styles
import './FolderGridItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import FolderFilledIcon from '../../../../svg/FolderFilledIcon';

// Components
import GridItem from '../../../../components/grid/griditem/GridItem';
import GridItemField from '../../../../components/grid/griditem/field/GridItemField';
import GridItemTitle from '../../../../components/grid/griditem/title/GridItemTitle';

// Managers
import FolderManager from '../../../../managers/FolderManager';

const folderManager = new FolderManager();

/**
 * FolderGridItem
 * 
 * A grid item for displaying folders.
 * 
 * @param {Object} item The folder item.
 * @param {Array} pathKeys The path keys.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * @param {Function} onDropItem The drop item event handler.
 * 
 * @returns {JSX.Element} FolderGridItem component.
 */
const FolderGridItem = ({ item, pathKeys, selected = false, onClick, onDoubleClick, onDragStart, onDropItem }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
        currentUser,
        hideProgress,
        profileFolders,
        profileItems,
        selectedFolder,
        selectedRoom,
        showProgress,
    } = useContext(Global);

    // Local State
    const [itemCount, setItemCount] = useState(0);

    /**
     * Updates the item count when the parent folder changes.
     */
    useEffect(() => {
        const folderItems = profileItems.filter((i) => i.folderKey === item.key);
        setItemCount(folderItems.length);

    }, [selectedFolder, profileItems]);

    // References
    const fileInputRef = useRef(null);

    /**
     * Cancels events when dragging files into the component.
     */
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    /**
     * Handles dropping of photos into the component.
     * 
     * @param {event} event - Event object.
     */
    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const draggedItemKey = event.dataTransfer.getData("key");
        const draggedItemType = event.dataTransfer.getData("type");
        if (draggedItemKey) {
            showProgress("Moving item...");
            await onDropItem(draggedItemKey, draggedItemType, item.key); // Call handler with item, type and target folder keys
            hideProgress();
        } else {
            console.error("No fileKey found in dataTransfer");
        }
    };

    /**
     * Handles adding of files to a folder via drag/drop.
     * 
     * @param {event} event - Event object.
     */
    const handleFolderDrop = async (event) => {
        showProgress("Adding files...");
        const folderPath = `profiles/${profile.key}/files/${getStringPathFromKeys(profileFolders, pathKeys)}/${item.title}`;
        await folderManager.handleFileDrop(event, folderPath, selectedRoom, item, profile, currentUser);
        fileInputRef.current.value = "";
        hideProgress();
    };

    return (

        <GridItem
            selected={selected}
            shared={item.shared}
            trashed={item.trashed}
            onClick={(event) => {
                if (onClick) onClick(event, item); 
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(event) => {
                if (onDragStart)  onDragStart(event, item);
            }}>

            <div
                className="folder-grid-item-container"
                title={item.title}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>

                {/* ICON */}
                <div className="folder-grid-item-icon-container"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                    }}>
                    <FolderFilledIcon
                        color={theme.highlightBackgroundColor}
                        width="90"
                        height="90"
                    />
                </div>

                {/* TITLE */}
                <GridItemTitle
                    title={item.title}
                    selected={selected}
                />

                {/* SUBTITLE */}
                <GridItemField
                    value="Collection"
                    selected={selected}
                />

                {/* COUNT */}
                <GridItemField
                    value={`${itemCount} items`}
                    selected={selected}
                />

                {/* FILE INPUT */}
                <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={(event) => handleFolderDrop(event)}
                    style={{ display: 'none' }}
                    aria-label="File Upload"
                    multiple
                />

            </div>

        </GridItem>
    );
};

export default FolderGridItem;
