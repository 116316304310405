import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

import { ThemeMode } from '../../../common/utilities/Enums';

// Styles
import './Room.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import ChatPostForm from '../../../common/channel/chat/chatpostform/ChatPostForm';
import ChatPosts from '../../../common/channel/chat/chatposts/ChatPosts';
import ItemPanel from '../../../common/components/itempanel/ItemPanel';
import RoomToolbar from './toolbar/RoomToolbar';
import Section from '../../components/section/Section';

// Preload Hooks
import usePreloadRoomProfiles from './hooks/PreloadRoomProfiles.js';
import usePreloadRoomChatPosts from './hooks/PreloadRoomChatPosts.js';
import usePreloadRoomItems from './hooks/PreloadRoomItems.js';

const Room = () => {

  // Theme
  const { currentThemeMode } = useTheme();

  // Global
  const {
    roomItems,
  } = useContext(Global);

  /**
   * These hooks preload the items for the current room.
   */
  usePreloadRoomProfiles();
  usePreloadRoomChatPosts();
  usePreloadRoomItems();

  return (

    <Section>

      {/* TOOLBAR */}
      <RoomToolbar />

      <div className="room-container">

        {/* LEFT */}
        <div className="room-left">

          {/* MASK */}
          <div className="room-mask"
            style={{
              backgroundColor: currentThemeMode === ThemeMode.DARK ? 'black' : 'white',
            }}></div>

          <div className="room-content">

            <div className="room-chat-wrapper">

              <div className="room-chat-posts">
                <ChatPosts />
              </div>

              <div className="room-chat-form">
                <ChatPostForm />
              </div>

            </div>

          </div>

        </div>

        {/* RIGHT */}
        <div className="room-right">

          {/* ITEM PANEL */}
          <ItemPanel
            items={roomItems}
          />

        </div>

      </div>

    </Section>

  );
};

export default Room;
