import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Styles
import './CalendarCollectionHourBlock.css';

// Components
import Segment from './Segment';

// Managers
import EventManager from '../../../../managers/EventManager';

const eventManager = new EventManager();

/**
 * CalendarCollectionHourBlock Component
 * 
 * Displays an hour block divided into segments based on `segmentLength`.
 * Supports drag-and-drop functionality for creating or moving events.
 * 
 * @param {object} props - Component props.
 * @param {object} object - The associated data object for the hour block.
 * @param {integer} index - The index of the hour in the day.
 * @param {boolean} useDrop - Enables drop functionality.
 * @param {number} segmentLength - Number of minutes per segment (default: 15).
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarCollectionHourBlock = ({ object, index, segmentLength = 15, setDisplayEvents }) => {
    const {
        activeDate,
        profileModels,
        profile,
        setEventCollection,
        setEventObject,
        setEventVisible,
        setSelectedEvent,
        setSelectedTime,
    } = useContext(Global);

    const [hoveredSegment, setHoveredSegment] = useState(null);

    const handleDrop = async (item, segmentIndex) => {
        const event = item.event;
        const eventObjects = item.event.eventObjects || [];
        const sourceObject = item.sourceObject; // Previous source object
        const sourceKey = sourceObject.key;
    
        // Remove the previous source object
        const updatedEventObjects = eventObjects.filter((obj) => obj.key !== sourceKey);
    
        // Add the new target object
        updatedEventObjects.push(object);
    
        const selected = new Date(activeDate.getTime());
        selected.setHours(index);
        selected.setMinutes(segmentIndex * segmentLength);
        const duration = event.endDate.toDate() - event.startDate.toDate();
        const newEnd = new Date(selected.getTime() + duration);
    
        const data = {
            ...event,
            startDate: Timestamp.fromDate(selected),
            endDate: Timestamp.fromDate(newEnd),
            eventObjects: updatedEventObjects,
        };
    
        try {
            // Update the event in the database
            await eventManager.update(profile.key, event.key, data);
        } catch (error) {
            console.error('Error updating event:', error);
        }
    };    

    const handleSegmentClick = (segmentIndex) => {
        // Clear the currently selected event
        setSelectedEvent(null);

        // Set the selected time
        const selected = new Date(activeDate.getTime());
        selected.setHours(index);
        selected.setMinutes(segmentIndex * segmentLength);
        selected.setSeconds(0);
        setSelectedTime(selected);

        // Set the event collection and object
        const collection = profileModels.find((c) => c.key === object.modelKey);
        setEventCollection(collection);
        setEventObject(object);

        // Display the event form
        setEventVisible(true);
    };

    const segmentCount = 60 / segmentLength;
    const segmentHeight = 100 / segmentCount;

    return (
        <div
            className="calendar-collection-hour-block-container">
            {Array.from({ length: segmentCount }).map((_, segmentIndex) => (
                <Segment
                    key={segmentIndex}
                    segmentIndex={segmentIndex}
                    segmentHeight={segmentHeight}
                    onDrop={handleDrop}
                    onMouseEnter={setHoveredSegment}
                    onMouseLeave={() => setHoveredSegment(null)}
                    isHovered={hoveredSegment === segmentIndex}
                    onSegmentClick={handleSegmentClick}
                />
            ))}
        </div>
    );
};

export default CalendarCollectionHourBlock;
