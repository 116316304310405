import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Trash.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Grid from '../../../common/components/grid/Grid';
import Item from '../../../common/sections/folders/item/Item';
import Section from '../../components/section/Section';
import TrashToolbar from './toolbar/TrashToolbar';

// Managers
import DataManager from '../../../common/managers/DataManager';
const dataManager = new DataManager();

const Trash = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
    trashedItems,
  } = useContext(Global);

  // Local State
  const [selectedItems, setSelectedItems] = useState([]);

  /**
   * Handles single click on an item.
   */
  const handleItemClick = (event, item) => {
    event.stopPropagation();

    if (event.ctrlKey || event.metaKey || event.shiftKey) {
      // If the Control (Windows) or Command (macOS) key is held, toggle the item's selection
      if (selectedItems.some((selected) => selected.key === item.key)) {
        setSelectedItems(selectedItems.filter((selected) => selected.key !== item.key));
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    } else {
      // If no modifier key is held, select only the clicked item and clear other selections
      if (!selectedItems.some((selected) => selected.key === item.key)) {
        setSelectedItems([item]);
      }
      // Do nothing if the item is already in the array
    }
  };

  /**
   * Deselects all items when the container is clicked.
   */
  const handleContainerClick = () => {
    setSelectedItems([]);
  };

  /**
   * Empties the trash for this profile.
   */
  const handleEmptyClicked = async () => {

    // Delete the original event
    const params = [
      { field: 'profileKey', operator: '==', value: profile.key },
      { field: 'trashed', operator: '==', value: true },
    ];

    await dataManager.delete(collections.items, profile.key, null, params);

  };

  return (

    <Section>

      <div className="trash-container">

        {/* TOOLBAR */}
        <TrashToolbar 
          onEmptyClicked={handleEmptyClicked}
        />

        {/* CONTENT */}
        <div className={isMobile ? "trash-grid-container-mobile" : "trash-grid-container"}
          onClick={handleContainerClick}>

          <Grid items={trashedItems}
            renderItem={(item) => (

              <Item
                key={item.key}
                item={item}
                selected={selectedItems.some((selected) => selected.key === item.key)}
                onClick={(event, item) => handleItemClick(event, item)}
              />

            )}
            loading={false}
            minCols={2}
            colWidth={isMobile ? 100 : 200}
          />

        </div>

      </div>

    </Section>

  );
};

export default Trash;
