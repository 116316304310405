import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import DataManager from '../../../managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load files for a specific app.
 */
const usePreloadProfileChatPosts = () => {

  // Global
  const {
    profile,
    selectedRoom,
    setRoomChatPosts
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected app or app key
    if (!selectedRoom || !selectedRoom.key) {
      setRoomChatPosts([]);
      return;
    }

    const params = [
      { field: "roomKey", operator: "==", value: selectedRoom.key },
    ];

    // Function to load models
    const loadChatPosts = (selectedRoomKey) => {
      const handleUpdate = (items) => {
        setRoomChatPosts(items);
      };

      const unsubscribe = dataManager.listAndSubscribe(
        collections.chatposts,
        profile.key,
        handleUpdate,
        params,
        [],
        true // global
      );

      return unsubscribe;
    };

    // Subscribe to models
    const unsubscribeChatPosts = loadChatPosts(selectedRoom.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeChatPosts) {
        unsubscribeChatPosts();
      }
    };
  }, [selectedRoom, setRoomChatPosts]);

};

export default usePreloadProfileChatPosts;
