import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ChatPost.css';

// Components
import Document from '../../components/document/Document';
import Event from '../../components/event/Event';
import Gallery from '../../components/gallery/Gallery';
import ObjectTile from '../../../collection/tiles/tile/ObjectTile';
import VideoGallery from '../../components/videogallery/VideoGallery';
import ProfileDot from '../../../../common/components/profiledot/ProfileDot';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ChatPost = ({ post, timestamp }) => {
    const { theme } = useTheme();
    const {
        profileModels,
        resetSelections,
        profile,
        roomProfiles,
        setSelectedModel,
        setSelectedObject
    } = useContext(Global);

    // Local State
    const [postProfile, setPostProfile] = useState(null);

    /**
     * Gathers the profile info for the post.
     */
    useEffect(() => {
        const found = roomProfiles.find((roomProfile) => roomProfile.key === post.profileKey);
        setPostProfile(found);
    }, [post]);

    const handleObjectClick = async (object) => {
        resetSelections();
        const appCollection = profileModels.find(appCollection => appCollection.key === object.modelKey);
        setSelectedModel(appCollection);
        setSelectedObject(object);
    };

    const getUrlsFromContent = (content) => {
        if (!content) {
            return [];
        }

        return Array.from(content).map((item) => ({
            url: getDisplayUrl(item),  // use the updated utility function here
        }));
    };

    /**
     * Gets a URL suitable for display, regardless if it's a local file (blob URL) or a remote URL.
     * If the URL already starts with 'blob:', it's returned as is.
     * 
     * @param {string} url - The URL string (either a local blob URL or remote URL)
     * 
     * @returns {string} - The displayable URL
     */
    const getDisplayUrl = (url) => {
        // If it's a blob URL, return it as is
        if (url.startsWith('blob:')) {
            return url;
        }
        // For remote URLs, just return the original URL
        return url;
    };

    if (!postProfile) return null;

    return (
        <>
            <div
                key={post.key}
                className="chat-post-wrapper">
                <div className="chat-post-left">
                        <ProfileDot
                            item={postProfile}
                            size={40}
                        />
                </div>
                <div className="chat-post-right">
                    <div className="chat-post-header">
                        <div className="chat-post-user"
                            style={{
                                color: theme.foregroundColorFaded,
                            }}>
                            {`${postProfile.firstName} ${postProfile.lastName}`}
                        </div>
                        <div className="chat-post-timestamp"
                            style={{
                                color: theme.foregroundColorFaded,
                            }}>
                            • {timestamp}
                        </div>
                    </div>
                    <div className="chat-post-content"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: profile.fontFamily
                        }}>
                        {post.text}
                    </div>
                </div>
            </div>

            {/* PHOTOS */}
            {post.photoUrls && post.photoUrls.length > 0 &&
                <div className="chat-post-gallery-wrapper">
                    <Gallery
                        key={`${post.key}-gallery`}
                        content={getUrlsFromContent(post.photoUrls)} />
                </div>
            }

            {/* VIDEOS */}
            {post.videoUrls && post.videoUrls.length > 0 &&
                <div className="chat-post-gallery-wrapper">
                    <VideoGallery
                        key={`${post.key}-video-gallery`}
                        content={getUrlsFromContent(post.videoUrls)}
                    />
                </div>
            }

            {/* DOCUMENTS */}
            {post.documents && post.documents.length > 0 &&
                <div className="chat-post-document-wrapper">
                    {post.documents.map((document, index) => (
                        <Document
                            key={`document-${index}`}
                            content={document}
                        />
                    ))}
                </div>
            }

            {/* OBJECTS */}
            {post.objects && post.objects.length > 0 &&
                <div className="chat-post-object-wrapper">
                    {post.objects.map((object, index) => (
                        <div key={`object-${index}`} className="chat-post-object"
                            style={{
                                backgroundColor: theme.backgroundColorFaded
                            }}
                            onClick={() => handleObjectClick(object)}>
                            <ObjectTile 
                                object={object} 
                            />
                        </div>
                    ))}
                </div>
            }

            {/* EVENTS */}
            {post.events && post.events.length > 0 &&
                <div className="chat-post-events-wrapper">
                    {post.events.map((evt, index) => (
                        <Event
                            key={`event-${index}`}
                            event={evt}
                        />
                    ))}
                </div>
            }
        </>
    );
};

export default ChatPost;
