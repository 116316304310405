import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ProfileButtons.css';

// Images
import AddFriendIcon from '../../../../common/svg/AddFriendIcon';

// Components
import ProfileButton from '../button/ProfileButton';

const ProfileButtons = ({ item, onEditClick, onShareClick, onAddFriendClick }) => {

    // Global
    const {
        profile
    } = useContext(Global);

    return (
        <div className="profile-buttons-container">

            {profile.key === item.key ? (
                <>
                    {/* EDIT PROFILE */}
                    <ProfileButton
                        title="Edit Profile"
                        onClick={onEditClick}
                    />

                    {/* SHARE PROFILE */}
                    <ProfileButton
                        title="Share Profile"
                        onClick={onShareClick}
                    />

                    {/* ADD FRIENDS */}
                    <ProfileButton
                        icon={AddFriendIcon}
                        onClick={onAddFriendClick}
                    />
                </>
            ) : (
                <>
                    {/* FOLLOW */}
                    <ProfileButton
                        title="Follow"
                        onClick={onEditClick}
                        highlighted={true}
                        width="100px"
                    />

                    {/* SHARE PROFILE */}
                    <ProfileButton
                        title="Share Profile"
                        onClick={onShareClick}
                    />
                </>
            )}


        </div>
    );
};

export default ProfileButtons;

