import React from 'react';

const HomeIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M289.23-310.77q-13.08 0-21.92-8.84-8.85-8.85-8.85-21.93v-40h470l25.39 25.39V-720h40q13.07 0 21.92 8.85 8.85 8.84 8.85 21.92v501.54L701.54-310.77H289.23Zm-153.85-27.69v-470.77q0-13.08 8.85-21.92 8.85-8.85 21.92-8.85h476.93q13.07 0 21.92 8.85 8.85 8.84 8.85 21.92v316.92q0 13.08-8.85 21.93-8.85 8.84-21.92 8.84H258.46L135.38-338.46Z" />
  </svg>

);

export default HomeIcon;
