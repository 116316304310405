import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './SettingsMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import SettingsItem from '../item/SettingsItem';

const SettingsMenu = ({ onItemClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        settings,
    } = useContext(Global);

    return (
        <>
            {settings.map((option, index) => {

                return (

                    <React.Fragment key={option.key}>

                        <SettingsItem
                            key={index}
                            option={option}
                            icon={option.icon}
                            onClick={() => onItemClick(option)}
                        />
                        
                    </React.Fragment>
                );
            })}

        </>
    );
};

export default SettingsMenu;
