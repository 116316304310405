import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Translation
import { useTranslation } from 'react-i18next';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../../firebaseConfig';

// Utilities
import { ItemType } from '../../../utilities/Enums';
import { generateKey } from '../../../utilities/Keys';

// Styles
import './RoomAdd.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../components/modal/buttons/ModalButtons';
import ModalInput from '../../../components/modal/input/ModalInput';
import ModalLabel from '../../../components/modal/label/ModalLabel';
import ModalSection from '../../../components/modal/section/ModalSection';
import ProfileItem from '../../../components/profileitem/ProfileItem';
import ProfileSearch from '../../../components/profilesearch/ProfileSearch';

// Managers
import DataManager from '../../../managers/DataManager';
import { on } from 'process';

const dataManager = new DataManager();

/**
 * RoomAdd Component
 * 
 * This component allows the user to add a room.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const RoomAdd = ({ isOpen, setOpen, folderKey, roomKey, eventKey, delayAdd = false, onAdded }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        hideProgress,
        profile,
        selectedRoom,
        setSelectedRoom,
        showProgress
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const [selectedProfiles, setSelectedProfiles] = useState([]);

    // Translation
    const { t } = useTranslation('Room');

    /**
     * Adds the current user to the room by default when creating a new room.
     */
    useEffect(() => {
        setSelectedProfiles([profile]);
    }, [profile]);

    /**
     * Gets the array of selected profiles prior to save.
     */
    const getProfilesKeysArray = () => {
        return selectedProfiles.map((profile) => profile.key);
    };

    /**
     * Gets the array of selected profiles prior to save.
     * This data will be stored with the room record, for efficiency.
     */
    const getProfilesArray = () => {
        return selectedProfiles.map((profile) => ({
            key: profile.key,
            photo: profile.photo,
            firstName: profile.firstName,
            lastName: profile.lastName
        }));
    };

    /**
     * Handles adding a room.
     */
    const handleAdd = async () => {

        showProgress(t('Room:new_room_progress_creating'));

        const profileKey = profile.key;

        const newRoomKey = generateKey();  // Generate key for the new room

        // Current timestamp
        const now = Timestamp.now();

        // Build the room object
        const roomData = {
            key: newRoomKey,
            profileKey: profileKey,
            title: title || "New Room",
            roomKey: selectedRoom ? selectedRoom.key : null,
            folderKey: folderKey || null,
            roomKey: roomKey || null,
            eventKey: eventKey || null,
            type: ItemType.ROOM,
            profileKeys: getProfilesKeysArray(), // For deeper profile info
            profiles: getProfilesArray(), // For detecting a profile's rooms
            trashed: false,
            shared: false,
            dateCreated: now,
            dateModified: now,
        };

        // Add the room to the database first
        if (!delayAdd) {
            await dataManager.add(
                collections.items,
                profileKey,
                newRoomKey,
                roomData
            );

            setSelectedRoom(roomData);
        } else {
            onAdded(roomData);
        }

        // Reset the title field
        setTitle('');

        // Close the dialog
        setOpen(false);

        // Hide the progress panel
        hideProgress();
    };

    /**
     * Adds a selected profile to the list of participants.
     * 
     * @param {Object} profile - The selected profile.
     */
    const handleProfileSelect = (profile) => {
        setSelectedProfiles([...selectedProfiles, profile]);
    };

    const handleClose = () => {

        setTitle('');
        setSelectedProfiles([]);

        setOpen(false);
    }

    return (
        <>
            {/* MODAL */}
            <Modal title={t('Room:new_room_title')}
                isOpen={isOpen}
                onClose={handleClose}
                width={isMobile ? "100%" : "400px"}
                height={isMobile ? "100%" : "90%"}>

                {/* CONTAINER */}
                <div className="room-add-container">

                    {/* TOP */}
                    <div className="room-add-top">

                        {/* PARTICIPANTS */}
                        <ModalSection>

                            <ModalLabel
                                text={t('Room:new_room_label_participants')}
                            />

                            <div className="room-add-participants">

                                {selectedProfiles.map((item) => (

                                    <ProfileItem
                                        key={item.key}
                                        item={item}
                                    />

                                ))}

                            </div>

                        </ModalSection>

                    </div>

                    {/* BOTTOM */}
                    <div className="room-add-bottom">

                        <div className="room-add-participants-buttons">

                            <div className="room-add-participants-button"
                                style={{
                                    backgroundColor: theme.highlightBackgroundColor,
                                }}
                                onClick={() => setSearchOpen(true)}>
                                Add Participant
                            </div>

                        </div>

                        {/* TITLE */}
                        <ModalSection>

                            <ModalLabel
                                text={t('Room:new_room_label_title')}
                            />

                            <ModalInput
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                maxLength="100"
                                autoFocus={true}
                            />

                        </ModalSection>

                    </div>

                    {/* BUTTONS */}
                    <ModalButtons>

                        {/* ADD BUTTON */}
                        <ModalButton
                            onClick={handleAdd}
                            label={t('Room:new_room_button_continue')}
                        />

                    </ModalButtons>

                </div>

            </Modal>

            {/* PROFILE SEARCH */}
            <ProfileSearch
                isOpen={searchOpen}
                setOpen={setSearchOpen}
                onSelect={handleProfileSelect}
            />
        </>
    );
};

export default RoomAdd;
