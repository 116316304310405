// Utilities
import { ItemType } from '../../common/utilities/Enums';

// Firebase
import { collections } from '../../firebaseConfig';

/**
 * Function to build the full path of folder keys for a folder.
 * 
 * @param {array} folders - The array of folders.
 * @param {object} folder - The folder object.
 * 
 * @returns {array} The array of folder keys.
 */
export const getFolderPath = (folders, folder) => {
    if (!folder) return [];
    const keyPath = [];
    let currentFolder = folder;
    while (currentFolder) {
        keyPath.unshift(currentFolder.key);
        currentFolder = folders.find(f => f.key === currentFolder.folderKey);
    }

    return keyPath;
};

/**
 * Calculates the number of shared items in a folder and its subfolders.
 * 
 * @param {array} items - The array of items.
 * @param {object|null} folder - The folder object. If null, calculates for the root.
 * 
 * @returns {number} The number of shared items.
 */
/**
 * Calculates the number of shared items in a folder and its subfolders.
 * 
 * @param {array} items - The array of items.
 * @param {object|null} folder - The folder object. If null, calculates for all items.
 * 
 * @returns {number} The number of shared items.
 */
export const getShareCount = (items, folder) => {
    // Recursive helper function to count shared items within a folder and its subfolders
    function recursiveCount(folderKey) {
        let sharedCount = 0;

        for (const item of items) {
            // Check if the item belongs to the current folder
            if (item.folderKey === folderKey) {
                // Count shared items if not a folder
                if (item.type !== ItemType.FOLDER && item.shared) {
                    sharedCount++;
                }

                // If the item is a folder, recursively count its contents
                if (item.type === ItemType.FOLDER) {
                    sharedCount += recursiveCount(item.key);
                }
            }
        }

        return sharedCount;
    }

    if (folder === null) {
        // If no folder is provided, count all shared items
        return items.reduce(
            (count, item) => count + (item.shared ? 1 : 0),
            0
        );
    } else {
        // Start the recursion with the provided folder's key
        return recursiveCount(folder.key);
    }
};

/**
 * Function to generate a string path from an array of folder keys
 * 
 * @param {array} folders - The array of folders.
 * @param {array} keyPath 
 * 
 * @returns {string} The string path.
 */
export const getStringPathFromKeys = (folders, keyArray) => {
    if (!keyArray || keyArray.length === 0) return "";

    const pathSegments = keyArray.map(key => {
        const folder = folders.find(f => f.key === key);
        return folder ? folder.key : "";
    });

    return pathSegments.filter(Boolean).join("/");
};