import React from 'react';

// Styles
import './StudioHeader.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const StudioHeader = ({ title }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="studio-header"
            style={{
                color: theme.foregroundColorFaded,
            }}>
            {title}
        </div>
    );
};

export default StudioHeader;

