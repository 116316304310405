import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { generateKey } from '../../../common/utilities/Keys';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../firebaseConfig';

// Styles
import './ConversationAddButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import PlusIcon from '../../../common/svg/PlusIcon';

// Components
import UserSelector from '../../../common/components/userselector/UserSelector';

// Managers
import DataManager from '../../../common/managers/DataManager';

const dataManager = new DataManager();

const ConversationAddButton = () => {
  const { theme } = useTheme();
  const {
    currentUser,
    profile,
    setSelectedConversation,
  } = useContext(Global);

  const [userSelectorOpen, setUserSelectorOpen] = useState(false);

  const handleAdd = async () => {
    setUserSelectorOpen(true);
  };

  /** 
   * Handles user selection completion.
   */
  const handleUserSelectComplete = async (selectedUsers) => {

    // Add the current user to the selected users
    selectedUsers.push(currentUser);

    const now = Timestamp.now();

    const key = generateKey();

    const data = {
      profileKey: profile.key,
      key: key,
      participants: selectedUsers,
      lastMessageRead: false,
      dateCreated: now
    };

    await dataManager.add(collections.conversations, profile.key, key, data);

    // Set the selected conversation
    setSelectedConversation(data);
  };

  return (
    <>
      <div className="conversation-add-button-container"
        style={{ backgroundColor: theme.highlightBackgroundColor }}
        onClick={handleAdd}>
        <PlusIcon
          color={theme.highlightForegroundColor}
          width="25"
          height="25"
        />
      </div>

      {/* USER SELECTOR */}
      <UserSelector
        selectedUsers={[]}
        isOpen={userSelectorOpen}
        includeCurrentUser={false}
        onClose={() => setUserSelectorOpen(false)}
        onSelectComplete={handleUserSelectComplete}
      />
    </>
  );
};

export default ConversationAddButton;
