import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { SectionType } from '../../common/utilities/Enums';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './MenuItems.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Images
import ArchiveIcon from '../../common/svg/ArchiveIcon';
import CalendarIcon from '../../common/svg/CalendarIcon';
import FeedIcon from '../../common/svg/FeedIcon';

// Components
import MenuHeader from './MenuHeader';
import MenuItem from './menuitem/MenuItem';
import MenuSettings from './MenuSettings';
import Settings from './settings/Settings';

const InlineStyles = useStyle`
    .menu-item:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const MenuItems = () => {
    const { theme } = useTheme();
    const {
        profile,
        currentUser,
        selectedModel,
        setCurrentSection
    } = useContext(Global);

    const [isMenuSettingsOpen, setMenuSettingsOpen] = useState(false);

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />
            <div className={isMobile ? "menuitems-container-mobile" : "menuitems-container"}
                style={{
                    backgroundColor: isMobile ? theme.backgroundColor : "transparent",
                }}>

                {isMobile &&
                    <MenuHeader />
                }

                <div className="menuitems-list"
                    style={
                        isMobile
                            ? {
                                overflowY: "scroll",
                                msOverflowStyle: "none", // IE and Edge
                                scrollbarWidth: "none"  // Firefox
                            }
                            : {}
                    }>

                    <div className="menu-items-highlighted">

                        <MenuItem
                            key="HOME"
                            title="For You"
                            icon={FeedIcon}
                            isActive={false}
                            onClick={() => setCurrentSection(SectionType.HOME)} />

                        <MenuItem
                            key="MYSTUFF"
                            title="Home"
                            icon={ArchiveIcon}
                            isActive={false}
                            onClick={() => setCurrentSection(SectionType.FOLDERS)} />

                        <MenuItem
                            key="CALENDARS"
                            title="Calendar"
                            icon={CalendarIcon}
                            isActive={false}
                            onClick={() => setCurrentSection(SectionType.CALENDAR)} />

                    </div>

                    {/* SETTINGS (CREATOR ONLY) */}
                    {currentUser && profile.userKey === currentUser.userKey &&
                        <Settings />
                    }

                    {selectedModel &&
                        <MenuSettings
                            modelKey={selectedModel.key}
                            isMenuSettingsOpen={isMenuSettingsOpen}
                            setMenuSettingsOpen={setMenuSettingsOpen}
                        />
                    }

                </div>

                {/* BUTTONS */}
                {isMobile &&
                    <div className="menu-items-buttons">

                        {/* NAV BAR 
                        {profile && currentUser &&
                            <NavBar />
                        }
                        */}

                    </div>
                }
            </div>
        </>
    );
};

export default MenuItems;
