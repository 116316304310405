import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

// Styles
import './CalendarStorylineEvent.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const CalendarStorylineEvent = ({ event, tags, years, tagHeight, shades }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const { setEventVisible, setSelectedEvent } = useContext(Global);

  // Ensure startDate and endDate are converted from Firebase Timestamp to Date
  const startDate = event.startDate.toDate();
  const endDate = event.endDate.toDate();
  const startYear = startDate.getFullYear();

  // Calculate total days between start and end dates
  const totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
  const dayWidth = 1; // 1 pixel per day

  // Calculate days since the start of the year for the left position
  const startOfYear = new Date(startYear, 0, 1); // January 1st of the start year
  const daysSinceYearStart = Math.floor((startDate - startOfYear) / (1000 * 60 * 60 * 24));

  // Filter and map only the tags that exist in the `tags` array
  const eventTagPositions = event.tags
    .filter(tag => tags.includes(tag)) // Check if tag exists in the tags array
    .map(tag => {
      const tagIndex = tags.indexOf(tag);
      return {
        tag,
        top: tagIndex * tagHeight + (tagHeight / 2),
        left: years.indexOf(startYear) * 365 + daysSinceYearStart * dayWidth,
        width: totalDays * dayWidth,
        shade: shades[tagIndex] // Get the corresponding shade for the tag
      };
    });

  const handleEventClick = (e, event) => {
    e.stopPropagation();
    setSelectedEvent(event);
    setEventVisible(true);
  };

  return (
    <>
      {eventTagPositions.map(({ tag, top, left, width, shade }) => (
        <div
          key={tag}
          className="calendar-storyline-event-container"
          style={{
            position: 'absolute',
            top: 0,
            left,
            width,
            height: top
          }}
        >
          {/* BOX */}
          <div
            className="calendar-storyline-event-box"
            style={{
              borderColor: shade, // Apply the corresponding shade for the border color
              height: top,
            }}
          >
            <div
              className="calendar-storyline-event-box-inner"
              style={{
                backgroundColor: shade, 
                opacity: 0.1 // Adjust opacity as needed
              }}></div>
          </div>

          {/* TITLE */}
          <div
            className="calendar-storyline-event-title"
            onClick={(e) => handleEventClick(e, event)}
            style={{
              color: theme.foregroundColor,
              top: top
            }}>
            {event.title}
          </div>
        </div>
      ))}
    </>
  );
};

export default CalendarStorylineEvent;
