import React, { forwardRef, useRef, useEffect } from 'react';

// Firebase
import { uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebaseConfig';

const Uploader = forwardRef(
    (
        {
            accept = "*",
            selectionMode = "multiple",
            folderPath,
            uploadReady = false,
            setUploadReady,
            onSelectComplete,
            onUploadStart,
            onUploadComplete,
        },
        ref
    ) => {
        const fileInputRef = useRef(null);
        const selectedFilesRef = useRef([]);

        // Expose the file input element to the parent component
        useEffect(() => {
            if (ref) {
                ref.current = fileInputRef.current;
            }
        }, [ref]);

        const handleFileSelect = (event) => {
            const files = event.target.files;
            if (!files || files.length === 0) return;

            selectedFilesRef.current = Array.from(files);
            const localFilePaths = selectedFilesRef.current.map((file) =>
                URL.createObjectURL(file)
            );

            if (onSelectComplete) {
                onSelectComplete(localFilePaths);
            }

            if (uploadReady) {
                handleUpload();
            }
        };

        const handleUpload = async () => {
            if (selectedFilesRef.current.length === 0) return;

            if (onUploadStart) {
                onUploadStart();
            }

            const uploadedUrls = [];

            try {
                for (const file of selectedFilesRef.current) {
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    const filePath = `${folderPath}/${Date.now()}_${file.name}`;
                    const storageRef = ref(storage, filePath);

                    await uploadBytes(storageRef, file);
                    const fileUrl = await getDownloadURL(storageRef);
                    uploadedUrls.push(fileUrl);
                }

                if (onUploadComplete) {
                    onUploadComplete(uploadedUrls);
                }

                if (setUploadReady) {
                    setUploadReady(false);
                }
            } catch (error) {
                console.error("Error uploading files:", error);
            }
        };

        useEffect(() => {
            if (uploadReady) {
                handleUpload();
            }
        }, [uploadReady]);

        return (
            <input
                ref={fileInputRef}
                type="file"
                accept={accept}
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                multiple={selectionMode === "multiple"}
                aria-label="File Upload"
            />
        );
    }
);

export default Uploader;