import React, { useContext, useState } from 'react';

// Utilities
import { ItemSize } from '../../../common/utilities/Enums';

// Global
import { Global } from '../../../Global';

// Styles
import './Home.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Item from '../../../common/sections/folders/item/Item';
import HomeToolbar from './toolbar/HomeToolbar';
import ItemButtons from './item/buttons/ItemButtons.js';
import Section from '../../components/section/Section';

// Preload Hooks
import usePreloadHomeItems from './hooks/PreloadHomeItems.js';

const Home = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile
  } = useContext(Global);

  // Local State
  const [homeItems, setHomeItems] = useState([]);

  // Hooks
  usePreloadHomeItems(profile, setHomeItems);

  const handleItemClick = (event, item) => {
    event.stopPropagation();
    // Handle item click logic here
  };

  return (

    <Section>

      {/* TOOLBAR */}
      <HomeToolbar />

      {/* SCROLLING CONTENT */}
      <div className="home-scroll-container">
        {homeItems.map((item) => (
          <div key={item.key} className="home-item">

            <div key={item.key} className="home-item-wrapper"
              style={{
                backgroundColor: theme.backgroundColorFaded
              }}>
              <Item
                key={item.key}
                item={item}
                size={ItemSize.LARGE}
              />
            </div>

            <div className="home-item-buttons">

              <ItemButtons 
                item={item}
              />

            </div>

          </div>
        ))}
      </div>

      {/*  
      <Grid items={homeItems}
            renderItem={(item) => (

              <Item
                key={item.key}
                item={item}
                size={ItemSize.SMALL}
              />

            )}
            loading={false}
            minCols={2}
            colWidth={170}
          />
      */}

    </Section>

  );
};

export default Home;
