import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import DataManager from '../../../managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load items for a specific profile.
 */
const usePreloadPublicItems = (profile, setPublicItems) => {

  // Global
  const {
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no profile
    if (!profile || !profile.key) {
      setPublicItems([]);
      return;
    }

    const load = () => {
      const handleUpdate = (items) => {
        setPublicItems(items);
      };

      const params = [
        { field: "profileKey", operator: "==", value: profile.key },
        { field: "shared", operator: "==", value: true },
        { field: "trashed", operator: "!=", value: true }
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.items,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeItems = load();

    return () => {
      if (unsubscribeItems) {
        unsubscribeItems();
      }
    };
  }, [profile, setPublicItems]);

};

export default usePreloadPublicItems;
