import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { getRelativeTime } from '../../../utilities/DateTime';
import { MemberThumbDirection } from '../../../utilities/Enums';
import { getUserDisplayValue } from '../../../utilities/Users';

// Styles
import './ConversationSummary.css';

// Theme
import { useStyle, useTheme } from '../../../../ThemeContext';

// Components
import MemberThumbs from '../../../components/memberthumbs/MemberThumbs';

// Styled Components
const InlineStyles = useStyle`
    .conversation-summary-user:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const ConversationSummary = ({ conversation, onClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const { profile } = useContext(Global);

    const [participants, setParticipants] = useState([]);
    const [lastMessage, setLastMessage] = useState("");

    useEffect(() => {
        if (conversation.participants) {
            setParticipants(conversation.participants);
        }

        // Trim the last message text to 200 characters
        const trimmedLastMessage = conversation.lastMessage
            ? conversation.lastMessage.text.slice(0, 200)
            : '';

        setLastMessage(trimmedLastMessage);
    }, [conversation]);

    function getUsernamesString() {
        return participants
            .map(user => getUserDisplayValue(profile.displayKey, user)) // Extract each username
            .filter(Boolean) // Remove any undefined or null usernames
            .join(', '); // Join with a comma and space
    }

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />
            <div className="conversation-summary-container"
                style={{
                    backgroundColor: theme.backgroundColor,
                }}
                onClick={() => onClick(conversation)}>

                <div className="conversation-summary-top">
                    <MemberThumbs
                        members={participants}
                        direction={MemberThumbDirection.LTR}
                        borderWidth={1}
                        size={30}
                        offset={20}
                    />
                </div>

                <div className="conversation-summary-center"
                    style={{
                        color: theme.foregroundColor
                    }}>

                    {/* TITLE */}
                    <div className="conversation-summary-title">
                        {getUsernamesString()}
                    </div>

                    {/* LAST MESSAGE */}
                <div className="conversation-summary-message"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {lastMessage && (
                        <div className={conversation.lastMessageRead ? "conversation-summary-last-read" : "conversation-summary-last-unread"}>
                            {lastMessage}
                        </div>
                    )}
                </div>

                    {/* ELAPSED */}
                {conversation && conversation.lastMessage && conversation.lastMessage.dateCreated &&
                    <div className="conversation-summary-elapsed"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        {getRelativeTime(conversation.lastMessage.dateCreated)}
                    </div>
                }
                </div>

            </div>
        </>
    );
};

export default ConversationSummary;
