import React, { useCallback, useContext, useEffect, useState } from 'react';

// Config
import { validSummaryTypes } from '../../../../Config';

// Global
import { Global } from '../../../../Global';

// Styles
import './TileListElements.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import TextIcon from '../../../../common/svg/TextIcon';
import CartIcon from '../../../../common/svg/CartIcon';
import CreatedIcon from '../../../../common/svg/CreatedIcon';
import EyeIcon from '../../../../common/svg/EyeIcon';
import FollowIcon from '../../../../common/svg/FollowIcon';
import CommentIcon from '../../../../common/svg/CommentIcon';
import DislikeIcon from '../../../../common/svg/DislikeIcon';
import DotIcon from '../../../../common/svg/DotIcon';
import DownloadIcon from '../../../../common/svg/DownloadIcon';
import DownvoteIcon from '../../../../common/svg/DownvoteIcon';
import EmojiIcon from '../../../../common/svg/EmojiIcon';
import HashtagIcon from '../../../../common/svg/HashtagIcon';
import ImageIcon from '../../../../common/svg/ImageIcon';
import LikeIcon from '../../../../common/svg/LikeIcon';
import PrintIcon from '../../../../common/svg/PrintIcon';
import PriorityIcon from '../../../../common/svg/PriorityIcon';
import RateOffIcon from '../../../../common/svg/RateOffIcon';
import ShareIcon from '../../../../common/svg/ShareIcon';
import UpvoteIcon from '../../../../common/svg/UpvoteIcon';
import UserIcon from '../../../../common/svg/UserIcon';

// Components
//import Element from '../element/Element';
import TileListElement from '../listelement/TileListElement';
import ListGroup from '../listgroup/ListGroup';

const TileListElements = ({ useDrag }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profileFields,
    selectedModel
  } = useContext(Global);

  // Local State
  const [fieldElements, setFieldElements] = useState([]);
  const [staticElements, setStaticElements] = useState([]);
  const [buttonElements, setButtonElements] = useState([]);

  /**
   * Populates the field elements based on the selected model.
   */
  const populateFieldElements = useCallback(() => {
    if (!selectedModel) return;

    const fields = profileFields
      .filter(item => item.modelKey === selectedModel.key)
      .filter(item => validSummaryTypes.includes(item.type));

    const newFieldElements = fields.map(field => ({
      type: 'field',
      title: field.title,
      icon: DotIcon,
      field: field,
    }));

    setFieldElements(newFieldElements);
  }, [profileFields, selectedModel]);

  /**
   * Populates the static elements for the canvas.
   */
  const populateStaticElements = useCallback(() => {
    const newStaticElements = [
      {
        type: 'static',
        elementType: 'label',
        icon: TextIcon,
        title: "Label",
      },
      {
        type: 'static',
        elementType: 'created',
        icon: CreatedIcon,
        title: "Date Created",
        styles: {
          width: '100px',
          height: '20px',
        }
      },
      {
        type: 'static',
        elementType: 'userphoto',
        icon: ImageIcon,
        title: "User Photo",
        styles: {
          width: '100px',
          height: '20px',
        }
      },
      {
        type: 'static',
        elementType: 'username',
        icon: UserIcon,
        title: "User Name",
        styles: {
          width: '100px',
          height: '20px',
        }
      },
      {
        type: 'static',
        elementType: 'viewcount',
        icon: EyeIcon,
        title: "View Count",
        styles: {
          width: '100px',
          height: '20px',
        }
      },
      {
        type: 'static',
        elementType: 'tags',
        icon: HashtagIcon,
        title: "Tags",
        styles: {
          width: '100px',
          height: '20px',
        }
      }
    ];

    setStaticElements(newStaticElements);
  }, []);

  /**
   * Populates the button elements for the canvas.
   */
  const populateButtonElements = useCallback(() => {
    const newButtonElements = [
      { type: 'button', buttonType: 'upvote', icon: UpvoteIcon, title: 'Upvote' },
      { type: 'button', buttonType: 'downvote', icon: DownvoteIcon, title: 'Downvote' },
      { type: 'button', buttonType: 'like', icon: LikeIcon, title: 'Like' },
      { type: 'button', buttonType: 'dislike', icon: DislikeIcon, title: 'Dislike' },
      { type: 'button', buttonType: 'priority', icon: PriorityIcon, title: 'Priority' },
      
      { type: 'button', buttonType: 'rate', icon: RateOffIcon, title: 'Ratings' },
      { type: 'button', buttonType: 'share', icon: ShareIcon, title: 'Share' },
      { type: 'button', buttonType: 'comment', icon: CommentIcon, title: 'Comment' },
      { type: 'button', buttonType: 'follow', icon: FollowIcon, title: 'Follow' },
      { type: 'button', buttonType: 'cart', icon: CartIcon, title: 'Add to Cart' },
      { type: 'button', buttonType: 'download', icon: DownloadIcon, title: 'Download' },
      { type: 'button', buttonType: 'print', icon: PrintIcon, title: 'Print' },
      { type: 'button', buttonType: 'emojis', icon: EmojiIcon, title: 'Emojis' },
    ];

    setButtonElements(newButtonElements);
  }, []);

  /**
   * Gathers fields, static elements, and buttons that can be added to the canvas.
   */
  useEffect(() => {
    populateFieldElements();
    populateStaticElements();
    populateButtonElements();
  }, [
    selectedModel, 
    populateFieldElements, 
    populateStaticElements, 
    populateButtonElements
  ]);

  return (
    <>
      {/* CONTAINER */}
      <div className="tile-list-elements-container"
        style={{
          borderRightColor: theme.backgroundColorFaded,
        }}>

        {/* SCROLLER */}
        <div className="tile-list-elements-scroller">

          {/* FIELDS */}
          <ListGroup title="Data">

            {fieldElements.map((element, index) => (

              <TileListElement
                key={index}
                element={element}
                icon={element.icon}
                title={element.title}
                useDrag={useDrag}
              />

            ))}

          </ListGroup>

          {/* STATIC ELEMENTS */}
          <ListGroup title="Elements">

            {staticElements.map((element, index) => (

              <TileListElement
                key={index}
                element={element}
                icon={element.icon}
                title={element.title}
                useDrag={useDrag}
              />

            ))}

          </ListGroup>

          {/* BUTTONS */}
          <ListGroup title="Buttons">

            {buttonElements.map((element, index) => (

              <TileListElement
                key={index}
                element={element}
                icon={element.icon}
                title={element.title}
                useDrag={useDrag}
              />

            ))}

          </ListGroup>

        </div>

      </div>

    </>
  );

};

export default TileListElements;
