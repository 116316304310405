import React, { useContext } from 'react';

// Styles
import './ChatToolbar.css';

// Global
import { Global } from '../../../Global';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * ChatToolbar Component
 * 
 * This component renders a chat toolbar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChatToolbar = () => {
    const { theme } = useTheme();
    const {
        profile,
        selectedChannel
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="chat-toolbar-container">

                {/* LEFT */}
                <div className="chat-toolbar-left"
                    style={{
                        color: theme.foregroundColorFaded,
                        fontFamily: profile.fontFamily,
                    }}>

                    {selectedChannel &&
                        <>
                            {selectedChannel.title}
                        </>
                    }

                </div>

                {/* RIGHT */}
                <div className="chat-toolbar-right">

                </div>
            </div>
        </>
    );
};

export default ChatToolbar;
