import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import DataManager from '../../../managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load items for a specific profile.
 */
const usePreloadHomeItems = (profile, setHomeItems) => {

  // Global
  const {
    currentSection
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected room
    if (!profile || !profile.key) {
      setHomeItems([]);
      return;
    }

    const load = () => {

      const handleUpdate = (items) => {
        setHomeItems(items);
      };

      const params = [
        { field: "profileKey", operator: "!=", value: profile.key },
        { field: "shared", operator: "==", value: true },
        { field: "trashed", operator: "==", value: false },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.items,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeItems = load();

    return () => {
      if (unsubscribeItems) {
        unsubscribeItems();
      }
    };

  }, [profile, setHomeItems]);

};

export default usePreloadHomeItems;
