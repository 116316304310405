import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CalendarMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import AgendaIcon from '../../../svg/AgendaIcon';
import DayIcon from '../../../svg/DayIcon';
import MonthIcon from '../../../svg/MonthIcon';
import StorylineIcon from '../../../svg/StorylineIcon';
import WeekIcon from '../../../svg/WeekIcon';

// Components
import ContextMenuButton from '../../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../components/contextmenu/ContextMenuPopup';

// Calendar options as state
const defaultCalendarOptions = [
  { key: 'SCHEDULE', icon: AgendaIcon, text: 'All Events' },
  { key: 'DAY', icon: DayIcon, text: 'Day' },
  { key: 'WEEK', icon: WeekIcon, text: 'Week' },
  { key: 'MONTH', icon: MonthIcon, text: 'Month' },
  { key: 'NOTELINE', icon: StorylineIcon, text: 'Storyline', iconSize: 15 },
];

const CalendarMenu = () => {
  // Theme
  const { theme } = useTheme();

  // Global
  const {
    setSelectedCalendarType
  } = useContext(Global);

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
  
  const [calendarOptions, setCalendarOptions] = useState(defaultCalendarOptions);

  /**
   * Toggles visibility of the context menu.
   */
  const toggleMenu = (event) => {
    if (!menuVisible) {
      event.preventDefault();
      setMenuPosition({ left: event.clientX, top: event.clientY });
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  };

  const handleCalendarSelection = async (key) => {
    setSelectedCalendarType(key);
    setMenuVisible(false);
  };

  return (
    <div className="calendar-menu-outer-container">

      {/* MENU BUTTON */}
      <div className="calendar-menu-container">
        <ContextMenuButton
          title="Calendar Views"
          onToggle={toggleMenu}
        />
      </div>

      {/* MENU */}
      <ContextMenuPopup
        visible={menuVisible}
        setVisible={setMenuVisible}
        backgroundColor={theme.backgroundColorFaded}
        width={200}
        position={menuPosition}>

        <ContextMenuGroup title="View">
          {calendarOptions.map(({ key, icon, text, iconSize = 11 }) => (
            <ContextMenuOption
              key={key}
              icon={icon}
              text={text}
              onClick={() => handleCalendarSelection(key)}
              iconSize={iconSize}
            />
          ))}
        </ContextMenuGroup>

      </ContextMenuPopup>

    </div>
  );
};

export default CalendarMenu;

