import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../../Global';

// Styles
import './ItemButtons.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import BookmarkIcon from '../../../../../common/svg/BookmarkIcon';
import LikeIcon from '../../../../../common/svg/LikeIcon';
import CommentIcon from '../../../../../common/svg/CommentIcon';
import ShareIcon from '../../../../../common/svg/ShareIcon';

// Components
import ItemButton from '../button/ItemButton.js';
import ProfileDot from '../../../../../common/components/profiledot/ProfileDot';
import ProfileModal from '../../../../components/profilemodal/ProfileModal.js';

/**
 * ItemButtons
 * 
 * @returns {JSX.Element} ItemFeedButtons component.
 */
const ItemButtons = ({ item }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile
    } = useContext(Global);

    // Local State
    const[profileModalOpen, setProfileModalOpen] = useState(false);

    const handleProfileClick = () => {
        setProfileModalOpen(true);
    };

    return (
        <>
            <div className="item-buttons-container">

                <ProfileDot
                    item={item.profile}
                    size={40}
                    onClick={handleProfileClick}
                />

                <ItemButton
                    icon={LikeIcon}
                    size={24}
                    onClick={() => console.log('Like clicked')}
                    count={26000}
                />

                <ItemButton
                    icon={CommentIcon}
                    size={21}
                    onClick={() => console.log('Comment clicked')}
                    count={3000}
                />

                <ItemButton
                    icon={BookmarkIcon}
                    size={24}
                    onClick={() => console.log('Bookmark clicked')}
                    count={156}
                />

                <ItemButton
                    icon={ShareIcon}
                    size={21}
                    onClick={() => console.log('Share clicked')}
                    count={7240}
                />

            </div>

            {/* PROFILE */}
            <ProfileModal 
                profile={item.profile}
                isOpen={profileModalOpen}
                setOpen={setProfileModalOpen}

            />
        </>
    );
};

export default ItemButtons;
