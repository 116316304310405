import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ItemPanelRow.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import RightIcon from '../../../svg/RightIcon';

const ItemPanelRow = ({ title, children }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {

    } = useContext(Global);

    return (
        <div className="item-panel-row-container">

            <div className="item-panel-row-title"
                style={{
                    color: theme.foregroundColor
                }}>
                {title}
                <RightIcon color={theme.foregroundColor} width="20" height="20" />
            </div>

            <div className="item-panel-row-content">
                {children}
            </div>

        </div>
    );
};

export default ItemPanelRow;
