import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './Friends.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import FriendsToolbar from './toolbar/FriendsToolbar';
import Section from '../../components/section/Section';

const Friends = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
  } = useContext(Global);

  return (

    <Section>

      {/* TOOLBAR */}
      <FriendsToolbar />


    </Section>

  );
};

export default Friends;
