import React from 'react';

// Styles
import './CalendarStorylineGrid.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const CalendarStorylineGrid = ({ tags, tagHeight }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="calendar-storyline-grid-container">
            {tags.map((tag, index) => (
                <div className="calendar-storyline-grid-line" key={tag}
                    style={{
                        color: theme.foregroundColor,
                        borderBottomColor: theme.backgroundColorFaded,
                        height: `${tagHeight}px`,
                    }}>
                </div>
            ))}
        </div>
    );
};

export default CalendarStorylineGrid;
