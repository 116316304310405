import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

// Formatter
import { formatDaySpan } from '../../../../utilities/Formatters';

// Styles
import './CalendarDayAllDayEvent.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const CalendarDayAllDayEvent = ({ event }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    setSelectedEvent,
    setEventVisible,
  } = useContext(Global);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventVisible(true);
  };

  return (
    <div className="calendar-day-all-day-event"
      onClick={() => handleEventClick(event)}
      style={{
        backgroundColor: theme.highlightBackgroundColor,
      }}>
      <div className="calendar-day-all-day-event-title"
        style={{
          color: theme.highlightForegroundColor,
        }}>
        {event.title}
      </div>
      <div className="calendar-day-all-day-event-date"
        style={{
          color: theme.highlightForegroundColor,
        }}>
        &nbsp;-&nbsp;{formatDaySpan(event.startDate.toDate(), event.endDate.toDate())}
      </div>
    </div>
  );
};

export default CalendarDayAllDayEvent;
