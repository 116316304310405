import React, { useContext, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { ItemType, SectionType } from '../../utilities/Enums';
import { getShareCount, getStringPathFromKeys } from '../../../common/utilities/Folders';

// Helpers
import { handleDragStart, handleDragOver, handleDragEnter, handleDragLeave, handleDropItem } from './helpers/FolderDragHelper.js';

// Styles
import './Folder.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import EventForm from '../../../common/sections/calendar/event/EventForm';
import FileEdit from './item/file/edit/FileEdit.js';
import FolderAdd from './add/FolderAdd';
import FolderEdit from './edit/FolderEdit';
import FolderToolbar from './toolbar/FolderToolbar';
import Form from '../../../common/form/Form';
import Grid from '../../../common/components/grid/Grid';
import Item from './item/Item';
import ObjectAdd from '../../../common/collection/addobject/ObjectAdd';
import Search from '../../../common/search/Search';
import Section from '../../../common/components/section/Section';
import Note from '../../../common/note/view/Note';
import NoteForm from '../../../common/note/form/NoteForm';

// Hooks
import useSetFolderItems from './hooks/SetFolderItems.js';
import useSetFolderPath from './hooks/SetFolderPath.js';

// Managers
import DataManager from '../../../common/managers/DataManager';
import FolderManager from '../../../common/managers/FolderManager';

const dataManager = new DataManager();
const folderManager = new FolderManager();

const Folder = ({ columnWidth = 200, showToolbar = true }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        hideProgress,
        profile,
        profileFolders,
        selectedFolder,
        selectedFolderItems,
        selectedRoom,
        setCurrentSection,
        setEventVisible,
        setSelectedEvent,
        setSelectedFolder,
        setSelectedFolderItems,
        setSelectedObject,
        setSelectedRoom,
        setSelectedNote,
        showProgress,
    } = useContext(Global);

    // Items in this folder
    const [folderItems, setFolderItems] = useState([]);

    const [pathKeys, setPathKeys] = useState([]); // Array of keys
    const [isDragging, setIsDragging] = useState(false);

    // Add
    const [addSearchOpen, setAddSearchOpen] = useState(false);
    const [addFolderOpen, setAddFolderOpen] = useState(false);
    const [addNoteOpen, setAddNoteOpen] = useState(false);
    const [addObjectOpen, setAddObjectOpen] = useState(false);

    // View
    const [viewNoteOpen, setViewNoteOpen] = useState(false);

    // Edit
    const [editFolderOpen, setEditFolderOpen] = useState(false);
    const [editObjectOpen, setEditObjectOpen] = useState(false);

    // File Edit
    const [selectedFile, setSelectedFile] = useState(null);
    const [editFileOpen, setEditFileOpen] = useState(false);

    // References
    const dragCounter = useRef(0);
    const fileInputRef = useRef(null);
    const containerRef = useRef(null);

    /**
     * Filters private items based on the selected folder.
     */
    useSetFolderItems({ setFolderItems });

    /**
     * Prepares the breadcrumb folder path keys.
     */
    useSetFolderPath({ profileFolders, selectedFolder, setPathKeys });

    /**
     * Handles selection of an object in the search dialog.
     */
    const handleSearch = async (entry) => {
        // Fetch the selected object and set it to active
        /*
        const object = profileObjects.find(object => object.key === entry.objectKey);
        const updatedObject = { ...object, folderKey: selectedFolder.key };
        await dataManager.update(collections.items, profile.key, updatedObject.key, updatedObject);

        */

        // Close the search dialog
        setAddSearchOpen(false);
    };

    /**
     * Handles single click on an item.
     */
    const handleItemClick = (event, item) => {
        event.stopPropagation();

        if (event.ctrlKey || event.metaKey || event.shiftKey) {
            // If the Control (Windows) or Command (macOS) key is held, toggle the item's selection
            if (selectedFolderItems.some((selected) => selected.key === item.key)) {
                setSelectedFolderItems(selectedFolderItems.filter((selected) => selected.key !== item.key));
            } else {
                setSelectedFolderItems([...selectedFolderItems, item]);
            }
        } else {
            // If no modifier key is held, select only the clicked item and clear other selections
            if (!selectedFolderItems.some((selected) => selected.key === item.key)) {
                setSelectedFolderItems([item]);
            }
            // Do nothing if the item is already in the array
        }
    };

    /**
     * Deselects all items when the container is clicked.
     */
    const handleContainerClick = () => {
        setSelectedFolderItems([]);
    };

    /**
     * DOUBLE-CLICK HANDLERS
     * 
     * Handles double-clicking of items and routes the intent
     * to the appropriate handler.
     */
    const handleItemDoubleClick = (item) => {
        if (item.type === ItemType.FOLDER) {
            handleFolderDoubleClick(item.key);
        } else if (item.type === ItemType.FILE) {
            handleFileDoubleClick(item);
        } else if (item.type === ItemType.PHOTO) {
            handleFileDoubleClick(item);
        } else if (item.type === ItemType.VIDEO) {
            handleFileDoubleClick(item);
        } else if (item.type === ItemType.EVENT) {
            handleEventDoubleClick(item);
        } else if (item.type === ItemType.ROOM) {
            handleRoomDoubleClick(item);
        } else if (item.type === ItemType.NOTE) {
            handleNoteDoubleClick(item);
        } else if (item.type === ItemType.OBJECT) {
            handleObjectDoubleClick(item.key);
        }
    };

    // Folder
    const handleFolderDoubleClick = (key) => {
        const folder = folderItems.find((folder) => folder.key === key);
        setSelectedFolder(folder);
    };

    // Files
    const handleFileDoubleClick = (file) => {
        setSelectedFile(file);
        setEditFileOpen(true);
    };

    // Event
    const handleEventDoubleClick = (item) => {
        const event = folderItems.find((event) => event.key === item.key);
        setSelectedEvent(event);
        setEventVisible(true);
    };

    // Room
    const handleRoomDoubleClick = (item) => {
        const room = folderItems.find((room) => room.key === item.key);
        setSelectedRoom(room);
        setCurrentSection(SectionType.ROOM);
    };

    // Object
    const handleObjectDoubleClick = (key) => {
        const object = folderItems.find((obj) => obj.key === key);
        setSelectedObject(object);
        setEditObjectOpen(true);
    };

    // Note
    const handleNoteDoubleClick = (item) => {
        const note = folderItems.find((note) => note.key === item.key);
        setSelectedNote(note);
        setAddNoteOpen(true);
    };

    /**
     * TOOLBAR BUTTON HANDLERS
     */

    // Settings
    const handleSettingsClicked = async () => {
        setEditFolderOpen(true);
    }

    // Share
    const handleShareClicked = async () => {
        setSelectedShared(true);
    };

    // Unshare
    const handleUnshareClicked = async () => {
        setSelectedShared(false);
    };

    /**
     * Utility for saving shared status of selected items.
     */
    const setSelectedShared = async (share) => {
        for (const item of selectedFolderItems) {
            await dataManager.update(
                collections.items,
                profile.key,
                item.key,
                { shared: share }
            );
        }
    };

    // Copy
    const handleCopyClicked = () => {

    };

    // Cut
    const handleCutClicked = () => {

    };

    // Paste
    const handlePasteClicked = () => {

    };

    // Delete Clicked
    const handleTrashClicked = async (event) => {
        event.stopPropagation();

        showProgress(`Sending to trash...`);

        for (const item of selectedFolderItems) {
            if (item.type === ItemType.FOLDER) {
                // Delete the folder and all its contents
                await folderManager.trashFolder(item);
            } else {
                await dataManager.update(
                    collections.items,
                    profile.key,
                    item.key,
                    { trashed: true }
                );
            }
        }

        setSelectedFolderItems([]);

        hideProgress();
    };

    /**
     * Handles dropping of photos into the component.
     * 
     * @param {event} event - Event object.
     */
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;
        handleCanvasDrop(event);
    };

    /**
     * Handles adding of files to a folder via drag/drop.
     * 
     * @param {event} event - Event object.
     */
    const handleCanvasDrop = async (event) => {

        if (selectedFolder) {
            showProgress(`Adding files to ${selectedFolder.title}...`);
        } else {
            showProgress(`Adding files...`);
        }

        const folderPath = `profiles/${profile.key}/files/${getStringPathFromKeys(profileFolders, pathKeys)}`;

        // Handle the drop of all the files
        await folderManager.handleFileDrop(event, folderPath, selectedRoom, selectedFolder, profile);

        fileInputRef.current.value = "";

        hideProgress();
    };

    return (

        <Section>

            <div ref={containerRef}
                title="Drop files here."
                className="folder-container"
                style={{
                    backgroundColor: 'transparent',
                    borderColor: theme.backgroundColorFaded,
                    borderWidth: isDragging ? '2px' : '1px',
                }}
                onClick={handleContainerClick}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragEnter={(event) => handleDragEnter(event, dragCounter, setIsDragging)}
                onDragLeave={(event) => handleDragLeave(event, dragCounter, setIsDragging)}>

                {/* TOOLBAR */}
                {showToolbar && !selectedRoom &&
                    <FolderToolbar
                        folderItems={folderItems}
                        pathKeys={pathKeys}
                        getShareCount={getShareCount}
                        onDropItem={(key, type, targetFolderKey) => // Inject additional params into the handler
                            handleDropItem(key, type, targetFolderKey, profile, selectedFolderItems)
                        }
                        onCopyClicked={handleCopyClicked}
                        onCutClicked={handleCutClicked}
                        onPasteClicked={handlePasteClicked}
                        onDeleteClicked={handleTrashClicked}
                        onShareClicked={handleShareClicked}
                        onUnshareClicked={handleUnshareClicked}
                        onSettingsClicked={handleSettingsClicked}
                    />
                }

                {/* CONTENT */}
                <div className={isMobile ? "folder-grid-container-mobile" : "folder-grid-container"} >
                    <Grid
                        items={folderItems}
                        renderItem={(item) => (

                            <Item
                                key={item.key}
                                item={item}
                                pathKeys={pathKeys}
                                selected={selectedFolderItems.some((selected) => selected.key === item.key)}
                                onClick={(event, item) => handleItemClick(event, item)}
                                onDoubleClick={() => handleItemDoubleClick(item)}
                                onDragStart={(event) => handleDragStart(event, item, selectedFolderItems, setSelectedFolderItems)}
                                onDropItem={(key, type, targetFolderKey) =>  // Inject additional params into the handler
                                    handleDropItem(key, type, targetFolderKey, profile, selectedFolderItems)
                                }
                            />

                        )}
                        loading={false}
                        minCols={1}
                        colWidth={isMobile ? 100 : columnWidth}
                    />
                </div>

                {/* FILE INPUT */}
                <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={(event) => handleCanvasDrop(event)}
                    style={{ display: 'none' }}
                    aria-label="File Upload"
                    multiple
                />

                {/* EVENT FORM */}
                <EventForm
                    date={new Date()}
                    folderKey={selectedFolder ? selectedFolder.key : null}
                />

                {/* SEARCH FORM */}
                <Search
                    onSearch={handleSearch}
                    isVisible={addSearchOpen}
                    setVisible={setAddSearchOpen}
                />

                {/* OBJECT FORM */}
                <Form
                    isOpen={editObjectOpen}
                    setOpen={setEditObjectOpen}
                />

                {/* NOTE FORM */}
                <NoteForm
                    isOpen={addNoteOpen}
                    setOpen={setAddNoteOpen}
                />

                {/* NOTE */}
                <Note
                    isOpen={viewNoteOpen}
                    setOpen={setViewNoteOpen}
                />

                {/* ADD OBJECT */}
                <ObjectAdd
                    isOpen={addObjectOpen}
                    setOpen={setAddObjectOpen}
                />

                {/* ADD */}
                <FolderAdd
                    isOpen={addFolderOpen}
                    setOpen={setAddFolderOpen}
                    folderKey={selectedFolder && selectedFolder.key || null}
                />

                {/* FOLDER EDIT */}
                <FolderEdit
                    folder={selectedFolder}
                    isOpen={editFolderOpen}
                    setOpen={setEditFolderOpen}
                />

                {/* FILE EDIT */}
                {selectedFile &&
                    <FileEdit
                        item={selectedFile}
                        isOpen={editFileOpen}
                        setOpen={setEditFileOpen}
                    />
                }

            </div>

        </Section>
    );
};

export default Folder;
