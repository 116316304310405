import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './NoteFormBlock.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Document from '../components/document/Document';
import Event from '../components/event/Event';
import Gallery from '../components/gallery/Gallery';
import Link from '../components/link/Link';
import ObjectTile from '../../collection/tiles/tile/ObjectTile';
import Text from '../components/text/Text';
import VideoGallery from '../components/videogallery/VideoGallery';

const NoteFormBlock = ({
    index,
    block,
    onChange,
    onDelete,
    onKeyDown,
    mode = "VIEW"
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profileModels,
        resetSelections,
        selectedNoteBlock,
        setSelectedNoteBlock,
        setSelectedModel,
        setSelectedObject,
    } = useContext(Global);

    // Local State
    const [content, setContent] = useState(block.content || null);
    const [type, setType] = useState(block.type || null);

    /**
     * Handles additions and changes to the block content.
     */
    const handleChange = (type, content) => {
        setType(type);
        setContent(content);
        onChange(index, type, content);
    };

    /**
     * Handles an object click and navigates to the object in collections.
     */
    const handleObjectClick = async (object) => {
        if (mode === "VIEW") {
            resetSelections();
            const appCollection = profileModels.find(appCollection => appCollection.key === object.modelKey);
            setSelectedModel(appCollection);
            setSelectedObject(object);
        } else {
            setSelectedNoteBlock(block);
        }
    };

    /** 
     * Sets the selected block to this one when the textarea gains focus.
     */
    const handleFocus = () => {
        setSelectedNoteBlock(block);
    }

    /** 
     * Handles deletion of the block.
     */
    const handleDelete = () => {
        if (index > 0) {
            onDelete(index);
        }
    }

    /**
     * Handles a block click.
     */
    const handleClick = async () => {
        if (mode === "EDIT") {
            setSelectedNoteBlock(block);
        }
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="note-form-block-container"
                style={{
                    borderColor: "transparent",
                    color: theme.foregroundColor
                }}>

                {type !== null && (
                    <>
                        {/* TEXT */}
                        {type === "TEXT" && (
                            <Text
                                block={block}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                onDelete={handleDelete}
                                onKeyDown={onKeyDown}
                            />
                        )}

                        {/* PHOTOS */}
                        {type === "PHOTOS" && (
                            <div className="note-form-block-gallery-wrapper"
                                style={{
                                    height: content.length === 1 ? "auto" : "200px",
                                    borderColor: (selectedNoteBlock && selectedNoteBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}>

                                <Gallery
                                    content={content}
                                    onClick={handleClick}
                                    mode={mode}
                                />

                            </div>
                        )}

                        {/* VIDEOS */}
                        {type === "VIDEOS" && (
                            <div className="note-form-block-gallery-wrapper"
                                style={{
                                    borderColor: (selectedNoteBlock && selectedNoteBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}>

                                <VideoGallery
                                    content={content}
                                    onClick={handleClick}
                                    mode={mode}
                                />

                            </div>
                        )}

                        {/* DOCUMENTS */}
                        {type === "DOCUMENTS" && (
                            <div className="note-form-block-documents-wrapper">
                                <div
                                    className="note-form-block-document-wrapper"
                                    style={{
                                        borderColor: (selectedNoteBlock && selectedNoteBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                    }}>
                                    <Document
                                        content={content[0]}
                                        onClick={handleClick}
                                        mode={mode}
                                    />
                                </div>
                            </div>
                        )}

                        {/* OBJECTS */}
                        {type === "OBJECT" && (
                            <div className="note-form-block-object-wrapper"
                                style={{
                                    backgroundColor: theme.backgroundColorFaded,
                                    borderColor: (selectedNoteBlock && selectedNoteBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}
                                onClick={() => handleObjectClick(content)}>
                                <ObjectTile 
                                    object={content} 
                                />
                            </div>
                        )}

                        {/* EVENTS */}
                        {type === "EVENT" && (
                            <div className="note-form-block-event-wrapper"
                                style={{
                                    backgroundColor: theme.backgroundColorFaded,
                                    borderColor: (selectedNoteBlock && selectedNoteBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}>
                                <Event
                                    event={content}
                                    onClick={handleClick}
                                    mode={mode}
                                />
                            </div>
                        )}

                        {/* LINK */}
                        {type === "LINK" && (
                            <div className="note-form-block-link-wrapper"
                                style={{
                                    borderColor: (selectedNoteBlock && selectedNoteBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}>

                                <Link
                                    url={content}
                                    onClick={handleClick}
                                    mode={mode}
                                />

                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default NoteFormBlock;
