import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './SettingsItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const SettingsItem = ({ option, icon: IconComponent, onClick }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    selectedSetting,
  } = useContext(Global);

    return (

        <div className="settings-item-container"
            onClick={onClick}
            style={{
                backgroundColor: selectedSetting && selectedSetting.key === option.key ? theme.highlightBackgroundColor : 'transparent',
            }}>

            {/* TOP */}
            <div className="settings-item-top">

                <div className="settings-item-icon">
                    <IconComponent
                        color={selectedSetting && selectedSetting.key === option.key ? theme.highlightForegroundColor : theme.highlightBackgroundColor}
                        width="14"
                        height="14"
                    />
                </div>
                <div className="settings-item-text"
                    style={{
                        color: selectedSetting && selectedSetting.key === option.key ? theme.highlightForegroundColor : theme.foregroundColor,
                    }}>
                    {option.title}
                </div>

            </div>

            {/* BOTTOM */}
            <div className="settings-item-bottom"
                style={{
                    color: selectedSetting && selectedSetting.key === option.key ? theme.highlightForegroundColor : theme.foregroundColorFaded,
                }}>
                {option.description}
            </div>

        </div>

    );
};

export default SettingsItem;
