// Config
import { allowedExtensions, imageFileTypes, videoFileTypes } from '../../Config';

// Firebase
import { collection, doc, getDocs, query, Timestamp, where, writeBatch } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { collections, db, storage } from '../../firebaseConfig';

// Utilities
import { ItemType } from '../../common/utilities/Enums';
import { generateKey } from '../../common/utilities/Keys';

// Managers
import { activity } from './ActivityManager';
import DataManager from './DataManager';
import PromptManager from './PromptManager';

const dataManager = new DataManager();
const promptManager = new PromptManager();

class FolderManager {

    /**
     * Generates a list of folders for a new app with the provided app title and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns A parsable list of folders that can individually be populated with fields
     *          in subsequent prompts.
     */
    async generateFolderList(appTitle, appDescription, temperature) {

        const folderListPrompt = await this.prepareFolderListPrompt(appTitle, appDescription);

        const response = await promptManager.sendJsonPrompt(folderListPrompt, temperature);

        return response.folders;
    };

    /**
     * Prepares a prompt for generating a list of folders for an app based on an app's title 
     * and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * 
     * @returns Prompt that can be used to generate a list of folders.
     */
    async prepareFolderListPrompt(appTitle, appDescription) {

        let instructions = `
        [START INSTRUCTIONS]

        [GOAL]
        A feature of the app we will be generating is the ability to organize files into folders. To prepare this list, we will
        define the top-level data folder structure for the app.
        The goal of your response is to define the top-level data folder structure for the app that the user is trying to create.
        Using the provided TITLE and DESCRIPTION, please define a list of folders that will be used to structure the app's initial
        top-level folder state.

        Between 3 and 5 folders would be an ideal starting point for most apps - but if the app we are generating is very file-heavy,
        you can define more folders. If the app is very simple, you can define fewer folders.

        The folders will only be used to organize potential files within the app. Database-types of data will be treated separately.

        For titles, it is acceptable to use user-friendly names, with spaces between words, for the folders. For example, you can call 
        a folder "Legal Documents" instead of "LegalDocuments" or "legaldocuments".

        There is no need to create the following types of items, as those will be handled separately:
        - models related to appointments/schedules/events
        - models related to communication channels, messaging or chat
        - models related to news or current events
        - models related to users of the system
        - models related to payments and commerce

        Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
        Please do not add comments or characters that might lead to the inability to parse the JSON.

        [APP TITLE AND DESCRIPTION]
        TITLE: ${appTitle}
        DESCRIPTION: ${appDescription}

        Do not add any nesting, and do not structure the response differently from the example.

        This example might be one that a real estate app could use:

        [EXAMPLE RESPONSE]
        {
            "folders": [
                {
                    "title": "Legal Templates",
                    "description": "Shared legal templates."
                },
                {
                    "title": "Home Photos",
                    "description": "Photos provided by team agents."
                },
                {
                    "title": "Walk-Through Videos",
                    "description": "Videos of various listings."
                },
            ]
        }

        [END INSTRUCTIONS]`;

        return instructions;
    }

    /**
     * Generates fields and tags for a folder.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * @param {string} folderTitle - The title of the folder.
     * @param {string} folderDescription - The description of the folder.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns A parsable folder object.
     */
    async generateFolder(appTitle, appDescription, folderTitle, folderDescription, temperature) {

        const folderPrompt = await this.prepareFolderPrompt(appTitle, appDescription, folderTitle, folderDescription);

        const folder = await promptManager.sendJsonPrompt(folderPrompt, temperature);

        return folder;
    }

    /**
     * Handles adding of files to a folder via drag/drop.
     * 
     * @param {event} event - Event object.
     */
    async handleFileDrop(event, folderPath, selectedRoom, selectedFolder, profile) {

        // Determine the source of the files (drag-and-drop or file input)
        const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        if (!files || files.length === 0) return;

        for (const file of files) {
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                console.log(`Invalid file type for ${fileName}. Only documents and images are allowed.`);
                continue;
            }

            const key = generateKey();
            const filePath = `${folderPath}/${key}.${fileExtension}`;
            const storageRef = ref(storage, filePath);
            try {
                await uploadBytes(storageRef, file);
                const fileUrl = await getDownloadURL(storageRef);

                // Save a document record
                const key = generateKey();

                // Current timestamp
                const now = Timestamp.now();

                // Profile display info
                const displayProfile = {
                    key: profile.key,
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                    photo: profile.photo,
                };

                // Create a document record with additional metadata
                const fileRecord = {
                    key: key,
                    profileKey: profile.key,
                    profile: displayProfile,
                    type: this.getItemType(fileExtension),
                    title: fileName,
                    extension: fileExtension,
                    roomKey: selectedRoom && selectedRoom.key || null,
                    folderKey: selectedFolder && selectedFolder.key || null,
                    path: filePath,
                    url: fileUrl,
                    size: file.size,
                    fileType: file.type,
                    lastModified: file.lastModified,
                    trashed: false,
                    shared: false,
                    dateCreated: now
                };

                // Log the size of the file in bytes
                activity.log(profile.key, 'uploads', file.size);

                // Add the document to the database
                dataManager.add(collections.items, profile.key, key, fileRecord);

            } catch (error) {
                console.error(`Error uploading file ${fileName}:`, error);
            }
        }
    }

    /**
     * Determines the ItemType based on the file extension.
     */
    getItemType = (extension) => {
        const isImageFile = imageFileTypes.includes(extension);
        const isVideoFile = videoFileTypes.includes(extension);
        if (isImageFile) {
            return ItemType.PHOTO;
        } else if (isVideoFile) {
            return ItemType.VIDEO;
        } else {
            return ItemType.FILE;
        }
    };

    /**
     * Recursively deletes a folder and its contents using batch processing.
     * 
     * @param {Object} item - The folder/item to delete. If it's a folder, deletes all contents recursively.
     * @param {number} batchLimit - The maximum number of operations per batch (default: 500).
     */
    async deleteFolder(item, batchLimit = 500) {
        if (!item || !item.type) {
            throw new Error("Invalid item provided for deletion.");
        }

        const batch = writeBatch(db); // Initialize a Firestore batch
        const folderQueue = [item]; // Initialize a queue for processing folders

        while (folderQueue.length > 0) {
            const currentFolder = folderQueue.shift(); // Dequeue the next folder

            // Query for all items in the current folder
            const folderContentsQuery = query(
                collection(db, collections.items),
                where("folderKey", "==", currentFolder.key)
            );
            const folderSnapshot = await getDocs(folderContentsQuery);

            for (const doc of folderSnapshot.docs) {
                const subItem = { ...doc.data(), key: doc.id };

                if (subItem.type === ItemType.FOLDER) {
                    // Enqueue subfolder for recursive processing
                    folderQueue.push(subItem);
                } else {
                    // Add non-folder items to the batch for deletion
                    batch.delete(doc(db, collections.items, subItem.key));
                }

                // Commit the batch if it reaches the limit
                if (batch._mutations.length >= batchLimit) {
                    await batch.commit();
                    console.log("Batch committed.");
                    batch.clear(); // Start a new batch
                }
            }

            // Add the current folder itself to the batch for deletion
            const folderRef = doc(db, collections.items, currentFolder.key);
            batch.delete(folderRef);
        }

        // Commit any remaining operations in the batch
        if (batch._mutations.length > 0) {
            await batch.commit();
            console.log("Final batch committed.");
        }

        console.log(`Deleted folder and its contents: ${item.key}`);
    }

    /**
     * Recursively trash a folder and its contents using batch processing.
     * 
     * @param {Object} item - The folder/item to trash. If it's a folder, trash all contents recursively.
     * @param {number} batchLimit - The maximum number of operations per batch (default: 500).
     */
    async trashFolder(item, batchLimit = 500) {
        if (!item || !item.type) {
            throw new Error("Invalid item provided for trashing.");
        }

        const batch = writeBatch(db); // Initialize a Firestore batch
        const folderQueue = [item]; // Initialize a queue for processing folders

        while (folderQueue.length > 0) {
            const currentFolder = folderQueue.shift(); // Dequeue the next folder

            // Query for all items in the current folder
            const folderContentsQuery = query(
                collection(db, collections.items),
                where("folderKey", "==", currentFolder.key)
            );
            const folderSnapshot = await getDocs(folderContentsQuery);

            for (const doc of folderSnapshot.docs) {
                const subItem = { ...doc.data(), key: doc.id };

                if (subItem.type === ItemType.FOLDER) {
                    // Enqueue subfolder for recursive processing
                    folderQueue.push(subItem);
                } else {
                    // Add non-folder items to the batch for updating
                    batch.update(doc(db, collections.items, subItem.key), { trashed: true });
                }

                // Commit the batch if it reaches the limit
                if (batch._mutations.length >= batchLimit) {
                    await batch.commit();
                    console.log("Batch committed.");
                    batch.clear(); // Start a new batch
                }
            }

            // Add the current folder itself to the batch for updating
            const folderRef = doc(db, collections.items, currentFolder.key);
            batch.update(folderRef, { trashed: true });
        }

        // Commit any remaining operations in the batch
        if (batch._mutations.length > 0) {
            await batch.commit();
            console.log("Final batch committed.");
        }

        console.log(`Trashed folder and its contents: ${item.key}`);
    }

}

export default FolderManager;
