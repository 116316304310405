import React from 'react';

// Styles
import './Divider.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * Divider Component
 * 
 * This component renders a divider.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Divider = ({ direction = "horizontal", color, height = "100%", width = "100%", margin = "7px" }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
      {/* CONTAINER */}
      {direction === "vertical" ? (
        <div className="divider-container-vertical"
          style={{
            borderRightColor: color || theme.backgroundColorFaded,
            height: height,
            marginLeft: margin,
            marginRight: margin
          }}> </div>
      ) : (
        <div className="divider-container-horizontal"
          style={{
            borderBottomColor: color || theme.backgroundColorFaded,
            width: width,
            marginTop: margin,
            marginBottom: margin
          }}></div>
      )}
    </>
  );
};

export default Divider;

