import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import DataManager from '../../../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load items for a specific profile.
 */
const usePreloadRoomItems = () => {

  // Global
  const {
    profile,
    selectedRoom,
    setRoomItems
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected room
    if (!selectedRoom || !selectedRoom.key) {
      setRoomItems([]);
      return;
    }

    const load = () => {
      const handleUpdate = (items) => {
        setRoomItems(items);
      };

      const params = [
        { field: "roomKey", operator: "==", value: selectedRoom.key },
        { field: "trashed", operator: "!=", value: true }
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.items,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeItems = load();

    return () => {
      if (unsubscribeItems) {
        unsubscribeItems();
      }
    };
  }, [profile, setRoomItems]);

};

export default usePreloadRoomItems;
