import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase// Firebase
import { signOut } from 'firebase/auth';
import { auth, collections } from '../../../firebaseConfig';

// Translation
import { useTranslation } from 'react-i18next';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { SectionType, ThemeMode } from '../../utilities/Enums';
import { getFolderPath, getStringPathFromKeys } from '../../utilities/Folders';

// Styles
import './MenuBar.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Images
import CalendarFilledIcon from '../../svg/CalendarFilledIcon';
import CalendarIcon from '../../svg/CalendarIcon';
import DarkModeIcon from '../../svg/DarkModeIcon';
import DataFilledIcon from '../../svg/DataFilledIcon';
import DataIcon from '../../svg/DataIcon';
import FolderIcon from '../../svg/FolderIcon';
import FolderFilledIcon from '../../svg/FolderFilledIcon';
import FriendsIcon from '../../svg/FriendsIcon';
import FriendsFilledIcon from '../../svg/FriendsFilledIcon';
import HomeIcon from '../../svg/HomeIcon';
import HomeFilledIcon from '../../svg/HomeFilledIcon';
import InboxFilledIcon from '../../svg/InboxFilledIcon';
import InboxIcon from '../../svg/InboxIcon';
import LogoutIcon from '../../svg/LogoutIcon';
import RoomsIcon from '../../svg/RoomsIcon';
import RoomsFilledIcon from '../../svg/RoomsFilledIcon';
import SearchIcon from '../../svg/SearchIcon';
import SettingsFilledIcon from '../../svg/SettingsFilledIcon';
import SettingsIcon from '../../svg/SettingsIcon';
import TrashFilledIcon from '../../svg/TrashFilledIcon';
import TrashIcon from '../../svg/TrashIcon';

// Components
import EventForm from '../../sections/calendar/event/EventForm';
import FolderAdd from '../../../common/sections/folders/add/FolderAdd';
import FolderAddButton from './addbutton/FolderAddButton';
import MenuBarButton from './button/MenuBarButton';
import NoteForm from '../../note/form/NoteForm';
import ObjectAdd from '../../collection/addobject/ObjectAdd';
import ProfileDot from '../profiledot/ProfileDot';
import RoomAdd from '../../../common/sections/room/add/RoomAdd';

// Managers
import DataManager from '../../managers/DataManager';
import FolderManager from '../../../common/managers/FolderManager';

const dataManager = new DataManager();
const folderManager = new FolderManager();

const MenuBar = () => {

    // Theme
    const {
        currentThemeMode,
        setCurrentThemeMode,
    } = useTheme();

    // Global
    const {
        currentSection,
        hideProgress,
        profile,
        profileFolders,
        trashedItems,
        setCurrentSection,
        selectedFolder,
        selectedFolderItems,
        selectedRoom,
        setEventVisible,
        setProfile,
        setSelectedFolderItems,
        setSelectedModel,
        setSelectedRoom,
        setSelectedNote,
        showProgress,
    } = useContext(Global);

    // Local State
    const [pathKeys, setPathKeys] = useState([]); // Array of keys
    const [addFolderOpen, setAddFolderOpen] = useState(false);
    const [addRoomOpen, setAddRoomOpen] = useState(false);
    const [addNoteOpen, setAddNoteOpen] = useState(false);
    const [addObjectOpen, setAddObjectOpen] = useState(false);

    // References
    const fileInputRef = useRef(null);

    // Translation
    const { t } = useTranslation('MenuBar');

    useEffect(() => {
        if (selectedFolder) {
            const keys = getFolderPath(profileFolders, selectedFolder);
            setPathKeys(keys);
        } else {
            setPathKeys([]);
        }
    }, [profileFolders, selectedFolder]);

    /**
     * Handles dropping of items into the trash.
     * 
     * @param {event} event - Event object.
     * @param {object} item - Item to trash.
     */
    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const draggedItemKey = event.dataTransfer.getData("key");
        const draggedItemType = event.dataTransfer.getData("type");

        showProgress(`Adding ${selectedFolderItems.length} items to the trash...`);

        if (draggedItemKey) {
            for (const item of selectedFolderItems) {
                await dataManager.update(
                    collections.items,
                    profile.key,
                    item.key,
                    { trashed: true }
                );
            }

            hideProgress();
        } else {
            console.error("No key found in dataTransfer");
        }
    };

    /**
     * Handles click on the profile button.
     */
    const handleProfileClick = () => {
        setCurrentSection(SectionType.PROFILE);
    };

    /**
     * Handles click on the folders button.
     */
    const handleHomeClick = () => {
        setCurrentSection(SectionType.HOME);
    };

    /**
     * Handles click on the inbox button.
     */
    const handleInboxClick = () => {
        setCurrentSection(SectionType.INBOX);
    };

    /**
     * Handles click on the folders button.
     */
    const handleFoldersClick = () => {
        setSelectedFolderItems([]);
        setSelectedRoom(null);
        setCurrentSection(SectionType.FOLDERS);
    };

    /**
     * Handles click on the friends button.
     */
    const handleFriendsClick = () => {
        console.log("Friends Clicked");
        setCurrentSection(SectionType.FRIENDS);
    };

    /**
     * Handles click on the rooms button.
     */
    const handleRoomsClick = () => {
        setCurrentSection(SectionType.ROOMS);
    };

    /**
     * Handles click on the database button, which opens the form designer.
     */
    const handleDatabaseClick = () => {
        setCurrentSection(SectionType.STUDIO);
    };

    /**
     * Handles click on the calendar button, which opens the calendar.
     */
    const handleCalendarClick = () => {
        setCurrentSection(SectionType.CALENDAR);
    };

    /**
     * Handles click on the message button, which opens dms.
     */
    const handleMessagesClick = () => {
        setCurrentSection(SectionType.ROOM);
    };

    /**
     * Handles click on the message button, which opens dms.
     */
    const handleTrashClick = () => {
        setCurrentSection(SectionType.TRASH);
    };

    /**
     * Handles click on the dark mode toggle button.
     */
    const handleDarkModeClick = () => {
        const newTheme = currentThemeMode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK;
        setCurrentThemeMode(newTheme);

        // Call the setPreference method to update LAST_THEME settings

        /*
        await profileManager.setPreference(
            currentUser,
            setCurrentUser,
            profile.key,
            UserPreference.LAST_THEME,
            newTheme
        );
        */
    };

    /**
     * Handles click on the settings button.
     */
    const handleSettingsClick = () => {
        setCurrentSection(SectionType.SETTINGS);
    };

    /**
     * Handles click on the log out button.
     */
    const handleLogoutClick = async () => {
        
      await signOut(auth);

      setProfile(null);
      return;
    };

    /**
     * ADD MENU FUNCTIONS
     */

    // Files
    const handleAddFilesClick = () => {
        fileInputRef.current.click();
    };

    /**
     * Handles adding of files to a folder via drag/drop.
     * 
     * @param {event} event - Event object.
     */
    const handleFilesAdd = async (event) => {

        if (selectedFolder) {
            showProgress(`Adding files to ${selectedFolder.title}...`);
        } else if (selectedRoom) {
            showProgress(`Adding files to ${selectedRoom.title}...`);
        } else {
            showProgress(`Adding files...`);
        }

        const folderPath = `profiles/${profile.key}/files/${getStringPathFromKeys(profileFolders, pathKeys)}`;

        console.log("calling handleFileDrop", selectedRoom);

        // Handle the drop of all the files
        await folderManager.handleFileDrop(event, folderPath, selectedRoom, selectedFolder, profile);

        fileInputRef.current.value = "";

        hideProgress();
    };

    // Open Add Menu
    const handleOpenAddMenu = () => {
    };

    // Room
    const handleAddRoomClick = () => {
        setAddRoomOpen(true);
    };

    // Folder
    const handleAddFolderClick = () => {
        setAddFolderOpen(true);
    };

    // Note
    const handleAddNoteClick = () => {
        setSelectedNote(null);
        setAddNoteOpen(true);
    };

    // Event
    const handleAddEventClick = () => {
        setEventVisible(true);
    };

    // Object
    const handleAddObjectClick = (model) => {
        setSelectedModel(model);
        setAddObjectOpen(true);
    };

    // Search Existing
    const handleSearchClick = () => {
        //setAddSearchOpen(true);
    };

    // Verify profile
    if (!profile) return null;

    return (
        <>
            {/* CONTAINER */}
            <div className="menu-bar-container">

                {/* TOP */}
                <div className="menu-bar-top"
                    style={
                        isMobile
                            ? {
                                overflowY: "scroll",
                                msOverflowStyle: "none", // IE and Edge
                                scrollbarWidth: "none"  // Firefox
                            }
                            : {}
                    }>

                    {/* PROFILE */}
                    <div className="menu-bar-dot-wrapper"
                        onClick={handleProfileClick}>
                        <ProfileDot
                            item={profile}
                            size={40}
                        />
                    </div>

                    {/* HOME */}
                    <MenuBarButton
                        title={t('MenuBar:button_home')}
                        icon={HomeIcon}
                        selectedIcon={HomeFilledIcon}
                        selected={currentSection === SectionType.HOME}
                        onClick={handleHomeClick}
                    />

                    {/* FRIENDS */}
                    <MenuBarButton
                        title={t('MenuBar:button_friends')}
                        icon={FriendsIcon}
                        selectedIcon={FriendsFilledIcon}
                        selected={currentSection === SectionType.FRIENDS}
                        onClick={handleFriendsClick}
                    />

                    {/* INBOX */}
                    <MenuBarButton
                        title={t('MenuBar:button_inbox')}
                        icon={InboxIcon}
                        selectedIcon={InboxFilledIcon}
                        selected={currentSection === SectionType.INBOX}
                        onClick={handleInboxClick}
                        count={2300}
                    />

                    {/* ROOMS */}
                    <MenuBarButton
                        title={t('MenuBar:button_rooms')}
                        icon={RoomsIcon}
                        selectedIcon={RoomsFilledIcon}
                        selected={currentSection === SectionType.ROOMS}
                        onClick={handleRoomsClick}
                        count={18}
                    />

                    {/* CALENDAR */}
                    <MenuBarButton
                        title={t('MenuBar:button_calendar')}
                        icon={CalendarIcon}
                        selectedIcon={CalendarFilledIcon}
                        selected={currentSection === SectionType.CALENDAR}
                        onClick={handleCalendarClick}
                    />

                    {/* FOLDERS */}
                    <MenuBarButton
                        title={t('MenuBar:button_folders')}
                        icon={FolderIcon}
                        selectedIcon={FolderFilledIcon}
                        selected={currentSection === SectionType.FOLDERS}
                        onClick={handleFoldersClick}
                    />

                </div>

                {/* BOTTOM */}
                <div className="menu-bar-bottom">

                    {/* ADD BUTTON */}
                    <div className="menu-bar-add-button">
                        <FolderAddButton
                            onOpen={handleOpenAddMenu}
                            onSearch={handleSearchClick}
                            onAddRoom={handleAddRoomClick}
                            onAddFolder={handleAddFolderClick}
                            onAddFiles={handleAddFilesClick}
                            onAddEvent={handleAddEventClick}
                            onAddNote={handleAddNoteClick}
                            onAddObject={handleAddObjectClick}
                        />
                    </div>

                    {/* SEARCH */}
                    <MenuBarButton
                        title={t('MenuBar:button_search')}
                        icon={SearchIcon}
                        onClick={() => console.log("Search")}
                    />

                    {/* TRASH */}
                    <div
                        onDrop={handleDrop}
                        onDragOver={(event) => {
                            event.preventDefault(); // Allow dropping
                        }}>
                        <MenuBarButton
                            title={t('MenuBar:button_trash')}
                            icon={TrashIcon}
                            selectedIcon={TrashFilledIcon}
                            selected={currentSection === SectionType.TRASH}
                            onClick={handleTrashClick}
                            count={trashedItems.length}
                        />
                    </div>

                    {/* DARK MODE */}
                    <MenuBarButton
                        title={t('MenuBar:button_darkmode')}
                        icon={DarkModeIcon}
                        onClick={handleDarkModeClick}
                    />

                    {/* STUDIO */}
                    <MenuBarButton
                        title={t('MenuBar:button_database')}
                        icon={DataIcon}
                        selectedIcon={DataFilledIcon}
                        selected={currentSection === SectionType.STUDIO}
                        onClick={handleDatabaseClick}
                    />

                    {/* SETTINGS */}
                    <MenuBarButton
                        title={t('MenuBar:button_settings')}
                        icon={SettingsIcon}
                        selectedIcon={SettingsFilledIcon}
                        selected={currentSection === SectionType.SETTINGS}
                        onClick={handleSettingsClick}
                    />

                    {/* SIGN OUT */}
                    <MenuBarButton
                        title={t('MenuBar:button_signout')}
                        icon={LogoutIcon}
                        selectedIcon={LogoutIcon}
                        onClick={handleLogoutClick}
                    />

                </div>

            </div>

            {/* ROOM ADD */}
            <RoomAdd
                isOpen={addRoomOpen}
                setOpen={setAddRoomOpen}
                folderKey={selectedFolder && selectedFolder.key || null}
            />

            {/* FOLDER ADD */}
            <FolderAdd
                isOpen={addFolderOpen}
                setOpen={setAddFolderOpen}
                folderKey={selectedFolder && selectedFolder.key || null}
            />

            {/* FILE ADD */}
            <input
                ref={fileInputRef}
                id="file-upload"
                type="file"
                accept="image/*, video/*, .pdf, .doc, .docx, .txt, .csv, .xls, .xlsx, .ppt, .pptx, .zip, .mp3, .wav"
                onChange={(event) => handleFilesAdd(event)}
                style={{ display: 'none' }}
                aria-label="File Upload"
                multiple
            />

            {/* NOTE FORM */}
            <NoteForm
                isOpen={addNoteOpen}
                setOpen={setAddNoteOpen}
            />

            {/* EVENT FORM */}
            <EventForm
                date={new Date()}
                folderKey={selectedFolder ? selectedFolder.key : null}
            />

            {/* ADD OBJECT */}
            <ObjectAdd
                isOpen={addObjectOpen}
                setOpen={setAddObjectOpen}
            />

        </>
    );
};

export default MenuBar;
