import React, { useCallback, useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { ItemType } from '../../../common/utilities/Enums';
import { generateKey } from '../../../common/utilities/Keys';
import { uploadPhotoGallery, uploadVideoGallery } from '../../../common/utilities/Media';

// Styles
import './ObjectAdd.css';

// Components
import FormField from '../../form/FormField';
import Modal from '../../../common/components/modal/Modal';
import ModalButton from '../../../common/components/modal/buttons/ModalButton';
import StatusMessage from '../../../common/components/statusmessage/StatusMessage';
import TagSelector from '../../../common/components/tagselector/TagSelector';

// Managers
import ObjectManager from '../../../common/managers/ObjectManager';

const objectManager = new ObjectManager();

/**
 * ObjectAdd Component
 * 
 * This component allows the user to add an object.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ObjectAdd = ({ isOpen, setOpen }) => {

    // Global
    const {
        profile,
        currentUser,
        formFields,
        hideProgress,
        selectedFolder,
        selectedModel,
        selectedObject,
        selectedRoom,
        setChangesMade,
        setFormFields,
        setSelectedObject,
        showProgress,
        profileModels,
        profileFields,
    } = useContext(Global);

    // Initialize fields as an array
    const [fields, setFields] = useState([]);

    // Status message
    const [statusMessage, setStatusMessage] = useState('');
    const [showStatusMessage, setShowStatusMessage] = useState(false);

    // Local State
    const [currentObject, setCurrentObject] = useState({}); // Default ADD object

    // Object tags (for ADD mode)
    const [selectedTags, setSelectedTags] = useState([]);

    // Determine the model's fields and sort them
    useEffect(() => {
        if (!selectedModel) return;

        // Filter fields for the current model
        const filtered = profileFields.filter(f => f.modelKey === selectedModel.key);

        // Try to sort fields according to the order in selectedModel.fieldSort
        if (selectedModel.fieldSort && Array.isArray(selectedModel.fieldSort) &&
            selectedModel.fieldSort !== undefined) {
            const sorted = filtered.sort((a, b) => {
                const indexA = selectedModel.fieldSort.indexOf(a.key);
                const indexB = selectedModel.fieldSort.indexOf(b.key);

                // Fields not found in fieldSort will appear at the end
                return indexA - indexB;
            });

            setFields(sorted);
            setFormFields(sorted);
        } else {
            setFields(filtered);
            setFormFields(filtered);
        }
    }, [selectedModel, profileFields, setFormFields]);

    // Update the field's value in currentObject
    const handleUpdate = useCallback((field, value) => {
        setChangesMade(true);
        setShowStatusMessage(false);
        setCurrentObject(prev => ({ ...prev, [field.key]: value }));
    }, [setChangesMade]);

    /**
     * Adds a new object record.
     */
    const handleAdd = async (close) => {

        // Validate the title field
        if (!validateTitleField(
            currentObject,
            fields,
            selectedModel
        )) return;

        showProgress("Adding...");

        const now = Timestamp.now();

        const key = generateKey();

        // Handle special fields
        await prepareSpecialFields(key); // Use the new key in an add

        const data = {
            ...currentObject,
            key: key,
            profileKey: profile.key,
            modelKey: selectedModel.key,
            roomKey: selectedRoom && selectedRoom.key || null,
            folderKey: selectedFolder && selectedFolder.key || null,
            type: ItemType.OBJECT,
            tags: selectedTags,
            dateCreated: now,
            dateModified: now,
        };

        await objectManager.add(
            profile.key,
            selectedModel.key,
            profileModels,
            key,
            data
        );

        setCurrentObject(data);
        setSelectedObject(data);

        if (close) {
            setOpen(false);
        }

        setStatusMessage("Record added successfully.");

        hideProgress();
    };

    /**
     * Validates that the title field is non-empty.
     */
    const validateTitleField = (obj, fields, collection) => {
        let title = obj[collection.titleFieldKey]
        if (!title || title.length === 0) {
            const field = fields.find(f => f.key === collection.titleFieldKey);
            alert(`Please enter a ${field.title}.`);
            return false;
        }
        return true;
    }

    /**
    * Prepares special fields for saving or adding.
    */
    const prepareSpecialFields = async (objectKey) => {
        for (const field of formFields) {
            // Handle gallery fields
            if (field.type === 'gallery') {
                await savePhotoGallery(field, objectKey);
            }

            // Handle videogallery fields
            if (field.type === 'videogallery') {
                await saveVideoGallery(field, objectKey);
            }
        }
    };

    /**
    * Saves photo gallery and uploads files if necessary.
    */
    const savePhotoGallery = async (field, objectKey) => {

        const galleryItems = currentObject[field.key] || [];

        const folderPath = `galleries/${profile.key}/${selectedModel.key}/${field.key}/${objectKey}/`;

        const uploadedImages = await uploadPhotoGallery(profile.key, folderPath, galleryItems);

        // Update the currentObject with the new URLs and captions
        currentObject[field.key] = uploadedImages;
    };

    /**
     * Saves video gallery data and uploads files if necessary.
     */
    const saveVideoGallery = async (field, objectKey) => {

        const galleryItems = currentObject[field.key] || [];

        const folderPath = `videogalleries/${profile.key}/${selectedModel.key}/${field.key}/${objectKey}/`;

        const uploadedGallery = await uploadVideoGallery(profile.key, folderPath, galleryItems);

        // Update the currentObject with the new URLs
        currentObject[field.key] = uploadedGallery;
    };

    if (!selectedModel) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title={`Add ${selectedModel.title} Item`}
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={isMobile ? '100%' : '500px'}
                height={isMobile ? '100%' : '90%'}>

                <div className="object-add-container">

                    {selectedModel && selectedModel.tags && selectedModel.tags.length > 0 &&
                        <div className="object-add-tag-selector-wrapper">
                            <TagSelector
                                tags={selectedModel.tags || []}
                                selectedTags={selectedTags}
                                onTagClick={setSelectedTags}
                                readonly={false}
                            />
                        </div>
                    }

                    <div className="object-add-scroller">

                        <StatusMessage
                            message={statusMessage}
                            visible={showStatusMessage}
                            setVisible={setShowStatusMessage}
                        />

                        {fields.map((field) => (
                            <FormField
                                object={selectedObject}
                                key={field.id}
                                field={field}
                                onUpdate={handleUpdate}
                            />
                        ))}

                    </div>

                </div>

                {/* BUTTONS */}
                <div className="object-add-buttons"
                    style={{
                        marginBottom: isMobile ? '60px' : '0px'
                    }}>

                    <ModalButton
                        onClick={() => handleAdd(true)}
                        label="Continue..."
                    />

                </div>

            </Modal>
        </>
    );
};

export default ObjectAdd;
