import React from 'react';

const CutIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="m775.38-138.46-292.3-292.31L358.31-306q8.77 15 12.92 31.62 4.15 16.61 4.15 34.38 0 54.58-38.84 93.44-38.84 38.87-93.38 38.87-54.54 0-93.47-38.84-38.92-38.85-38.92-93.39t38.86-93.46q38.87-38.93 93.45-38.93 17.77 0 34.38 4.16 16.62 4.15 31.62 12.92L433.85-480 309.08-604.77q-15 8.77-31.62 12.92-16.61 4.16-34.38 4.16-54.58 0-93.45-38.84-38.86-38.85-38.86-93.39t38.84-93.46q38.84-38.93 93.39-38.93 54.54 0 93.46 38.87 38.92 38.86 38.92 93.44 0 17.77-4.15 34.38Q367.08-669 358.31-654l490.92 490.92v24.62h-73.85ZM584.62-532.31l-49.24-49.23 240-240h73.85v24.62L584.62-532.31Zm-341.54-95.38q38.07 0 65.19-27.12 27.11-27.11 27.11-65.19t-27.11-65.19q-27.12-27.12-65.19-27.12-38.08 0-65.2 27.12-27.11 27.11-27.11 65.19t27.11 65.19q27.12 27.12 65.2 27.12Zm240 147.69Zm-240 332.31q38.07 0 65.19-27.12 27.11-27.11 27.11-65.19t-27.11-65.19q-27.12-27.12-65.19-27.12-38.08 0-65.2 27.12-27.11 27.11-27.11 65.19t27.11 65.19q27.12 27.12 65.2 27.12Z" />
  </svg>

);

export default CutIcon;
