import React from 'react';

// Utilities
import { ItemSize, ItemType } from '../../../utilities/Enums.js';

// Styles
import './Item.css';

// Components
import EventGridItem from './event/grid/EventGridItem.js';
import FileGridItem from './file/FileGridItem.js';
import NoteGridItem from './note/NoteGridItem.js';
import FolderGridItem from './folder/FolderGridItem.js';
import ObjectGridItem from './object/ObjectGridItem.js';
import PhotoGridItem from './photo/PhotoGridItem.js';
import RoomGridItem from './room/RoomGridItem.js';

import EventFeedItem from './event/feed/EventFeedItem.js';
//import FileFeedItem from './file/FileFeedItem.js';
//import NoteFeedItem from './note/NoteFeedItem.js';
//import FolderFeedItem from './folder/FolderFeedItem.js';
//import ObjectFeedItem from './object/ObjectFeedItem.js';
import PhotoFeedItem from './photo/PhotoFeedItem.js';
//import RoomFeedItem from './room/RoomFeedItem.js';

const COMPONENTS_GRID = {
    [ItemType.FOLDER]: FolderGridItem,
    [ItemType.FILE]: FileGridItem,
    [ItemType.PHOTO]: PhotoGridItem,
    [ItemType.VIDEO]: FileGridItem,
    [ItemType.EVENT]: EventGridItem,
    [ItemType.ROOM]: RoomGridItem,
    [ItemType.NOTE]: NoteGridItem,
    [ItemType.OBJECT]: ObjectGridItem,
};

const COMPONENTS_FEED = {
    [ItemType.PHOTO]: PhotoFeedItem,
    [ItemType.EVENT]: EventFeedItem,
};

/**
 * Item
 * 
 * Item that represents a folder, file, event, note, or object.
 * 
 * @param {Object} item - The item to render.
 * @param {Array} pathKeys - The keys of the items in the path.
 * @param {Boolean} selected - Whether the item is selected.
 * @param {Function} onClick - Function to call when the item is clicked.
 * @param {Function} onDoubleClick - Function to call when the item is double-clicked.
 * @param {Function} onDragStart - Function to call when the item is dragged.
 * @param {Function} onDropItem - Function to call when an item is dropped on the item.
 * @param {String} size - The size of the item.
 * 
 * @returns {Component} The folder item.
 */
const Item = ({ item, pathKeys, selected = false, onClick, onDoubleClick, onDragStart, onDropItem, size = ItemSize.SMALL }) => {
    const Component = size === ItemSize.SMALL ? COMPONENTS_GRID[item.type] : 
        COMPONENTS_FEED[item.type] || COMPONENTS_GRID[item.type];

    if (!Component) return null; // Handle undefined item types gracefully

    return (
        <Component
            key={item.key}
            item={item}
            pathKeys={pathKeys}
            selected={selected}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onDragStart={onDragStart}
            onDropItem={item.type === ItemType.FOLDER ? onDropItem : undefined} // Only pass onDropItem for folders
            size={size}
        />
    );
};

export default Item;
