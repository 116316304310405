import React from 'react';

// Styles
import './ToolbarIcon.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ToolbarIcon = ({ icon: IconComponent }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="toolbar-icon-container">

            <IconComponent
                color={theme.highlightBackgroundColor}
                width="28"
                height="28"
            />

        </div>

    );
};

export default ToolbarIcon;
