import React from 'react';

// Styles
import './RoomsToolbar.css';

// Images
import RoomsFilledIcon from '../../../svg/RoomsFilledIcon';

// Components
import { Toolbar, Left, Right } from '../../../components/toolbar/Toolbar';
import ToolbarIcon from '../../../components/toolbar/icon/ToolbarIcon';
import ToolbarTitle from '../../../components/toolbar/title/ToolbarTitle';

const RoomsToolbar = () => {

    return (
        <Toolbar>

            <Left>

                {/* ICON */}
                <ToolbarIcon 
                    icon={RoomsFilledIcon} 
                />

                {/* TITLE */}
                <ToolbarTitle
                    title="Rooms"
                />

            </Left>
            <Right>

            </Right>

        </Toolbar>
    );
};

export default RoomsToolbar;
