import React, { useContext, useState, forwardRef } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './NoteAddBlockButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import ImageIcon from '../../svg/ImageIcon';
import PlusIcon from '../../svg/PlusIcon';
import VideoIcon from '../../svg/VideoIcon';
import DocumentIcon from '../../svg/DocumentIcon';

// Buttons
import EventButton from '../components/buttons/events/EventButton';
import FileButton from '../components/buttons/files/FileButton';
import LinkButton from '../components/buttons/links/LinkButton';
import ObjectButton from '../components/buttons/objects/ObjectButton';
import TextButton from '../components/buttons/text/TextButton';

// Managers
import ObjectManager from '../../managers/ObjectManager';

const objectManager = new ObjectManager();

// Use forwardRef to forward the ref to the div element
const NoteAddBlockButton = forwardRef(({ index, onSelect }, ref) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    // Local State
    const [panelVisible, setPanelVisible] = useState(false);

    /** 
     * Handles selections within buttons.
     */
    const handleSelect = (type, content) => {
        setPanelVisible(false);
        onSelect(index + 1, type, content);
    };

    /** 
     * Handles the object search result.
     */
    const handleSearch = async (entry) => {
        setPanelVisible(false);
        const content = await objectManager.fetch(profile.key, entry.objectKey);
        onSelect(index + 1, "OBJECT", content);
    };

    /** 
     * Handles the addition of an event.
     */
    const handleAddEvent = async (event) => {
        setPanelVisible(false);
        onSelect(index + 1, "EVENT", event);
    };

    /** 
     * Handles the addition of a link.
     */
    const handleAddLink = async (link) => {
        setPanelVisible(false);
        onSelect(index + 1, "LINK", link);
    };

    /**
     * Handles the click event on the plus icon.
     * 
     * @param {event} e - The event object.
     */
    const handlePlusClick = (e) => {
        e.stopPropagation();
        setPanelVisible(!panelVisible);
    };

    return (
        <>
            {/* CONTAINER */}
            <div ref={ref}
                className="add-block-button-container"
                style={{
                    borderColor: "transparent"
                }}>

                {/* PLUS ICON */}
                <div className="note-form-plus-button"
                    style={{
                        borderColor: theme.backgroundColorFaded
                    }}
                    onClick={handlePlusClick}>
                    <PlusIcon
                        color={theme.foregroundColorFaded}
                        width="14"
                        height="14"
                    />
                </div>

                {/* BUTTON PANEL */}
                {panelVisible &&

                    <div className="note-form-add-panel-container">

                        {/* TEXT */}
                        <TextButton
                            onClick={() => handleSelect("TEXT", '')}
                        />

                        {/* PHOTOS */}
                        <FileButton
                            type="PHOTOS"
                            accept="image/*"
                            icon={ImageIcon}
                            onSelect={handleSelect}
                        />

                        {/* VIDEOS */}
                        <FileButton
                            type="VIDEOS"
                            accept="video/*"
                            icon={VideoIcon}
                            onSelect={handleSelect}
                        />

                        {/* DOCUMENTS */}
                        <FileButton
                            type="DOCUMENTS"
                            accept=".pdf,.doc,.docx,.txt,.ppt,.pptx,.xls,.xlsx,.csv,.rtf,.html,.zip"
                            icon={DocumentIcon}
                            onSelect={handleSelect}
                            multiple={false}
                        />

                        {/* OBJECTS */}
                        <ObjectButton
                            onSearch={handleSearch}
                        />

                        {/* EVENTS */}
                        <EventButton
                            onAddEvent={handleAddEvent}
                        />

                        {/* LINK */}
                        <LinkButton
                            onSelect={handleAddLink}
                        />

                    </div>
                }

            </div>
        </>
    );
});

export default NoteAddBlockButton;
