// Firebase
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

class ProfileManager {

    /**
      * Fetches a profile by key.
      * 
      * @param {string} key - Key of the profile to retrieve.
      */
    async getProfile(key) {
        try {
            const ref = doc(db, collections.profiles, key);

            const docSnap = await getDoc(ref);

            if (docSnap.exists()) {
                return {
                    id: docSnap.id,
                    ...docSnap.data()
                };
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching profile:", error);
            throw error;
        }
    }

    /**
     * Determines if a user with the email already exists. Returns the user
     * if true, or null if no user with the email exists.
     * 
     * @param {string} email - User email to check for.
     * 
     * @returns {Promise<Object|null>} A promise that resolves to the user object if found, or null if not found.
    */
    async getProfileWithEmail(email) {
        try {
            const usersCollection = collection(db, collections.profiles);
            const q = query(usersCollection, where("email", "==", email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error;
        }
    }

    /**
     * Determines if a user with the phone number already exists. Returns the user
     * if true, or null if no user with the phone exists.
     * 
     * @param {string} phone - User phone to check for.
     * @returns {Promise<Object|null>} A promise that resolves to the user object if found, or null if not found.
    */
    async getProfileWithPhone(phone) {
        try {
            const usersCollection = collection(db, collections.users);
            const q = query(usersCollection, where("phone", "==", phone));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one user with this phone
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error;
        }
    }

    /**
     * Determines if a user with the username already exists. Returns the user
     * if true, or null if no user with the username exists.
     * 
     * @param {string} username - User username to check for.
     * @returns {Promise<Object|null>} A promise that resolves to the user object if found, or null if not found.
    */
    async getProfileWithUsername(username) {
        try {
            const usersCollection = collection(db, collections.users);
            const q = query(usersCollection, where("username", "==", username));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one user with this username
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error;
        }
    }

    /**
     * Determines if a user with the email already exists.
     * 
     * @param {string} email - User email to check for.
     * 
     * @returns {boolean} Whether the email exists.
    */
    async emailExists(email) {
        const user = await this.getProfileWithEmail(email);
        return user !== null;
    }

    /**
     * Determines if a user with the username already exists.
     * 
     * @param {string} username - Username to check for.
     * 
     * @returns {boolean} Whether the username exists.
    */
    async usernameExists(username) {
        const user = await this.getProfileWithUsername(username);
        return user !== null;
    }

    /**
     * Determines if a user with the handle already exists. Returns the user
     * if true, or null if no user with the handle exists.
     * 
     * @param {string} handle - Handle to check for.
     * @returns {Promise<Object|null>} A promise that resolves to the user object if found, or null if not found.
    */
    async getProfileWithHandle(handle) {
        try {
            const usersCollection = collection(db, collections.users);
            const q = query(usersCollection, where("handle", "==", handle));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one user with this email
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error;
        }
    }

    /**
     * Determines if a user with the handle already exists.
     * 
     * @param {string} handle - Handle to check for.
     * 
     * @returns {boolean} Whether the handle exists.
    */
    async handleExists(handle) {
        const user = await this.getProfileWithHandle(handle);
        return user !== null;
    }

    async fetchCurrentUser(authUser) {
        if (!authUser) return null;

        try {
            const userData = await this.getProfile(authUser.uid);
            if (userData) {
                return {
                    key: authUser.uid,
                    email: authUser.email,
                    displayName: authUser.displayName,
                    emailVerified: authUser.emailVerified,
                    phoneNumber: authUser.phoneNumber,
                    userKey: authUser.uid, // Inject the user key to make sure it's available
                    ...userData
                };
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error combining user data:", error);
            throw error;
        }
    }

    /**
     * Updates a user's information.
     * 
     * @param {string} userKey - Key of the object being updated.
     * @param {string} data - Data to update.
    */
    async update(userKey, data) {
        const userRef = doc(db, collections.users, userKey);
        try {
            await updateDoc(userRef, data);
        } catch (error) {
            console.error("Error updating current user: ", error);
            alert('Error updating current user.');
        }
    }

    /**
     * Updates a user's preference for an app.
     * 
     * @param {Object} user - Current user object.
     * @param {string} profileKey - Selected app key.
     * @param {string} preferenceKey - Key for the specific preference to update.
     * @param {string} value - Value to save.
     * @param {Function} setCurrentUser - Function to update the current user state.
     */
    async setPreference(user, setCurrentUser, profileKey, preferenceKey, value) {
        // Prepare updated preferences
        const updatedPreferences = {
            ...user.preferences,
            [profileKey]: {
                ...((user.preferences && user.preferences[profileKey]) || {}),
                [preferenceKey]: value  // Dynamically assign the preference key
            }
        };

        // Prepare data with updated preferences
        const data = { preferences: updatedPreferences };

        // Update the user's information in the database
        await this.update(user.userKey, data);

        // After updating the database, update the local currentUser state
        setCurrentUser(prevUser => ({
            ...prevUser,
            preferences: updatedPreferences  // Update preferences in state
        }));
    }

    /**
     * Retrieves a user's preference for an app.
     * 
     * @param {Object} user - Current user object.
     * @param {string} profileKey - Selected app key.
     * @param {string} preferenceKey - Key for the specific preference to retrieve.
     * @returns {any} The value of the preference, or undefined if not set.
     */
    getPreference(user, profileKey, preferenceKey) {
        // Safely access the preference value, or return undefined if it doesn’t exist
        return user?.preferences?.[profileKey]?.[preferenceKey];
    }

    /**
     * Searches for a profile by first/last name.
     * 
     * @param {string} searchTerm - Query string to search for in the first name or last name fields.
     * 
     * @returns {Array} - Filtered array of objects matching the query.
     */
    async search(searchTerm) {
        if (!searchTerm) return []; // Return empty array if query is empty

        try {
            // Create a query to search for lastName starting with the query
            const q = query(
                collection(db, collections.profiles),
                where('indexField', '>=', searchTerm),
                where('indexField', '<=', searchTerm + '\uf8ff')
            );

            // Execute the query
            const querySnapshot = await getDocs(q);

            // Extract and return the results
            const results = querySnapshot.docs.map((doc) => doc.data());
            return results;
        } catch (error) {
            console.error('Error searching profiles:', error);
            return [];
        }
    }

}

export default ProfileManager;
