import React from 'react';

// Utilities
import { ItemSize } from '../../../../../common/utilities/Enums';

// Styles
import './PhotoGridItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import GridItem from '../../../../components/grid/griditem/GridItem';

/**
 * PhotoGridItem
 * 
 * A grid item for displaying files.
 * 
 * @param {Object} item The file item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} PhotoGridItem component.
 */
const PhotoGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart, size = ItemSize.SMALL }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <GridItem
            selected={selected}
            shared={item.shared}
            trashed={item.trashed}
            onClick={(event) => {
                if (onClick) onClick(event, item); 
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(event) => {
                if (onDragStart)  onDragStart(event, item);
            }}
            size={size}>

            <div className="photo-grid-item-container"
                title={item.title}>

                {/* PREVIEW */}
                <div className="photo-grid-item-image-wrapper">
                    <img
                        src={item.url}
                        alt={item.title}
                        className="photo-grid-item-image"
                    />
                </div>

            </div>

        </GridItem>
    );
};

export default PhotoGridItem;
