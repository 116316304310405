import React from 'react';

// Styles
import './Section.css';

const Section = ({ children }) => {

    return (

        <div className="section-container">
            {children}
        </div>

    );
};

export default Section;
