import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './EventLocation.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const EventLocation = ({
  location,
  setLocation
}) => {
  const { theme } = useTheme();
  const { 
    profile,
    selectedEvent 
  } = useContext(Global);

  // References
  const locationRef = useRef(null);

  useEffect(() => {
    // Focus the input on load if adding an event
    if (locationRef.current && !selectedEvent) {
      locationRef.current.focus();
    }
  }, [location, selectedEvent]);

  return (
    <>
      <input
        type="text"
        ref={locationRef}
        className="event-location-container"
        placeholder="Add Location"
        style={{
          backgroundColor: theme.backgroundColorFaded,
          color: theme.foregroundColor,
          fontFamily: profile.fontFamily
        }}
        value={location}
        onChange={(e) => setLocation(e.target.value)}
      />
    </>
  );
};

export default EventLocation;
