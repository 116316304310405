import React, { createContext } from 'react';

// Styles
import './Toolbar.css';

const SideContext = createContext();

/**
 * Toolbar Component
 * 
 * Renders a section toolbar with left and right sections.
 * 
 * Usage:
 * 
 * <Toolbar>
 *    <Left>
 *      <button>Left Button 1</button>
 *      <button>Left Button 2</button>
 *    </Left>
 *    <Right>
 *      <button>Right Button 1</button>
 *      <button>Right Button 2</button>
 *    </Right>
 *  </Toolbar>
 * 
 * @param ({ children }) - The left/right children of the toolbar.
 */
const Toolbar = ({ children }) => {

    const leftChildren = React.Children.toArray(children).filter(
        (child) => child.type === Left
    );
    const rightChildren = React.Children.toArray(children).filter(
        (child) => child.type === Right
    );

    return (
        <div className="toolbar-container">
            <div className="toolbar-left">
                {leftChildren}
            </div>
            <div className="toolbar-right">
                {rightChildren}
            </div>
        </div>
    );
};

const Left = ({ children }) => {
    return <SideContext.Provider value="left">{children}</SideContext.Provider>;
};

const Right = ({ children }) => {
    return <SideContext.Provider value="right">{children}</SideContext.Provider>;
};

export { Toolbar, Left, Right };