import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Firebase
import { collections } from '../../../../../../firebaseConfig';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './FileEdit.css';

// Components
import Modal from '../../../../../components/modal/Modal';
import ModalButton from '../../../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../../../components/modal/buttons/ModalButtons';
import ModalInput from '../../../../../components/modal/input/ModalInput';
import ModalLabel from '../../../../../components/modal/label/ModalLabel';
import ModalSection from '../../../../../components/modal/section/ModalSection';
import ModalTextarea from '../../../../../components/modal/textarea/ModalTextarea';

// Managers
import DataManager from '../../../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * FileEdit Component
 * 
 * This component allows the user to edit a item.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const FileEdit = ({ item, isOpen, setOpen }) => {

    // Global
    const {
        profile,
    } = useContext(Global);

    // Local State
    const [description, setDescription] = useState('');

    // Load the item attributes
    useEffect(() => {
        if (!item) return;
        setDescription(item.description || '');
    }, [item]);

    const handleSave = async () => {
        const data = {
            description: description,
        };

        await dataManager.update(
            collections.items,
            profile.key,
            item.key,
            data
        );

        setOpen(false);
    };

    if (!item) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title="File Settings"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={isMobile ? "100%" : "330px"}
                height={isMobile ? "100%" : "auto"}>

                {/* CAPTION */}
                <ModalSection>

                    <ModalLabel text="Caption" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="5"
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* SAVE */}
                    <ModalButton
                        onClick={handleSave}
                        label="Save"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default FileEdit;
