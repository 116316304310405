import React from 'react';

const VideoIcon = ({ color = "white", width = "24", height = "24" }) => (

    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M203.85-200q-27.62 0-46.12-18.5-18.5-18.5-18.5-46.12v-430.76q0-27.62 18.5-46.12 18.5-18.5 46.12-18.5h430.77q27.61 0 46.11 18.5t18.5 46.12v186.15l121.54-121.54v301.54L699.23-450.77v186.15q0 27.62-18.5 46.12-18.5 18.5-46.11 18.5H203.85Zm0-40h430.77q10.76 0 17.69-6.92 6.92-6.93 6.92-17.7v-430.76q0-10.77-6.92-17.7-6.93-6.92-17.69-6.92H203.85q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v430.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92Zm-24.62 0v-480 480Z" />
    </svg>

);

export default VideoIcon;
