import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Firebase
import { collections } from '../../../../../../firebaseConfig';

// Managers
import { activity } from '../../../../../managers/ActivityManager';

// Styles
import './FieldDelete.css';

// Components
import Modal from '../../../../../components/modal/Modal';
import ModalButton from '../../../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../../../components/modal/buttons/ModalButtons';
import ModalMessage from '../../../../../components/modal/message/ModalMessage';

// Managers
import DataManager from '../../../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * FieldDelete Component
 * 
 * This component confirms a delete prior to deleting the field.
 * 
 * @param {object} field - The field object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const FieldDelete = ({ isOpen, setOpen }) => {

    // Global
    const {
        hideProgress,
        profileFields,
        profile,
        selectedFormField,
        setFormFields,
        setSelectedFormField,
        showProgress
    } = useContext(Global);

    /** 
     * Deletes the field.
     */
    const handleDelete = async () => {

        showProgress("Deleting field...");

        try {
            const fieldKey = selectedFormField.key;

            await dataManager.delete(
                collections.fields, 
                profile.key, 
                fieldKey
            );

            // Update local fields state to delete the deleted field
            const updatedFields = profileFields.filter(item => item.key !== fieldKey);

            setFormFields(updatedFields);

            setSelectedFormField(null);

            // Log the activity
            activity.log(profile.key, 'deletes', 1);

        } catch (error) {
            console.error("Error deleting field: ", error);
        }

        setOpen(false);

        hideProgress();
    };

    // Verify the field exists
    if (!selectedFormField) return null;

    return (
        <>
            {/* MODAL */}
            <Modal 
                title={`Delete "${selectedFormField.title}" Field`} 
                width={400} 
                isOpen={isOpen} 
                onClose={() => setOpen(false)}>

                {/* CONFIRM MESSAGE */}
                <ModalMessage text={`This will permanently delete all data in the "${selectedFormField.title}" field. Continue?`} />

                {/* BUTTONS */}
                <ModalButtons>

                    {/* DELETE BUTTON */}
                    <ModalButton
                        onClick={handleDelete}
                        label="Delete"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default FieldDelete;
