import React, { useCallback, useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './RoomListElements.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DotIcon from '../../../../common/svg/DotIcon';

// Components
//import Element from '../element/Element';
import RoomListElement from '../listelement/RoomListElement';
import RoomListGroup from '../listgroup/RoomListGroup';

const RoomListElements = ({ useDrag }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
  } = useContext(Global);

  const elements = [
    { key: 'CHAT', type: 'CHAT', icon: DotIcon, title: 'Chat', description: 'Chat area for room participants.' },
    { key: 'FOLDER', type: 'FOLDER', icon: DotIcon, title: 'Files', description: 'Shared files within the room.' },
    { key: 'PEOPLE', type: 'PEOPLE', icon: DotIcon, title: 'Participants', description: 'Participants in the room.' },
  ];

  return (
    <>
      {/* CONTAINER */}
      <div className="room-list-elements-container"
        style={{
          borderRightColor: theme.backgroundColorFaded,
        }}>

        {/* SCROLLER */}
        <div className="room-list-elements-scroller">

          {/* COLLECTIONS */}
          <RoomListGroup title="Elements">

            {elements.map((element, index) => (

              <RoomListElement
                key={index}
                element={element}
                icon={element.icon}
                title={element.title}
                useDrag={useDrag}
              />

            ))}

          </RoomListGroup>

        </div>

      </div>

    </>
  );

};

export default RoomListElements;
