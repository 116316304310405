import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Translation
import { useTranslation } from 'react-i18next';

// Styles
import './FolderAddButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CalendarIcon from '../../../svg/CalendarIcon';
import DataIcon from '../../../svg/DataIcon';
import FilesIcon from '../../../svg/FilesIcon';
import FolderIcon from '../../../svg/FolderIcon';
import ForumIcon from '../../../svg/ForumIcon';
import SearchIcon from '../../../svg/SearchIcon';
import NoteIcon from '../../../svg/NoteIcon';

// Components
import AddButton from '../../addbutton/AddButton';
import ContextMenuGroup from '../../contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../contextmenu/ContextMenuPopup';

const FolderAddButton = ({ onOpen, onSearch, onAddRoom, onAddFolder, onAddFiles, onAddEvent, onAddObject, onAddNote }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profileModels,
        selectedRoom
    } = useContext(Global);

    // Local State
    const [addMenuVisible, setAddMenuVisible] = useState(false);

    // Translation
    const { t } = useTranslation('Folder');

    /**
     * Handles click on the add button to toggle the menu.
     */
    const handleAddClick = () => {
        setAddMenuVisible(!addMenuVisible);
        onOpen();
    };

    const handleSearchClick = () => {
        setAddMenuVisible(false);
        onSearch();
    };

    const handleAddRoomClick = () => {
        setAddMenuVisible(false);
        onAddRoom();
    };

    const handleAddFolderClick = () => {
        setAddMenuVisible(false);
        onAddFolder();
    };

    const handleAddFilesClick = () => {
        setAddMenuVisible(false);
        onAddFiles();
    };

    const handleAddEventClick = () => {
        setAddMenuVisible(false);
        onAddEvent();
    };

    const handleAddObjectClick = (collection) => {
        setAddMenuVisible(false);
        onAddObject(collection);
    };

    const handleAddNoteClick = () => {
        setAddMenuVisible(false);
        onAddNote();
    };

    return (
        <>
            {/* MENU */}
            <ContextMenuPopup
                visible={addMenuVisible}
                setVisible={setAddMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                position={{ left: 70, top: 210 }}
                width={150}>

                {/* NEW */}
                <ContextMenuGroup title={selectedRoom ?
                    t('Folder:button_add_menu_add_to_room') :
                    t('Folder:button_add_menu_add_new')}>

                    {/* ROOM */}
                    {!selectedRoom && (
                        <ContextMenuOption
                            key="ROOM"
                            icon={ForumIcon}
                            text={t('Folder:button_add_menu_room')}
                            onClick={handleAddRoomClick}
                        />
                    )}

                    {/* FOLDER */}
                    {!selectedRoom && (
                        <ContextMenuOption
                            key="FOLDER"
                            icon={FolderIcon}
                            text={t('Folder:button_add_menu_folder')}
                            onClick={handleAddFolderClick}
                        />
                    )}

                    {/* NOTE */}
                    <ContextMenuOption
                        key="NOTE"
                        icon={NoteIcon}
                        text={t('Folder:button_add_menu_note')}
                        onClick={handleAddNoteClick}
                    />

                    {/* FILES */}
                    <ContextMenuOption
                        key="FILES"
                        icon={FilesIcon}
                        text={t('Folder:button_add_menu_files')}
                        onClick={handleAddFilesClick}
                    />

                    {/* EVENT */}
                    <ContextMenuOption
                        key="EVENT"
                        icon={CalendarIcon}
                        text={t('Folder:button_add_menu_event')}
                        onClick={handleAddEventClick}
                    />

                    {/* COLLECTIONS */}
                    {profileModels.map((collection) => (
                        <ContextMenuOption
                            key={collection.key}
                            icon={DataIcon}
                            text={collection.singular || collection.title}
                            onClick={() => handleAddObjectClick(collection)}
                        />
                    ))}

                </ContextMenuGroup>

            </ContextMenuPopup>

            {/* ADD BUTTON */}
            <AddButton
                title={t('Folder:button_add_title')}
                onClick={handleAddClick}
                size={40}
            />

        </>

    );
};

export default FolderAddButton;
