import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

// Firebase
import { collections } from '../../../../../firebaseConfig';

// Managers
import { activity } from '../../../../managers/ActivityManager';

// Styles
import './ModelDelete.css';

// Components
import Modal from '../../../../components/modal/Modal';
import ModalButton from '../../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../../components/modal/buttons/ModalButtons';
import ModalMessage from '../../../../components/modal/message/ModalMessage';

// Managers
import DataManager from '../../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * ModelDelete Component
 * 
 * This component confirms a delete prior to deleting the model.
 * 
 * @param {object} model - The model object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModelDelete = ({ isOpen, setOpen }) => {

    // Global
    const {
        hideProgress,
        profileModels,
        profile,
        selectedModel,
        setFormFields,
        setSelectedModel,
        showProgress
    } = useContext(Global);

    /** 
     * Deletes the model.
     */
    const handleDelete = async () => {

        showProgress("Deleting model...");

        try {
            const modelKey = selectedModel.key;

            await dataManager.delete(
                collections.models, 
                profile.key, 
                modelKey
            );

            // Update local models state to delete the deleted model
            const updatedFields = profileModels.filter(item => item.key !== modelKey);

            setFormFields(updatedFields);

            setSelectedModel(null);

            // Log the activity
            activity.log(profile.key, 'deletes', 1);

        } catch (error) {
            console.error("Error deleting model: ", error);
        }

        setOpen(false);

        hideProgress();
    };

    // Verify the model exists
    if (!selectedModel) return null;

    return (
        <>
            {/* MODAL */}
            <Modal title={`Delete "${selectedModel.title}" Model`} width={400} isOpen={isOpen} onClose={() => setOpen(false)}>

                {/* CONFIRM MESSAGE */}
                <ModalMessage text={`This will permanently delete all data in the "${selectedModel.title}" model. Continue?`} />

                {/* BUTTONS */}
                <ModalButtons>

                    {/* DELETE BUTTON */}
                    <ModalButton
                        onClick={handleDelete}
                        label="Delete"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default ModelDelete;
