import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase } from 'firebase/database';
import { getMessaging, onMessage } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCcEIhzXuyhz57D9frdvoQGBh9UQNtcTOQ",
  authDomain: "appdeckmain.firebaseapp.com",
  projectId: "appdeckmain",
  storageBucket: "appdeckmain.appspot.com",
  messagingSenderId: "430998078358",
  appId: "1:430998078358:web:77bb1153c76826a14ad3b7",
  measurementId: "G-SGHR4WJ2ZV",
  databaseURL: "https://appdeckmain-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Storage
const storage = getStorage(app);

// Initialize Firebase Functions
const functions = getFunctions(app);

// Initialize Firebase Realtime Database
const realTimeDb = getDatabase(app);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

// Register service worker for Firebase Messaging
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js', { scope: '/' })
    .then((registration) => {
      //console.log('Service Worker registered successfully:', registration);

      // Wait for the service worker to be ready
      return navigator.serviceWorker.ready;
    })
    .then((readyRegistration) => {
      //console.log('Service Worker is active and ready:', readyRegistration);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

// Handle foreground messages
onMessage(messaging, async (payload) => {
  console.log('Message received in foreground:', payload);

  // Extract notification details and custom data from the payload
  const { title, body, icon } = payload.notification || {};
  const domain = payload.data?.domain || ''; // Get custom parameter, if provided

  if (title && body) {
    try {
      // Use the active service worker to show the notification
      const registration = await navigator.serviceWorker.ready;

      // Show the notification with the custom data
      registration.showNotification(title, {
        body: body,
        icon: icon || '/icons/chat.png', // Optional icon
        data: { domain }, // Pass custom data for later use
      });
    } catch (error) {
      console.error('Error showing notification:', error);
    }
  } else {
    console.log('Notification payload is missing required fields.');
  }
});

// Centralized collection names
const collections = {
  activity: "_activity",
  fields: "_fields",
  items: "_items",
  models: "_models",
  profiles: "_profiles",


  objects: "_objects",


  calendars: "_calendars",
  channels: "_channels",
  chatposts: "_chatposts",
  comments: "_comments",
  conversations: "_conversations",
  documents: "_documents",
  index: "_index",
  links: "_links",
  messages: "_messages",
  notifications: "_notifications",
  userelements: "_userelements",
  roles: "_roles",
  users: "_users",
};

export { app, auth, db, collections, storage, functions, httpsCallable, realTimeDb, messaging };
