import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { getRelativeTimeShort } from '../../../common/utilities/DateTime';
import { getUserDisplayValue } from '../../../common/utilities/Users';

// Styles
import './Message.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Managers

const Message = ({ message }) => {
    const { theme } = useTheme();
    const {
        profileMembers,
        profile,
        currentUser,
    } = useContext(Global);

    const member = profileMembers.find(member => member.userKey === message.userKey);
    const memberName = getUserDisplayValue(profile.displayKey, member);
    
    return (
        <div className="message-outer-wrapper">

            {/* HEADER */}
            <div className={message.userKey === currentUser.userKey ? 'message-header-right' : 'message-header-left'}
                style={{
                    color: theme.foregroundColorFaded,
                }}>
                {currentUser.userKey !== message.userKey &&
                    <>
                        {memberName}&nbsp;•&nbsp;
                    </>
                }
                {getRelativeTimeShort(message.dateCreated)}
            </div>

            {/* MESSAGE */}
            <div
                className={message.userKey === currentUser.userKey ? 'message-container-right' : 'message-container-left'}
                key={message.key}>

                <div className="message-wrapper"
                    style={{
                        backgroundColor: message.userKey === currentUser.userKey ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                    }}>
                    <div className="message-text"
                        style={{
                            color: message.userKey === currentUser.userKey ? theme.highlightForegroundColor : theme.foregroundColor
                        }}>
                        {message.text}
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Message;
