import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Drag/Drop
import { useDrag } from 'react-dnd';

// Styles
import './FieldSelector.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Components
import FieldSelectorHeader from './FieldSelectorHeader';
import FieldPreview from './FieldPreview';

// Styled Components
const InlineStyles = useStyle`
    .field-selector-field:hover {
        border-color: ${(props) => props.hoverColor} !important;
        border-style: solid !important;
    }
`;

// The item type for new fields that are added to FormDesigner
export const DRAGGABLE_NEW_FIELD_TYPE = 'NEW_FIELD';

const FieldSelector = ({ size = "SMALL", customOptions = [] }) => {
    // Theme
    const { theme } = useTheme();

    // Global
    const {
        fieldFilter,
        profileModels,
        selectedModel,
    } = useContext(Global);

    // Local State
    const [options, setOptions] = useState([]);

    // References
    const prevCustomOptionsRef = useRef();

    useEffect(() => {
        if (!selectedModel) return;
        
        // Rebuild the options if customOptions changed
        if (JSON.stringify(prevCustomOptionsRef.current) !== JSON.stringify(customOptions)) {
            if (customOptions.length > 0) {
                setOptions(customOptions);
            } else {
                const dynamicChecklists = profileModels.map(model => ({
                    value: 'objectchecklist',
                    name: `${model.title} Checklist`,
                    checklistModelKey: model.key, // Additional data to pass to the field
                    description: `Enables creation of checklists related to ${model.title}.`
                }));

                const dynamicRelationships = profileModels.map(model => ({
                    value: 'relationships',
                    name: `Related ${model.title}`,
                    modelKey: model.key, // Additional data to pass to the field
                    description: `Enables the linking of ${model.key === selectedModel.key ? 'other' : ''} ${model.title}.`,
                }));

                setOptions([
                    { value: 'text', name: 'Text', description: `Stores a single line of text information about ${selectedModel.title}.` },
                    { value: 'gallery', name: 'Photos', description: `Stores a photo gallery related to specific ${selectedModel.title}.` },
                    { value: 'videogallery', name: 'Video', description: `Allows you to upload and display a collection of videos related to ${selectedModel.title}.` },
                    { value: 'documents', name: 'Documents', description: `Stores documents related to ${selectedModel.title}.` },
                    { value: 'checkbox', name: 'Checkbox', description: `Keeps track of true/false information.` },
                    { value: 'barcode', name: 'Barcode', description: `Enables scanning of SKU barcodes with a phone camera.` },
                    { value: 'checklist', name: 'Checklist', description: `Enables creation of checklists related to a specific record.` },
                    { value: 'countries', name: 'Country', description: 'Allow selection from a pre-populated list of countries.' },
                    { value: 'currency', name: 'Currency', description: 'Enforce currency formatting when typing.' },
                    { value: 'date', name: 'Date', description: 'Displays a date popup.' },
                    { value: 'day', name: 'Day', description: 'Allow selection from a list of weekdays.' },
                    { value: 'number', name: 'Number', description: 'Enforces number formatting when users enter data into the model.' },
                    { value: 'location', name: 'Location', description: 'Allows users to enter location information.' },
                    { value: 'lookup', name: 'List', description: 'Lets users choose an item from a list of options that you configure.' },
                    { value: 'month', name: 'Month', description: 'Allows users to select an option from a list of months.' },
                    { value: 'monthyear', name: 'Month/Year', description: 'Lets users select an applicable month and year.' },
                    { value: 'comments', name: 'Comments', description: `Lets users have conversations about ${selectedModel.title}.` },
                    { value: 'password', name: 'Password', description: 'Applies password masking and allows users to show/hide the password.' },
                    { value: 'phone', name: 'Phone Number', description: 'Applies consistent phone number formatting as users enter information.' },
                    ...dynamicRelationships,
                    { value: 'states', name: 'State', description: 'Lets users choose from a list of pre-defined states in the United States.' },
                    { value: 'time', name: 'Time', description: 'Allows users to define a time to associate with the record.' },
                    { value: 'userlist', name: 'Related Members', description: 'Allows users to associate members with an item.' },
                    { value: 'year', name: 'Year', description: 'Allows users to select an option from a list of years.' },
                    ...dynamicChecklists,
                ]);

                prevCustomOptionsRef.current = customOptions;
            }
        }
    }, [selectedModel, customOptions, profileModels]);

    // Validate the selected model
    if (!selectedModel) {
        return null;
    }

    const filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const lowerCaseFilter = filter.toLowerCase();
        return options.filter(option =>
            option.name.toLowerCase().includes(lowerCaseFilter)
        );
    };

    const filteredOptions = filterOptions(options, fieldFilter);

    /**
     * FieldSelectorField
     * Wraps a single option in a drag source so we can drop a new field into FormDesigner.
     */
    const FieldSelectorField = ({ option }) => {
        // The "useDrag" hook for new fields
        const [{ isDragging }, drag] = useDrag({
            type: DRAGGABLE_NEW_FIELD_TYPE,
            item: () => ({
                isNew: true,         // Indicates we are adding a brand new field
                fieldType: option.value,
                fieldName: option.name,
                modelKey: option.modelKey,
                checklistModelKey: option.checklistModelKey
            }),
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const opacity = isDragging ? 0.5 : 1;

        return (
            <div
                ref={drag}
                className="field-selector-field"
                style={{
                    backgroundColor: theme.backgroundColor,
                    borderColor: theme.backgroundColorFaded,
                    borderStyle: "dotted",
                    opacity,
                }}
            >
                <FieldPreview
                    type={option.value}
                    title={option.name}
                    description={option.description}
                    size={size}
                />
            </div>
        );
    };

    return (
        <>
            <InlineStyles hoverColor={theme.highlightBackgroundColor} />
            <div
                className="field-selector-container"
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                }}>
                <FieldSelectorHeader />
                <div className={isMobile ? "field-selector-fields-mobile" : "field-selector-fields"}>
                    {filteredOptions.map((option, index) => (
                        <FieldSelectorField key={index} option={option} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default FieldSelector;
