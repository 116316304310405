import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections, storage } from '../../../../firebaseConfig';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';



// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { ItemType } from '../../../../common/utilities/Enums';
import { generateKey } from '../../../../common/utilities/Keys';

// Styles
import './EventForm.css';

// Theme
import { useStyle, useTheme } from '../../../../ThemeContext';

// Images
import MusicIcon from '../../../svg/MusicIcon';
import ImageIcon from '../../../svg/ImageIcon';
import VideoIcon from '../../../svg/VideoIcon';
import FilesIcon from '../../../svg/FilesIcon';
import RoomsIcon from '../../../svg/RoomsIcon';
import NoteIcon from '../../../svg/NoteIcon';

// Components
import EventAddItemButton from './EventAddItemButton';
import EventDates from './EventDates';
import EventLocation from './EventLocation';
import EventTitle from './EventTitle';
import EventDivider from './EventDivider';
import ItemPanel from '../../../../common/components/itempanel/ItemPanel';
import Modal from '../../../../common/components/modal/Modal';
import ModalButtons from '../../../components/modal/buttons/ModalButtons';
import ModalButton from '../../../components/modal/buttons/ModalButton';
import ModalSwitch from '../../../components/modal/switch/ModalSwitch';
import NoteForm from '../../../../common/note/form/NoteForm';
import RoomAdd from '../../../../common/sections/room/add/RoomAdd';
import Uploader from '../../../../common/components/uploader/Uploader';

// Managers
import DataManager from '../../../../common/managers/DataManager';
const dataManager = new DataManager();

// Inline Styles
const InlineStyles = useStyle`
  *::placeholder {
    color: ${(props) => props.placeholderColor};
    opacity: .2; 
  }
`;

const EventForm = ({ date, folderKey = null, onSave }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
    eventVisible,
    hideProgress,
    selectedEvent,
    selectedRoom,
    setEventVisible,
    setSelectedEvent,
    showProgress,
  } = useContext(Global);

  // Local State
  const [modalTitle, setModalTitle] = useState('Add Event');

  const [title, setTitle] = useState('');
  const [defaultStart, setDefaultStart] = useState(null);
  const [defaultEnd, setDefaultEnd] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [location, setLocation] = useState('');
  const [isPublic, setPublic] = useState(false);

  const [uploadReady, setUploadReady] = useState(false);
  const [uploadItemType, setUploadItemType] = useState(ItemType.PHOTO);
  const [uploadFileTypes, setUploadFileTypes] = useState("*");

  const [items, setItems] = useState([]);

  const [addRoomOpen, setAddRoomOpen] = useState(false);
  const [addNoteOpen, setAddNoteOpen] = useState(false);

  // References
  const uploaderRef = useRef(null);

  // Generate a unique key for a new event
  const generatedKey = generateKey();

  // Initialize state based on whether we are adding or editing
  useEffect(() => {
    if (!eventVisible) return;

    if (selectedEvent) {
      // Editing an existing event
      setModalTitle('Edit Event');
      setTitle(selectedEvent.title);
      const start = selectedEvent.startDate.toDate();
      const end = selectedEvent.endDate.toDate();
      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDate(start);
      setStartTime(start);
      setEndDate(end);
      setEndTime(end);
      setItems(selectedEvent.items || []);
      setLocation(selectedEvent.location || '');
      setPublic(selectedEvent.shared);
    } else {
      // Adding a new event
      setModalTitle('Add Event');
      setTitle('');
      const start = new Date(date);
      const end = new Date(start);
      end.setHours(end.getHours() + 1);
      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDate(start);
      setStartTime(start);
      setEndDate(end);
      setEndTime(end);
    }

  }, [eventVisible, selectedEvent, date]);

  const handleStartDateChange = (d) => {
    setStartDate(d);
  };

  const handleStartTimeChange = (t) => {
    setStartTime(t);
  };

  const handleEndDateChange = (d) => {
    setEndDate(d);
  };

  const handleEndTimeChange = (t) => {
    setEndTime(t);
  };

  const handleSave = async () => {

    showProgress("Saving...");

    // Close the event form
    setEventVisible(false);

    // Validate title
    if (title.trim() === '') {
      setTitle('(No title)');
    }

    // Create a copy of the items array to avoid mutating the state directly
    const updatedItems = [...items];

    // Loop through the items to upload local files and update URLs
    for (let i = 0; i < updatedItems.length; i++) {
      const item = updatedItems[i];

      // Check if the item is a file (photo, video, or document) and has a local "blob:" URL
      if (
        (item.type === ItemType.PHOTO ||
          item.type === ItemType.VIDEO ||
          item.type === ItemType.FILE ||
          item.type === ItemType.MUSIC
        ) &&
        item.url.startsWith('blob:')
      ) {
        try {
          // Fetch the file object from the "blob:" URL
          const file = await fetch(item.url).then((res) => res.blob());

          // Generate a unique file name and path
          const fileName = `file_${Date.now()}`;
          const fileExtension = file.type.split('/')[1]; // Extract extension from MIME type
          const filePath = `uploads/${fileName}.${fileExtension}`;
          const storageRef = ref(storage, filePath);

          // Upload the file to Firebase Storage
          await uploadBytes(storageRef, file);
          const fileUrl = await getDownloadURL(storageRef);

          // Generate a unique key for the file record
          const key = generateKey();

          // Current timestamp
          const now = Timestamp.now();

          // Profile display info
          const displayProfile = {
            key: profile.key,
            firstName: profile.firstName,
            lastName: profile.lastName,
            photo: profile.photo,
          };

          // Create a file record with additional metadata
          const fileRecord = {
            key: key,
            profileKey: profile.key,
            profile: displayProfile,
            type: item.type,
            title: fileName,
            extension: fileExtension,
            eventKey: selectedEvent ? selectedEvent.key : generatedKey,
            path: filePath,
            url: fileUrl,
            size: file.size,
            fileType: file.type,
            trashed: false,
            shared: false,
            dateCreated: now,
          };

          // Add the file record to the database
          await dataManager.add(collections.items, profile.key, key, fileRecord);

          // Update the item URL with the Firebase Storage URL
          updatedItems[i].url = fileUrl;
        } catch (error) {
          console.error(`Error uploading file:`, error);
          // Handle the error (e.g., show a notification to the user)
        }
      }
    }

    // Prepare event data
    const start = new Date(startDate);
    start.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
    const end = new Date(endDate);
    end.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

    const key = selectedEvent ? selectedEvent.key : generatedKey;

    const startTimestamp = Timestamp.fromDate(start);
    const endTimestamp = Timestamp.fromDate(end);

    // Profile display info
    const displayProfile = {
      key: profile.key,
      firstName: profile.firstName,
      lastName: profile.lastName,
      photo: profile.photo,
    };

    const data = {
      key: key,
      profileKey: profile.key,
      profile: displayProfile,
      type: ItemType.EVENT,
      title: title,
      location: location,
      shared: isPublic,
      startDate: startTimestamp,
      endDate: endTimestamp,
      roomKey: selectedRoom ? selectedRoom.key : null,
      folderKey: folderKey,
      items: updatedItems, // Use the updated items array
      trashed: false,
    };

    // Save or update the event in the database
    try {
      if (selectedEvent) {
        await dataManager.update(collections.items, profile.key, key, data);
      } else {
        await dataManager.add(collections.items, profile.key, key, data);
      }

      // Notify the parent component (if applicable)
      if (onSave) {
        onSave(data);
      }

    } catch (error) {
      console.error(`Error saving event:`, error);
      // Handle the error (e.g., show a notification to the user)
    }

    hideProgress();
  };

  /**
   * Method to handle the removal of an event.
   * 
  */
  const handleRemoveEvent = async () => {
    // Delete event copies referencing this event. Use a null item key
    // and pass in the referenceEventKey as a parameter
    const params = [
      { field: 'referenceEventKey', operator: '==', value: selectedEvent.key },
    ];
    await dataManager.delete(collections.items, profile.key, null, params);

    // Delete the Event
    await dataManager.delete(collections.items, profile.key, selectedEvent.key);

    setEventVisible(false);
  };

  /**
   * Resets all state variables and closes the modal.
   */
  const handleClose = () => {
    setTitle('');
    setDefaultStart(null);
    setDefaultEnd(null);
    setStartDate(null);
    setStartTime(null);
    setEndDate(null);
    setEndTime(null);
    setUploadReady(false);
    setUploadItemType(ItemType.PHOTO);
    setUploadFileTypes("*");
    setItems([]);
    setSelectedEvent(null);

    setEventVisible(false)
  };

  const handleItemSelect = (type) => {
    let acceptValue = "*"; // fallback
    let useUpload = false;

    switch (type) {
      case ItemType.PHOTO:
        setUploadItemType(ItemType.PHOTO);
        acceptValue = "image/*";
        useUpload = true;
        break;
      case ItemType.VIDEO:
        setUploadItemType(ItemType.VIDEO);
        acceptValue = "video/*";
        useUpload = true;
        break;
      case ItemType.MUSIC:
        setUploadItemType(ItemType.MUSIC);
        acceptValue = "audio/*";
        useUpload = true;
        break;
      case ItemType.FILE:
        setUploadItemType(ItemType.FILE);
        acceptValue = ".pdf, .doc, .docx, .txt, .csv, .xls, .xlsx, .ppt, .pptx, .zip, .mp3, .wav";
        useUpload = true;
        break;
      case ItemType.ROOM:
        setAddRoomOpen(true);
        return; // no file picking
      case ItemType.NOTE:
        setAddNoteOpen(true);
        break;
      default:
        break;
    }

    // Set the <input> accept attribute directly,
    // then trigger the file picker.
    if (useUpload && uploaderRef.current) {
      uploaderRef.current.accept = acceptValue;
      uploaderRef.current.click();
    }
  };

  const handleSelectComplete = (localFilePaths) => {
    console.log("uploadItemType", uploadItemType);
    const newItems = localFilePaths.map((filePath) => ({
      key: generateKey(),
      type: uploadItemType,
      url: filePath,
    }));
    setItems((prevItems) => [...prevItems, ...newItems]);
  };

  const handleRoomAdd = (room) => {
    setItems((prevItems) => [...prevItems, room]);
  };

  const handleNoteAdd = (note) => {
    setItems((prevItems) => [...prevItems, note]);
  };

  const itemTypes = [
    { type: ItemType.MUSIC, icon: MusicIcon, title: 'Add Music' },
    { type: ItemType.ROOM, icon: RoomsIcon, title: 'Add Room' },
    { type: ItemType.NOTE, icon: NoteIcon, title: 'Add Note' },
    { type: ItemType.PHOTO, icon: ImageIcon, title: 'Add Photo(s)' },
    { type: ItemType.VIDEO, icon: VideoIcon, title: 'Add Video' },
    { type: ItemType.FILE, icon: FilesIcon, title: 'Add File(s)' },
  ];

  return (
    <>
      <InlineStyles
        placeholderColor={theme.foregroundColorFaded}
      />

      <Modal
        title={modalTitle}
        isOpen={eventVisible}
        onClose={handleClose}
        width={isMobile ? "100%" : items.length === 0 ? "460px" : "90%"}
        height={isMobile ? "100%" : "90%"}>

        <div className="event-form-container">


          {/* LEFT */}
          <div className="event-form-left">

            <div className="event-form-sections">

              {/* EVENT TITLE */}
              <EventTitle
                title={title}
                setTitle={setTitle}
              />

              {/* DIVIDER */}
              <EventDivider />

              {/* DATES AND TIMES */}
              <EventDates
                defaultStart={defaultStart}
                defaultEnd={defaultEnd}
                onStartDateChange={handleStartDateChange}
                onStartTimeChange={handleStartTimeChange}
                onEndDateChange={handleEndDateChange}
                onEndTimeChange={handleEndTimeChange}
              />

              {/* DIVIDER */}
              <EventDivider />

              {/* EVENT LOCATION */}
              <EventLocation
                location={location}
                setLocation={setLocation}
              />

              {/* DIVIDER */}
              <EventDivider />

              <ModalSwitch
                label="Public"
                checked={isPublic}
                setChecked={setPublic}
              />

              {/* DIVIDER */}
              <EventDivider />

              {/* ITEMS */}
              {itemTypes.map((itemType) => {

                return (

                  <React.Fragment key={itemType.type}>

                    <EventAddItemButton
                      icon={itemType.icon}
                      label={itemType.title}
                      onClick={() => handleItemSelect(itemType.type)}
                    />

                    <EventDivider />

                  </React.Fragment>

                );
              })}

            </div>

            {/* BUTTONS */}
            <ModalButtons>

              <div className="event-form-buttons-left">

              </div>

              <div className="event-form-buttons-right">

                {/* SAVE BUTTON */}
                <ModalButton
                  onClick={handleSave}
                  label={selectedEvent ? "Save" : "Add"}
                  highlighted={true}
                  width="100px"
                />

                {selectedEvent &&
                  <>
                    {/* DELETE BUTTON */}
                    <ModalButton
                      onClick={handleRemoveEvent}
                      label={"Delete"}
                      width="100px"
                    />
                  </>
                }

              </div>

            </ModalButtons>

          </div>

          {/* ITEM PANEL */}
          {items.length > 0 &&
            <div className="event-form-right">

              <ItemPanel
                items={items}
              />

            </div>
          }

        </div>

      </Modal >

      {/* ROOM ADD */}
      <RoomAdd
        isOpen={addRoomOpen}
        setOpen={setAddRoomOpen}
        eventKey={selectedEvent && selectedEvent.key || generatedKey}
        delayAdd={true}
        onAdded={handleRoomAdd}
      />

      {/* NOTE ADD */}
      <NoteForm
        isOpen={addNoteOpen}
        setOpen={setAddNoteOpen}
        delayAdd={true}
        onAdded={handleNoteAdd}
      />

      {/* UPLOADER */}
      <Uploader
        ref={uploaderRef}
        accept={uploadFileTypes}
        selectionMode="multiple"
        folderPath="uploads"
        uploadReady={uploadReady}
        setUploadReady={setUploadReady}
        onSelectComplete={handleSelectComplete}
      />
    </>
  );
};

export default EventForm;
