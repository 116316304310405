import React from 'react';

const InboxIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 224.62-800h510.76q27.62 0 46.12 18.5Q800-763 800-735.38v510.76q0 27.62-18.5 46.12Q763-160 735.38-160H224.62Zm0-40h510.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-110.76H616.92q-22.3 38-58.42 59-36.12 21-78.5 21t-78.5-21q-36.12-21-58.42-59H200v110.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92ZM480-295.38q38 0 69-22t43-58h168v-360q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H224.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v360h168q12 36 43 58t69 22ZM224.62-200H200h560-535.38Z" />
  </svg>

);

export default InboxIcon;
