import React from 'react';

// Styles
import './EventDivider.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const EventDivider = () => {
  const { theme } = useTheme();

  return (
    <div className="event-divider" 
      style={{ backgroundColor: theme.backgroundColorFaded }}></div>
  );
};

export default EventDivider;
