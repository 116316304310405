import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { adjustTextareaHeight } from '../../../../common/utilities/Forms';

// Styles
import './Text.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * A component that allows the user to input text in a post.
 * 
 * @param {object} block - The block containing the content.
 * @param {function} onChange - The function to call when the text changes.
 * @param {function} onFocus - The function to call when the block gets focused.
 * @param {function} onDelete - The function to call when the block needs to be deleted.
 * 
 * @returns The Text component.
 */
const Text = ({ block, onFocus, onChange, onDelete, onKeyDown }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const { 
    profile 
  } = useContext(Global);

  // References
  const textareaRef = useRef(null);

  /** 
   * Sets the height of the textarea.
   */
  useEffect(() => {
      if (textareaRef.current) {
          adjustTextareaHeight(textareaRef);
      }
  }, [block.content]);

  /** 
   * Handles content change and notifies the parent.
   * 
   * @param {event} e - The event object.
   */
  const handleChange = (e) => {
      onChange("TEXT", e.target.value); // Pass up the block type and the new text
  }

  /** 
   * Handles the focus event and notifies the parent.
   */
  const handleFocus = () => {
      onFocus();
  }

  return (
    <>
      <textarea
        name="text"
        className="note-form-content"
        placeholder="Enter text..."
        style={{
          color: theme.foregroundColor,
          fontFamily: profile.fontFamily,
        }}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        value={block.content || ""}
        rows={1}
        ref={textareaRef}
      />
    </>
  );
};

export default Text;
