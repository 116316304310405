import React, { useContext, useState } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';

// Components
import Login from '../common/login/Login.js';

// Custom Hooks
import useConfirmLoginStatus from './hooks/ConfirmLoginStatus.js';

import usePreloadPrivateItems from './hooks/PreloadPrivateItems.js';
import usePreloadTrashedItems from './hooks/PreloadTrashedItems.js';

import usePreloadProfileModels from './hooks/PreloadProfileModels.js';
import usePreloadProfileFields from './hooks/PreloadProfileFields.js';

// Styles
import './Root.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import Desktop from '../desktop/Desktop';
import Mobile from '../mobile/Mobile';
import Progress from '../common/components/progress/Progress';

// Dynamic Styles
import { RootStyles } from './RootStyles';

const Root = () => {

  // Theme
  const {
    defaultThemes,
    theme
  } = useTheme();

  // Global
  const {
    loginOpen,
    setLoginOpen,
    coreTheme,
    profile,
    screen,
  } = useContext(Global);

  // HOOKS

  /**
   * useConfirmLoginStatus
   * 
   * Detects the user's login status and sets the profile state variable
   * accordingly. If the user is logged in, the profile is fetched from
   * the database and set. If the user is not logged in, the profile is
   * set to null.
   */
  useConfirmLoginStatus();

  /**
   * These hooks preload the folders and files for the current profile.
   */
  usePreloadProfileModels();
  usePreloadProfileFields();

  usePreloadPrivateItems();
  usePreloadTrashedItems();

  /**
  usePreloadProfileObjects();
  */

  return (
    <>
      {/* LOGIN/SIGNUP */}
      <Login
        isOpen={loginOpen}
        setOpen={setLoginOpen}
      />

      <RootStyles
        fontFamily={(profile && profile.fontFamily) || defaultFont}
        placeholderColor={theme.foregroundColorFaded}
        scrollbarColor={theme.foregroundColorFaded}
      />

      {/* RUN */}
      {isMobile ? (
        <>
          <Mobile />
        </>
      ) : (
        <>
          <Desktop />
        </>
      )}


      {/* PROGRESS PANEL */}
      <Progress />

    </>
  );
};

export default Root;

