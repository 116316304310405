import React from 'react';

// Styles
import './TrashToolbar.css';

// Images
import TrashIcon from '../../../svg/TrashIcon';
import TrashFilledIcon from '../../../svg/TrashFilledIcon';

// Components
import { Toolbar, Left, Right } from '../../../components/toolbar/Toolbar';
import ToolbarButton from '../../../components/toolbar/button/ToolbarButton';
import ToolbarIcon from '../../../components/toolbar/icon/ToolbarIcon';
import ToolbarTitle from '../../../components/toolbar/title/ToolbarTitle';

const TrashToolbar = ({ onEmptyClicked }) => {

    return (
        <Toolbar>

            <Left>

                {/* ICON */}
                <ToolbarIcon
                    icon={TrashFilledIcon}
                />

                {/* TITLE */}
                <ToolbarTitle
                    title="Trash"
                />

            </Left>
            <Right>

                {/* EMPTY */}
                <ToolbarButton
                    icon={TrashIcon}
                    title="Empty Trash"
                    onClick={onEmptyClicked}
                />
                
            </Right>

        </Toolbar>
    );
};

export default TrashToolbar;
