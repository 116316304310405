import React from 'react';

const HomeFilledIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M200-160v-420l280-211.54L760-580v420H552.31v-255.38H407.69V-160H200Z" />
  </svg>

);

export default HomeFilledIcon;
