/**
 * Maximum uploaded photo dimensions in pixels.
 */
export const maxPhotoDimension = 1000;

/**
 * Maximum uploaded video size in MB.
 */
export const maxVideoSizeMB = 30;

/**
 * Height of menu items in pixels.
 */
export const menuItemHeight = 30;

/**
 * Opacity of masks throughout the app.
 */
export const maskOpacity = 0.8;

/**
 * Valid field types that can be used in summaries.
 */
export const validSummaryTypes = [
    'country',
    'currency',
    'day',
    'gallery',
    'lookup',
    'number',
    'phone',
    'state',
    'text',
    'time',
    'videogallery',
    'year'
];

/**
* A list of valid text summary field types that can be used in the summary view.
*/
export const validSummaryTextTypes = [
    'country',
    'currency',
    'day',
    'lookup',
    'number',
    'phone',
    'state',
    'text',
    'time',
    'year'
];

/**
* A list of valid extensions for file management.
*/
export const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'png', 'jpg', 'jpeg', 'gif', 'webp', 'mp4', 'mov', 'avi', 'mkv', 'mpg', 'mpeg', 'wmv', 'flv', 'mp3'];

/**
 * A list of valid image file types.
 */
export const imageFileTypes = ["jpg", "jpeg", "png", "gif", "webp", "bmp"];

/**
 * A list of valid video file types.
 */
export const videoFileTypes = ["mp4", "webm", "ogg", "mov", "avi", "mkv"];