import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { getRelativeTime } from '../../utilities/DateTime';
import { getMember, getUserDisplayValue } from '../../utilities/Users';

// Styles
import './Note.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Document from '../components/document/Document';
import Event from '../components/event/Event';
import NoteComments from './NoteComments';
import Link from '../components/link/Link';
import ObjectTile from '../../collection/tiles/tile/ObjectTile';
import Gallery from '../components/gallery/Gallery';
import UserThumb from '../../components/userthumb/UserThumb';
import VideoGallery from '../components/videogallery/VideoGallery';

import Modal from '../../components/modal/Modal';

const Note = ({ isOpen, setOpen }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profileMembers,
        profile,
        selectedNote,
        setBackButtonAction, // Navigation
        setBackVisible, // Navigation
        setSelectedNote,
        profileModels,
        resetSelections,
        setSelectedModel,
        setSelectedObject
    } = useContext(Global);

    // Local State
    const [post, setPost] = useState(null);

    /** 
     * Sets the custom function to run when the back button is clicked.
     */
    useEffect(() => {

        setBackButtonAction(() => {
            return () => {
                setSelectedNote(null);
                setBackVisible(false);
            };
        });

        if (selectedNote) {
            setBackVisible(true);
        }

        return () => { // Reset on unmount
            setBackVisible(false);
            setBackButtonAction(null);
        };

    }, [selectedNote, setSelectedNote, setBackButtonAction, setBackVisible]);

    /**
     * Set the post from the selected post.
     */
    useEffect(() => {
        if (!selectedNote) return;
        setPost(selectedNote);
    }, [selectedNote]);

    const getTimestamp = (post) => {
        return <span
            className="note-timestamp"
            style={{
                color: theme.foregroundColorFaded
            }}>
            {getRelativeTime(post.dateCreated)}
        </span>
    };

    const handleObjectClick = async (object) => {
        resetSelections();
        const appCollection = profileModels.find(appCollection => appCollection.key === object.modelKey);
        setSelectedModel(appCollection);
        setSelectedObject(object);
    };

    if (!post) return null;

    return (
        <Modal
            title=""
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            width="90%"
            height="90%">
            <div className="note-container">

                {/* HEADER */}
                <div className="note-header">

                    {/* LEFT */}
                    <div className="note-header-left">

                        {/* TITLE */}
                        <div className="note-title"
                            style={{
                                color: theme.foregroundColor,
                                fontFamily: profile.fontFamily
                            }}>
                            {post.title}
                        </div>

                    </div>

                </div>

                {/* AUTHOR */}
                <div className="note-author">

                    {/* USER THUMB */}
                    <UserThumb
                        user={post}
                        size="23"
                    />

                    {/* USERNAME */}
                    <div className="note-user"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: profile.fontFamily
                        }}>
                        {getUserDisplayValue(profile.displayKey, getMember(profileMembers, post.userKey))}
                        </div>

                    {/* TIMESTAMP */}
                    <div className="note-timestamp"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        • {getTimestamp(post)}
                    </div>
                </div>

                {/* WRAPPER */}
                <div className="note-wrapper">

                    {/* BLOCKS */}
                    {post.blocks.map((block, index) => (
                        <React.Fragment key={block.key}>
                            {/* TEXT */}
                            {block.type === "TEXT" && (
                                <div className="note-content"
                                    style={{
                                        color: theme.foregroundColor,
                                        fontFamily: profile.fontFamily
                                    }}>
                                    {block.content}
                                </div>
                            )}

                            {/* PHOTOS */}
                            {block.type === "PHOTOS" && (
                                <div className="note-gallery-wrapper">
                                    <Gallery content={block.content} />
                                </div>
                            )}

                            {/* VIDEOS */}
                            {block.type === "VIDEOS" && (
                                <div className="note-gallery-wrapper">
                                    <VideoGallery content={block.content} />
                                </div>
                            )}

                            {/* DOCUMENTS */}
                            {block.type === "DOCUMENTS" && (
                                <div className="note-documents-wrapper">
                                    {block.content.map((item, itemIndex) => (
                                        <Document key={itemIndex} content={item} />
                                    ))}
                                </div>
                            )}

                            {/* OBJECTS */}
                            {block.type === "OBJECT" && (
                                <div className="note-object-wrapper"
                                    style={{
                                        backgroundColor: theme.backgroundColorFaded
                                    }}
                                    onClick={() => handleObjectClick(block.content)}>
                                    <ObjectTile 
                                        object={block.content} 
                                    />
                                </div>
                            )}

                            {/* EVENT */}
                            {block.type === "EVENT" && (
                                <div className="note-event-wrapper">
                                    <Event event={block.content} />
                                </div>
                            )}

                            {/* LINK */}
                            {block.type === "LINK" && (
                                <div className="note-link-wrapper">
                                    <Link url={block.content} />
                                </div>
                            )}
                        </React.Fragment>
                    ))}

                </div>

                {/* COMMENTS */}
                <NoteComments
                    post={post}
                />
            </div>

        </Modal>
    );
};

export default Note;
