import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Notifications.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import NewMember from './templates/newmember/NewMember';
import NewMessage from './templates/newmessage/NewMessage';
import NotificationsToolbar from './toolbar/NotificationsToolbar';
import Section from '../../components/section/Section';

const Notifications = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    memberNotifications,
  } = useContext(Global);

  return (

    <Section>

      {/* TOOLBAR */}
      <NotificationsToolbar />

      <div className="notifications-content">

        {/* INBOX 
        {memberNotifications.map((notification, index) => (

          <React.Fragment key={notification.key}>

            <div className="notification-wrapper">

              <div className="notification-mask"
                style={{
                  backgroundColor: notification.read === false ? theme.highlightBackgroundColor : "transparent",
                }}></div>

              {notification.type === 'NEW_MESSAGE' &&
                <NewMessage notification={notification} />
              }

              {notification.type === 'NEW_MEMBER' &&
                <NewMember notification={notification} />
              }

            </div>

          </React.Fragment>

        ))}*/}

      </div>

    </Section>
    
  );
};

export default Notifications;
