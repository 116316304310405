import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

// Utilities
import { truncateFileName } from '../../../../utilities/Strings';

// Styles
import './RoomGridItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import GridItem from '../../../../components/grid/griditem/GridItem';
import GridItemField from '../../../../components/grid/griditem/field/GridItemField';
import GridItemTitle from '../../../../components/grid/griditem/title/GridItemTitle';
import ParticipantsGrid from '../../../../components/participants/grid/ParticipantsGrid';

/**
 * RoomGridItem
 * 
 * A grid item for displaying rooms.
 * 
 * @param {Object} item The room item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click room handler.
 * @param {Function} onDoubleClick The double click room handler.
 * @param {Function} onDragStart The drag start room handler.
 * 
 * @returns {JSX.Element} RoomGridItem component.
 */
const RoomGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const { profile } = useContext(Global);

    // Extract room participants (max 6)
    const participants = item.profiles.slice(0, 6);
    const extraCount = item.profiles.length > 6 ? item.profiles.length - 6 : 0;

    // Determine grid structure based on participant count
    const getGridStyle = () => {
        const count = participants.length;
        if (count <= 3) return { gridTemplateColumns: `repeat(${count}, 1fr)` }; // 1 row
        return { gridTemplateColumns: `repeat(3, 1fr)` }; // Max 3 per row
    };

    return (
        <GridItem
            selected={selected}
            shared={item.shared}
            trashed={item.trashed}
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(event) => {
                if (onDragStart) onDragStart(event, item);
            }}>

            <div
                className="room-grid-item-container"
                title={item.title}>

                {/* PARTICIPANTS GRID */}
                <ParticipantsGrid
                    item={item}
                    displayCount={6}
                />

                {/* TITLE */}
                <GridItemTitle
                    title={truncateFileName(item.title, 20)}
                    selected={selected}
                />

                {/* PARTICIPANTS COUNT */}
                <GridItemField
                    value={`${item.profiles.length} participants`}
                    selected={selected}
                />

            </div>

        </GridItem>
    );
};

export default RoomGridItem;
