import React, { useCallback, useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../../common/utilities/Keys';
import { ThemeMode } from '../../common/utilities/Enums';

// Drag/Drop
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Styles
import './RoomDesigner.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import ColumnAddIcon from '../../common/svg/ColumnAddIcon';
import DeleteIcon from '../../common/svg/DeleteIcon';
import RemoveBlockIcon from '../../common/svg/RemoveBlockIcon';
import SaveIcon from '../../common/svg/SaveIcon';
import SplitHorizontalIcon from '../../common/svg/SplitHorizontalIcon';
import TileIcon from '../../common/svg/TileIcon';

// Components
import Modal from '../../common/components/modal/Modal';
import SiteCanvasButton from './canvas/canvasbutton/SiteCanvasButton';
import SiteCanvasColumn from './canvas/canvascolumn/SiteCanvasColumn';
import RoomListElements from './list/listelements/RoomListElements';
import TileDesigner from '../tiledesigner/TileDesigner';

// Managers
import DataManager from '../../common/managers/DataManager';

const dataManager = new DataManager();

// Utility to create a new block
const createBlock = (content = []) => ({
    id: generateKey(),
    content: content,  // Accept content to allow splitting
    align: 'top',
});

const RoomDesigner = ({ open, setOpen }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Global
    const {
        profileModels,
        profile,
        selectedRoom, 
        selectedRoomElement,
        setProfile,
        setSelectedRoomElement,
    } = useContext(Global);

    // Local State
    const [columns, setColumns] = useState([{ id: generateKey(), blocks: [createBlock()] }]);
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [tileDesignerVisible, setTileDesignerVisible] = useState(false);

    useEffect(() => {
        if (selectedRoom.columns) {
            setColumns(selectedRoom.columns);
        } else {
            setColumns([{ id: generateKey(), blocks: [createBlock()] }]);
        }
    }, [selectedRoom]);

    // Clears selections
    const clearSelections = useCallback(() => {
        setSelectedColumn(null);
        setSelectedBlock(null);
        setSelectedRoomElement(null);
    }, [setSelectedRoomElement]);

    // Handles selecting a block
    const handleBlockSelect = (columnId, blockId) => {
        clearSelections();
        setSelectedBlock({ columnId, blockId });
    };

    // Handle selecting a column
    const handleColumnSelect = async (columnId) => {
        await clearSelections();
        setSelectedColumn(columnId);
    };

    // Handle selecting an element inside a block
    const handleElementSelect = async (element) => {
        await clearSelections();
        setSelectedRoomElement(element);
    };

    // Split the selected block into two blocks
    const handleSplitBlock = (columnId, blockId) => {
        setColumns(columns.map(column => {
            if (column.id === columnId) {
                const newBlocks = column.blocks.map(block => {
                    if (block.id === blockId) {
                        // Ensure each new block gets a unique key
                        return [createBlock(block.content), createBlock([])]; // Split into two blocks
                    }
                    return block;
                }).flat();
                return { ...column, blocks: newBlocks };
            }
            return column;
        }));
    };

    // Delete the selected column
    const handleDeleteColumn = useCallback(() => {
        setColumns(columns.filter(column => column.id !== selectedColumn))
        clearSelections();
    }, [clearSelections, columns, selectedColumn]);

    // Delete the selected block or the entire column if it's the last block
    const handleDeleteBlock = useCallback(() => {
        const { columnId, blockId } = selectedBlock;

        setColumns(prevColumns =>
            prevColumns.map(column => {
                if (column.id === columnId) {
                    // Check if the column only has one block
                    if (column.blocks.length === 1) {
                        return null; // Return null to indicate this column should be removed
                    }
                    // Remove the block with the matching blockId from the blocks array
                    return {
                        ...column,
                        blocks: column.blocks.filter(block => block.id !== blockId)
                    };
                }
                return column; // Return the column unchanged if it's not the target column
            }).filter(column => column !== null) // Remove columns where we returned null (i.e., columns with only one block)
        );

        clearSelections();
    }, [clearSelections, selectedBlock]);

    // Delete the selected element by its key
    const handleDeleteElement = useCallback(() => {
        const { key } = selectedRoomElement;  // Extract the key from the passed element

        // Iterate over the columns to find the column, block, and element by key
        setColumns(prevColumns => prevColumns.map(column => {
            return {
                ...column,
                blocks: column.blocks.map(block => {
                    const newContent = block.content.filter(item => item.key !== key); // Remove the element by key
                    return { ...block, content: newContent };
                })
            };
        }));

        clearSelections();
    }, [clearSelections, selectedRoomElement]);

    // Delete the selected block or entire column if it's the last block
    const handleCanvasClick = () => {
        clearSelections();
    }

    // Move a column from one position to another
    const handleMoveColumn = (dragIndex, hoverIndex) => {
        const newColumns = [...columns];
        const [draggedColumn] = newColumns.splice(dragIndex, 1); // Remove the dragged column
        newColumns.splice(hoverIndex, 0, draggedColumn); // Insert it in the new position
        setColumns(newColumns); // Update the state with reordered columns
    };

    // Handle opening of the collection tile editor
    const handleTileDesigner = () => {
        setTileDesignerVisible(true);
    }

    // Add a new column
    const addColumn = () => {
        setColumns([...columns, { id: generateKey(), blocks: [createBlock()] }]);
    };

    // Update block content when an element is dropped, appending instead of replacing
    const handleDrop = (columnId, blockId, element) => {

        console.log('handleDrop', columnId, blockId, element);

        // Clear any existing selections
        clearSelections();

        // Destructure the element object and omit the 'icon' property
        const { icon, ...elementWithoutIcon } = element;

        // When an element is dropped, generate a unique key for the instance
        const newBlockElement = { ...elementWithoutIcon, key: generateKey() };

        // Update the columns state with the new element
        setColumns(columns.map(column => {
            if (column.id === columnId) {
                return {
                    ...column,
                    blocks: column.blocks.map(block =>
                        block.id === blockId
                            ? { ...block, content: [...block.content, newBlockElement] }
                            : block
                    ),
                };
            }
            return column;
        }));

        console.log('newBlockElement', newBlockElement);

        setSelectedRoomElement(newBlockElement);
    };

    /**
     * Method to save the design to the model record
     */
    const handleSave = async () => {

        // Save the data to the database
        await dataManager.update(
            collections.items,
            profile.key,
            selectedRoom.key,
            { columns: columns });

        // Update the selected model in state with the new elements
        setProfile(prev => ({ ...prev, columns }));

        // Close the designer
        setOpen(false);

    };

    // Keyboard shortcuts
    useEffect(() => {
        // Function to handle key press events
        const handleKeyPress = (event) => {

            // Check if the active element is an input, textarea, or other focusable element
            const activeElement = document.activeElement;
            const isInputFocused = activeElement.tagName === 'INPUT' ||
                activeElement.tagName === 'TEXTAREA' ||
                activeElement.isContentEditable;

            // If an input is focused, do not remove or move the selected element
            if (isInputFocused) return;

            if (event.key === 'Backspace' || event.key === 'Delete') {
                // Check if an element is selected
                if (selectedRoomElement) {
                    handleDeleteElement(selectedRoomElement);
                }
                // Check if a block is selected
                else if (selectedBlock) {
                    handleDeleteBlock(selectedBlock.columnId, selectedBlock.blockId);
                }
                // Check if a column is selected
                else if (selectedColumn) {
                    handleDeleteColumn(selectedColumn);
                }
            }
        };

        // Attach the event listener for keydown
        window.addEventListener('keydown', handleKeyPress);

        // Cleanup the event listener when the component unmounts or dependencies change
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [selectedColumn, selectedBlock, selectedRoomElement, handleDeleteColumn, handleDeleteBlock, handleDeleteElement]);

    const getCollection = () => {
        if (!selectedRoomElement) return null;
        const collection = profileModels.find(collection => collection.key === selectedRoomElement.collectionKey);
        return collection;
    }

    // Verify profile
    if (!profile) return null;

    return (
        <>
            <Modal
                title={`Room Design - ${selectedRoom.title}`}
                isOpen={open}
                onClose={() => setOpen(false)}
                height="90%"
                width="90%">
                <DndProvider backend={HTML5Backend}>
                    <div className="room-designer-container">

                        {/* ELEMENTS PANEL */}
                        <div className="room-designer-elements"
                            style={{
                                backgroundColor: theme.backgroundColor,
                            }}>
                            <RoomListElements useDrag={useDrag} />
                        </div>

                        <div className="room-designer-canvas-wrapper">

                            {/* TOP BUTTONS */}
                            <div className="room-designer-buttons">

                                {/* ADD COLUMN */}
                                <SiteCanvasButton
                                    title="Add Column"
                                    icon={ColumnAddIcon}
                                    onClick={addColumn}
                                />

                                {/* DELETE ROW */}
                                {selectedColumn && (
                                    <SiteCanvasButton
                                        title="Delete Column"
                                        icon={DeleteIcon}
                                        onClick={handleDeleteColumn}
                                    />
                                )}

                                {/* SELECTED BLOCK */}
                                {selectedBlock && (
                                    <>
                                        <SiteCanvasButton
                                            title="Split Block"
                                            icon={SplitHorizontalIcon}
                                            onClick={() => handleSplitBlock(selectedBlock.columnId, selectedBlock.blockId)}
                                        />
                                        <SiteCanvasButton
                                            title="Remove Block"
                                            icon={RemoveBlockIcon}
                                            onClick={() => handleDeleteBlock(selectedBlock.columnId, selectedBlock.blockId)}
                                        />
                                    </>
                                )}

                                {/* SELECTED ELEMENT */}
                                {selectedRoomElement && (
                                    <>
                                        <SiteCanvasButton
                                            title={`Remove "${selectedRoomElement.title}"`}
                                            icon={DeleteIcon}
                                            onClick={() => handleDeleteElement(selectedRoomElement)}
                                        />

                                        {selectedRoomElement.type === 'collection' &&
                                            <SiteCanvasButton
                                                title={`Edit "${selectedRoomElement.title}" Tiles`}
                                                icon={TileIcon}
                                                onClick={() => handleTileDesigner(selectedRoomElement)}
                                            />
                                        }
                                    </>
                                )}
                            </div>

                            {/* MAIN CANVAS */}
                            <div className="room-designer-canvas"
                                style={{
                                    backgroundColor: theme.backgroundColor,
                                    backgroundImage: profile
                                        ? currentThemeMode === ThemeMode.DARK
                                            ? `url(${profile.wallpaperDark || 'none'})`
                                            : `url(${profile.wallpaperLight || 'none'})`
                                        : `none`, // Fallback if profile is null
                                    backgroundSize: 'cover', // Ensures the image covers the entire screen
                                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                                    backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
                                }}
                                onClick={handleCanvasClick}>
                                {columns.map((column, index) => (
                                    <SiteCanvasColumn
                                        key={column.id}
                                        index={index}
                                        column={column}
                                        selectedColumn={selectedColumn}
                                        selectedBlock={selectedBlock}
                                        selectedRoomElement={selectedRoomElement}
                                        onMoveColumn={handleMoveColumn}
                                        onColumnSelect={handleColumnSelect}
                                        onBlockSelect={handleBlockSelect}
                                        onDrop={handleDrop}
                                        onElementSelect={handleElementSelect}
                                        onElementDoubleClick={handleTileDesigner}
                                        useDrag={useDrag}
                                        useDrop={useDrop}
                                    />
                                ))}
                            </div>

                            {/* BOTTOM BUTTONS */}
                            <div className="room-designer-buttons">

                                <SiteCanvasButton
                                    title="Save Changes"
                                    icon={SaveIcon}
                                    onClick={handleSave}
                                />

                            </div>

                        </div>

                        {/* OPTIONS PANEL */}
                        <div className="room-designer-options"
                            style={{
                                backgroundColor: theme.backgroundColor,
                            }}>
                        </div>

                    </div>

                </DndProvider>

            </Modal>

            {/* TILE DESIGNER */}
            <TileDesigner
                open={tileDesignerVisible}
                setOpen={setTileDesignerVisible}
                collection={getCollection()}
            />
        </>
    );
};

export default RoomDesigner;

