import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { ItemType, SectionType, ThemeMode } from '../common/utilities/Enums';
import { loadFont } from '../common/utilities/Fonts';

// Styles
import './Desktop.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import MenuBar from '../common/components/menubar/MenuBar.js';
import Calendar from '../common/sections/calendar/Calendar';
import Studio from '../common/sections/studio/Studio';
import Folder from '../common/sections/folders/Folder';
import Friends from '../common/sections/friends/Friends';
import Home from '../common/sections/home/Home';
import Menu from '../common/menu/Menu';
import Room from '../common/sections/room/Room';
import Notifications from '../common/sections/notifications/Notifications';
import Objects from '../common/collection/Objects';
import Profile from '../common/sections/profile/Profile';
import Rooms from '../common/sections/rooms/Rooms';
import Settings from '../common/sections/settings/Settings';
import Trash from '../common/sections/trash/Trash';

// Dynamic Styles
import { DesktopStyles } from './DesktopStyles';

// Images
const gradient = 'https://appdeck.ai/wallpapers/gradient.jpg';

/**
 * Desktop Component
 * 
 * This component renders a desktop version of the app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Desktop = () => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    profile,
    currentSection,
  } = useContext(Global);

  const renderContent = () => {

    if (currentSection === SectionType.PROFILE) return <Profile  profile={profile} />;
    if (currentSection === SectionType.HOME) return <Home />;
    if (currentSection === SectionType.FRIENDS) return <Friends />;
    if (currentSection === SectionType.FOLDERS) return (
      <Folder 
        types={[
          ItemType.FOLDER,
          ItemType.PHOTO,
          ItemType.VIDEO,
          ItemType.NOTE,
          ItemType.OBJECT,
          ItemType.FILE,
        ]}
        unsharedOnly={true}
      />
    );
    if (currentSection === SectionType.STUDIO) return <Studio />;
    if (currentSection === SectionType.CALENDAR) return <Calendar />;
    if (currentSection === SectionType.INBOX) return <Notifications />;
    if (currentSection === SectionType.ROOM) return <Room />;
    if (currentSection === SectionType.ROOMS) return <Rooms />;
    if (currentSection === SectionType.TRASH) return <Trash />;
    if (currentSection === SectionType.SETTINGS) return <Settings />;
    
    return null;
  };

  useEffect(() => {
    if (!profile) return;
    loadFont(profile.fontFamily || defaultFont);
  }, [profile]);

  // Verify profile
  if (!profile) return null;

  return (
    <>
      <DesktopStyles
        fontFamily={(profile && profile.fontFamily) || defaultFont}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="desktop-container"
        style={{
          backgroundColor: theme.backgroundColor,
          backgroundImage: profile
            ? currentThemeMode === ThemeMode.DARK
              ? `url(${profile.wallpaperDark || 'none'})`
              : `url(${profile.wallpaperLight || 'none'})`
            : `url(${gradient})`, // Fallback if profile is null
          backgroundSize: 'cover', // Ensures the image covers the entire screen
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
          height: '100vh', // Ensures it takes up the full viewport height
          width: '100vw', // Ensures it takes up the full viewport width
        }}>

        {/* TITLE BAR 
        {profile && (
          <div className="desktop-title-bar">
            <TitleBar />
          </div>
        )}
          */}

        <div className="desktop-main">

          {/* PROFILE BAR */}
          <div className="desktop-left">
            <MenuBar />
          </div>

          <div className="desktop-right">
            <div className="desktop-middle">
              {/* CONTENT */}
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Desktop;
