import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load items for a specific profile.
 */
const usePreloadTrashedItems = () => {

  // Global
  const {
    profile,
    setTrashedItems
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected app or app key
    if (!profile || !profile.key) {
      setTrashedItems([]);
      return;
    }

    const loadFiles = () => {
      const handleUpdate = (items) => {
        setTrashedItems(items);
      };

      const params = [
        { field: "profileKey", operator: "==", value: profile.key },
        { field: "trashed", operator: "==", value: true }
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.items,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeFiles = loadFiles();

    return () => {
      if (unsubscribeFiles) {
        unsubscribeFiles();
      }
    };
  }, [profile, setTrashedItems]);

};

export default usePreloadTrashedItems;
