import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Translation
import { useTranslation } from 'react-i18next';

// Enums
import { ThemeMode } from '../../../common/utilities/Enums';

// Styles
import './ThemeToggle.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Images
import DarkModeIcon from '../../../common/svg/DarkModeIcon';

// Managers
import ProfileManager from '../../../common/managers/ProfileManager';

const profileManager = new ProfileManager();

const ThemeToggle = () => {

    // Theme
    const {
        currentThemeMode,
        setCurrentThemeMode,
        theme
    } = useTheme();

    // Global
    const {
        currentUser,
        profile,
        setCurrentUser,
    } = useContext(Global);

    // Translation
    const { t } = useTranslation('TitleBar');

    const toggleTheme = async () => {
        const newTheme = currentThemeMode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK;
        setCurrentThemeMode(newTheme);
    };

    return (
        <div
            title={t('TitleBar:toggle_dark_mode')}
            className="theme-toggle-container"
            onClick={toggleTheme}>

            <DarkModeIcon
                color={theme.foregroundColorFaded}
                width="26"
                height="26"
            />

        </div>
    );
};

export default ThemeToggle;
