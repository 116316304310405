import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Styles
import './DownvoteButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import DownvoteIcon from '../../../../../../common/svg/DownvoteIcon';
import DownvoteOnIcon from '../../../../../../common/svg/DownvoteOnIcon';

// Managers
import ElementManager from '../../../../../../common/managers/ElementManager';

const elementManager = new ElementManager();

const DownvoteButton = ({ element, object }) => {
    const { theme } = useTheme();
    const {
        currentUser,
        profile,
        selectedModel,
        userElements
    } = useContext(Global);

    // Local State
    const [selected, setSelected] = useState(false);

    /** 
     * Checks if the user has selected the element.
     */
    useEffect(() => {

        const userElement = userElements.find(elem => 
            elem.key === element.key && 
            elem.userKey === currentUser.userKey && 
            elem.objectKey === object.key);

        if (userElement) {
            setSelected(userElement.value);
        }   

    }, [currentUser.userKey, element.key, object.key, userElements]);

    /**
     * Handles the click event for the button.
     * @param {event} event 
     */
    const handleClick = async (event) => {
        event.stopPropagation();

        const newValue = !selected;

        setSelected(newValue);

        const data = {
            key: element.key,
            profileKey: profile.key,
            modelKey: selectedModel.key,
            userKey: currentUser.userKey,
            objectKey: object.key,
            elementKey: element.key,
            value: newValue
        };

        await elementManager.update(profile.key, element.key, data);
    };

    return (
        <div className="follow-button-container"
            title={selected ? 'Remove Downvote' : 'Downvote'}
            onClick={handleClick}>
            {selected ? (
                <DownvoteOnIcon
                    color={theme.highlightBackgroundColor}
                    width="19"
                    height="19"
                />
            ) : (
                <DownvoteIcon
                    color={theme.foregroundColorFaded}
                    width="19"
                    height="19"
                />
            )}
        </div>
    );
};

export default DownvoteButton;
