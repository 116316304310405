import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './EventTime.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const EventTime = ({
    placeholder = '',
    defaultValue,
    onUpdate
}) => {
    const { theme } = useTheme();
    const { 
      profile 
    } = useContext(Global);

    const [value, setValue] = useState('');

    useEffect(() => {
        if (defaultValue instanceof Date) {
            const hours = defaultValue.getHours().toString().padStart(2, '0');
            const minutes = defaultValue.getMinutes().toString().padStart(2, '0');
            setValue(`${hours}:${minutes}`);
        } else {
            setValue(''); // Reset if defaultValue is null or not a Date object
        }
    }, [defaultValue]);

    // Generate times in 15-minute increments
    const generateTimes = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const valueHour = hour.toString().padStart(2, '0');
                const valueMinute = minute.toString().padStart(2, '0');
                const timeValue = `${valueHour}:${valueMinute}`; // Value in 24-hour format

                const displayHour = hour % 12 === 0 ? 12 : hour % 12;
                const amPm = hour < 12 ? 'AM' : 'PM';
                const timeDisplay = `${displayHour}:${valueMinute} ${amPm}`; // Display in 12-hour format

                times.push({ value: timeValue, display: timeDisplay });
            }
        }
        return times;
    };

    const handleChange = (e) => {
        setValue(e.target.value);
        const [hours, minutes] = e.target.value.split(':').map(Number);
        const date = new Date(defaultValue); // Initialize with defaultValue's date
        date.setHours(hours, minutes, 0, 0); // Set hours, minutes, and reset seconds and milliseconds
        onUpdate(date);
    };

    const times = generateTimes(); // Ensure times is initialized by calling generateTimes

    return (
        <div className="event-time-container">
            <select
                className="event-time-input"
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                    color: theme.foregroundColor,
                    fontFamily: profile.fontFamily
                }}
                value={value}
                onChange={handleChange}>
                <option value="">{placeholder}</option>
                {times.map((time, index) => (
                    <option key={index} value={time.value}>{time.display}</option>
                ))}
            </select>
        </div>
    );
};

export default EventTime;
