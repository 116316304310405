import React, { useEffect, useState } from 'react';

// Utilities
import { ItemType, ThemeMode } from '../../../common/utilities/Enums';

// Styles
import './ItemPanel.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Grid from '../../../common/components/grid/Grid';
import Item from '../../../common/sections/folders/item/Item';
import ItemPanelRow from './row/ItemPanelRow';

/**
 * ItemPanel Component
 * 
 * This component renders a item panel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ItemPanel = ({ items }) => {

  // Theme
  const { currentThemeMode } = useTheme();

  // Grouped local state for all item types (instead of many separate states)
  const [groupedItems, setGroupedItems] = useState({
    [ItemType.MUSIC]: [],
    [ItemType.EVENT]: [],
    [ItemType.FILE]: [],
    [ItemType.PHOTO]: [],
    [ItemType.VIDEO]: [],
    [ItemType.ROOM]: [],
    [ItemType.OBJECT]: [],
    [ItemType.NOTE]: []
  });

  useEffect(() => {
    if (!items || items.length === 0) return;

    console.log('ITEMS', items);

    // Build a new grouping object, each property is an empty array to start
    const newGroups = {
      [ItemType.MUSIC]: [],
      [ItemType.EVENT]: [],
      [ItemType.FILE]: [],
      [ItemType.PHOTO]: [],
      [ItemType.VIDEO]: [],
      [ItemType.ROOM]: [],
      [ItemType.OBJECT]: [],
      [ItemType.NOTE]: []
    };

    // Populate each array by filtering items based on their type
    items.forEach((item) => {
      if (newGroups.hasOwnProperty(item.type)) {
        newGroups[item.type].push(item);
      }
    });

    setGroupedItems(newGroups);

  }, [items]);

  // We define a small config array to handle the repetitive rendering (title, col width, etc.)
  const sectionsConfig = [
    { title: 'Photos', items: groupedItems[ItemType.PHOTO], colWidth: 150 },
    { title: 'Videos', items: groupedItems[ItemType.VIDEO], colWidth: 300 },
    { title: 'Music', items: groupedItems[ItemType.MUSIC], colWidth: 150 },
    { title: 'Notes', items: groupedItems[ItemType.NOTE], colWidth: 300 },
    { title: 'Rooms', items: groupedItems[ItemType.ROOM], colWidth: 170 },
    { title: 'Events', items: groupedItems[ItemType.EVENT], colWidth: 170 },
    // { title: 'Objects', items: groupedItems[ItemType.OBJECT], colWidth: 170 },
    { title: 'Files', items: groupedItems[ItemType.FILE], colWidth: 170 }
  ];

  return (
    <>
      {/* MASK */}
      <div
        className="room-mask"
        style={{
          backgroundColor: currentThemeMode === ThemeMode.DARK ? 'black' : 'white',
        }}
      ></div>

      <div className="room-content">
        
        {sectionsConfig.map(({ title, items, colWidth }, idx) => (
          items && items.length > 0 && (
            <ItemPanelRow key={title + idx} title={title}>
              <Grid
                items={items}
                renderItem={(item) => (
                  <Item
                    key={item.key}
                    item={item}
                  />
                )}
                loading={false}
                minCols={1}
                colWidth={colWidth}
              />
            </ItemPanelRow>
          )
        ))}

      </div>
    </>
  );
};

export default ItemPanel;
